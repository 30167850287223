import React, { useState, useEffect, useRef } from "react";
import { Input, TextArea } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import homeIcon from "../Assets/images/home-property-blue.svg";
import { editAssets } from "../Utility/Services/assets";

const initForm = {
  description: "",
  assetLocation: "",
  value: "",
  errors: {
    description: null,
    assetLocation: null,
    value: null,
  },
};

const EditAssets = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [loader, setLoader] = useState(false);
  const [assetId, setAssetId] = useState("");
  const tempForm = useRef();

  let { form } = state;
  let location = useLocation();
  let { errors, description, assetLocation, value } = form;

  const formFun = () => {
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);

  const formUpdate = () => {
    let assetData = location.state.data;
    form.description = assetData.description;
    form.assetLocation = assetData.location;
    form.value = assetData.estimated_value;
    setState({ ...state, form });
    setAssetId(location.state.asset_id);
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onEditAsset = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      editAssets(assetId, description, assetLocation, value).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Asset updated successfully");
          setLoader(false);
          navigate(path_url.home_property);
        }
      });
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={homeIcon}
            alt="home-icon"
          />
          Update Asset
          <hr className="mb-0" />
        </h2>
        <div className="common-mt add-edit-modal">
          <React.Fragment>
            <div className="row mb-2 mx-auto">
              <div className="col-lg-12">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">
                  Description
                </label>
                <TextArea
                  name="description"
                  value={description}
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  title="Description"
                  isReq={true}
                  validationFunc={(name, error) => {
                    onInputValidate(name, error);
                  }}
                  onChangeFunc={(name, value, error) => {
                    onInputChange(name, value, error);
                  }}
                  rows={4}
                  placeholder="Enter description"
                  error={errors.description}
                />
              </div>
            </div>
            <div className="row mb-2 mx-auto">
              <div className="col-lg-6">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">
                  Location
                </label>
                <Input
                  name="assetLocation"
                  value={assetLocation}
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  title="Location"
                  onChangeFunc={(name, value, error) => {
                    onInputChange(name, value, error);
                  }}
                  placeholder="Enter location"
                />
              </div>
              <div className="col-lg-6">
                <label className="w-100 fw-semibold mb-2 text-left">
                  Estimated Value
                </label>
                <Input
                  name="value"
                  value={value}
                  className="form-control input-content"
                  title="Estimated Value"
                  onChangeFunc={(name, value, error) => {
                    onInputChange(name, value, error);
                  }}
                  placeholder="Enter estimated value"
                />
              </div>
            </div>
          </React.Fragment>{" "}
          <div className="col-lg-12 mt-4 home-action">
            <Link to={path_url.home_property}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>

            <button
              onClick={onEditAsset}
              type="button"
              className="btn btn-primary br-10 fz-16 fw-bold"
            >
              {loader ? "Loading..." : "Update"}
              {loader && (
                <i
                  className="ml-2 fa-spin fa-lg fa fa-spinner"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditAssets;
