import React from 'react';
import ReactSelect, { components } from 'react-select';
import PropTypes from 'prop-types';
import { map } from 'lodash';

const { SingleValue, MultiValue } = components;

const CustomMultiValue = (props) => (
	<MultiValue {...props}>
		<img
			style={{ width: '30px', height: '30px' }}
			src={props.options.filter((val) => val[props.valueKey] === props.data[props.valueKey])['0'][props.iconKey]}
			alt="style-icon"
		/>{' '}
		&nbsp; &nbsp;&nbsp;
		{props.data[props.labelKey]}
	</MultiValue>
);

const CustomSingleValue = (props) => (
	<SingleValue {...props}>
		<img
			style={{ width: '30px', height: '30px' }}
			src={props.options.filter((val) => val[props.valueKey] === props.data[props.valueKey])['0'][props.iconKey]}
			alt="style-icon"
		/>{' '}
		&nbsp; &nbsp;&nbsp;
		{props.data[props.labelKey]}
	</SingleValue>
);

const changeHandler = (obj, props, reqErrorMsg) => {
	const { isReq, valueKey, isMulti } = props;
	let value = null;
	if (isMulti) value = obj && obj.length > 0 ? map(obj, [ valueKey ].toString()).join(',') : null;
	else value = obj ? obj[valueKey] : null;

	let errorMsg = undefined;
	if (isReq !== null) {
		if (!value && isReq) errorMsg = reqErrorMsg;
		else errorMsg = null;
	}
	props.onChangeFunc(props.name, value, errorMsg);
};

const getObjectByValue = (options, valueKey, labelKey, value) => {
	if (!options || (options && options.length === 0)) return null;
	const selectedObj = options.find((o) => o[valueKey] === value);
	if (!selectedObj) return null;
	return {
		[valueKey]: selectedObj[valueKey],
		[labelKey]: selectedObj ? selectedObj[labelKey] : ''
	};
};

const customStyles = {
	control: (base, state) => ({
		...base,
		boxShadow: 'none'
	})
};


const stylesForRegister = {
  control: (base, state) => ({
    ...base,
    boxShadow: "none",
	minHeight: "100%"
  }),
};

const Select = (props) => {
	const reqErrorMsg = `Select ${props.title}`;
	let { options } = props;
	const {
		title,
		className,
		classNamePrefix,
		placeholder,
		isClearable,
		value,
		name,
		valueKey,
		labelKey,
		iconKey,
		error,
		isMulti,
		icon,
		isSearchable,
		isLoading,
		disabled,
		isRegister
	} = props;

	const inputProps = {
		className,
		classNamePrefix,
		valueKey,
		labelKey,
		placeholder: placeholder === null ? `${title}` : placeholder,
		isClearable,
		isSearchable,
		isMulti,
		isLoading,
		isDisabled: disabled
	};
	if (icon)
		inputProps.components = {
			SingleValue: (other) => (
				<CustomSingleValue {...other} labelKey={labelKey} valueKey={valueKey} iconKey={iconKey} />
			),
			MultiValue: (other) => (
				<CustomMultiValue {...other} labelKey={labelKey} valueKey={valueKey} iconKey={iconKey} />
			),
		};
	if (name) inputProps.name = name;
	if (options) inputProps.options = options;
	if (value) {
		if (isMulti) {
			const valueArr = [];
			value.toString().includes(',')
				? value.split(',').map((v) => valueArr.push(getObjectByValue(options, valueKey, labelKey, v)))
				: valueArr.push(getObjectByValue(options, valueKey, labelKey, value));

			inputProps.value = valueArr;
		} else {
			inputProps.value = getObjectByValue(options, valueKey, labelKey, value);
		}
	}
	if (inputProps.value === undefined) inputProps.value = null;
	return (
		<div className="form-group text-left mb-2">
			<ReactSelect
				{...inputProps}
				getOptionLabel={(option) => option[labelKey]}
				getOptionValue={(option) => option[valueKey]}
				menuPlacement="auto"
				onChange={(e) => changeHandler(e, props, reqErrorMsg)}
			//	onBlur={(e) => onBlur(value, props, reqErrorMsg)}
				styles={ isRegister ? stylesForRegister : customStyles}
			/>

			{error && (
				<span className="reqEstric mb-0">
					<i className="fas fa-exclamation-circle" />
					&nbsp;{error === true ? reqErrorMsg : error}
				</span>
			)}
		</div>
	);
};
Select.defaultProps = {
	isReq: null,
	className: 'form-control',
	classNamePrefix: 'prefix-select',
	placeholder: null,
	isClearable: true,
	isSearchable: true,
	options: [],
	valueKey: 'value',
	labelKey: 'label',
	labelIcon: 'icon',
	isMulti: false,
	isLoading: false,
	onChangeFunc: () => {},
	disabled: false,
	isRegister: false
};
Select.propTypes = {
	title: PropTypes.string,
	name: PropTypes.string,
	className: PropTypes.string,
	classNamePrefix: PropTypes.string,
	placeholder: PropTypes.string,
	isClearable: PropTypes.bool,
	value: PropTypes.any,
	options: PropTypes.array,
	valueKey: PropTypes.string,
	labelKey: PropTypes.string,
	labelIcon: PropTypes.string,
	onChangeFunc: PropTypes.func,
	isReq: PropTypes.bool,
	isLoading: PropTypes.bool,
	error: PropTypes.any,
	isMulti: PropTypes.bool,
	disabled: PropTypes.bool,
	isRegister: PropTypes.bool
};
export default React.memo(Select);
