import React, { useState, useEffect, useRef } from "react";
import { Input, Select } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { ts } from "../Utility/ReduxToaster";
import { editPersonalInfo, getAccount } from "../Utility/Services/account";

const initForm = {
  firstName: "",
  lastName: "",
  email: "",
  middleName: "",
  title: "",
  suffix: "",
  errors: {
    firstName: null,
    lastName: null,
    email: null,
  },
};

const Profile = (props) => {
  const navigate = useNavigate()
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });
  const [loader, setLoader] = useState(false);
  const tempFetch = useRef();
  let { form } = state;
  let { errors, firstName, lastName, email, middleName, title, suffix } = form;
  const user_id = localStorage.getItem("userID");

  const fetchFun = () => {
    fetchAccountData();
  };

  tempFetch.current = fetchFun;

  useEffect(() => {
    tempFetch.current();
  }, []);

  const fetchAccountData = () => {
    getAccount(user_id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        const response = res.data.data.attributes;

        setState({
          ...state,
          form: {
            ...form,
            firstName: response.first_name,
            middleName: response.middle_name,
            lastName: response.last_name,
            title: response.title,
            suffix: response.suffix,
            email: response.email,
            errors: errors,
          },
        });
      }
    });
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onSubmit = () => {
    const { form } = state;
    let bodyObj = {
      first_name: firstName,
      last_name: lastName,
      middle_name: middleName,
      title: title,
      suffix: suffix,
      email: email,
    };
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      const userId =
        localStorage.getItem("userID") && localStorage.getItem("userID");
      editPersonalInfo(userId, bodyObj).then((res) => {
        if (res && res.error) {
          return;
        }
        if (res && !res.error) {
          fetchAccountData();
          setLoader(false);
          ts("Profile edited successfully");
          navigate(`${path_url.personalInformation}/${user_id}`);
        }
      });
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper">
        <h2 className="fw-bold text-black content-title">
          <i className="fas fa-user-circle mr-3 text-blue title-img" />
          Profile
          <hr className="mb-0" />
        </h2>

        <div className="common-mt common-mb account-modal">
          <div className="row mx-auto">
            <div className="col-lg-2">
              <label className="w-100 fw-semibold text-left mb-2">Title</label>
              <Select
                name="title"
                value={title}
                className="form-control form-control-sm p-0 pl-2"
                title="Title"
                isReq={true}
                options={[
                  { value: "Mr.", label: "Mr." },
                  { value: "Miss.", label: "Miss." },
                  { value: "Mrs.", label: "Mrs." },
                  { value: "Dr.", label: "Dr." },
                ]}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Select Title"
                isRegister={true}
              />
            </div>
            <div className="col-lg-10">
              <label className="w-100 fw-semibold text-left mb-2">
                First Name
              </label>
              <Input
                name="firstName"
                value={firstName}
                className="form-control input-content"
                title="First Name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter your first name"
                error={errors.firstName}
              />
            </div>

            <div className="col-lg-12">
              <label className="w-100 fw-semibold text-left mb-2">
                Middle Name
              </label>
              <Input
                name="middleName"
                value={middleName}
                className="form-control input-content"
                title="Middle Name"
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter your middle name"
              />
            </div>
            <div className="col-lg-10">
              <label className="w-100 fw-semibold text-left mb-2">
                Last Name
              </label>
              <Input
                name="lastName"
                value={lastName}
                className="form-control input-content"
                title="Last Name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter your last name"
                error={errors.lastName}
              />
            </div>
            <div className="col-lg-2">
              <label className="w-100 fw-semibold text-left mb-2">Suffix</label>
              <Select
                name="suffix"
                value={suffix}
                className="form-control form-control-sm p-0 pl-2"
                isReq={true}
                options={[
                  { value: "I", label: "I" },
                  { value: "II", label: "II" },
                  { value: "III", label: "III" },
                  { value: "Sr.", label: "Sr." },
                  { value: "Jr.", label: "Jr." },
                ]}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Select Suffix"
                isRegister={true}
              />
            </div>
          </div>

          <div className="col-lg-12 mt-3">
            <label className="w-100 fw-semibold text-left mb-2">Email</label>
            <Input
              name="email"
              value={email}
              className="form-control input-content"
              title="Email"
              isReq={true}
              reqType="email"
              validationFunc={onInputValidate}
              onChangeFunc={(name, value, error) => {
                onInputChange(name, value, error);
              }}
              placeholder="Enter your email address"
              error={errors.email}
              disabled
            />
          </div>
          <div className="col-lg-12 mt-4 account-action">
            <Link to={`${path_url.personalInformation}/${user_id}`}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>
            <button
              onClick={onSubmit}
              className="btn btn-primary br-10 fz-16 fw-bold"
            >
              {loader ? "Saving..." : "Save"}
              {loader && (
                <i
                  className="ml-2 fa-spin fa-lg fa fa-spinner"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Profile;
