import React, { useState, useEffect } from "react";
import PetsIcon from "../Assets/images/pets-blue.svg";
import { path_url } from "../Utility/constant";
import { ts } from "../Utility/ReduxToaster";
import { getPets, delPet } from "../Utility/Services/mypets";
import DefaultPet from '../Assets/images/malamute.jpg';
import View from '../Assets/images/view.svg';
import Delete from '../Assets/images/delete.svg';
import { useNavigate } from "react-router-dom";
import 'react-responsive-modal/styles.css';

const Pets = (props) => {
  const [petsList, setPetsList] = useState([]);
  const [prompts, setPrompt] = useState(false); 

  const navigation = useNavigate();

  useEffect(() => {
    fetchPetsData();
  }, []);

  const fetchPetsData = () => {
    getPets().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setPetsList(res.data.pets);
        setPrompt(res.data.prompts);
      }
    });
  };

  const addEditPet = (val, type) => {
    if (type === "Edit") {
      navigation(path_url.add_edit_pet, {
        state: { id: val.id, data: val },
      });
    } else {
      navigation(path_url.add_edit_pet);
    }
  };

  const onDeletePet = (val) => {
    delPet(val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchPetsData();
        ts("Pet deleted successfully");
      }
    });
  };

  const viewPet = (val) => {
    navigation(path_url.view_pet, {
      state: {
        id: val.id,
        data: val,
        //addEditPet: addEditPet(),
        //onDeletePet: onDeletePet() 
      }
    });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section upload-wrapper personal-info">

        <h2 className="fw-bold text-black content-title text-center">
          <img className="ew-image" src={PetsIcon} alt="pet-icon" style={{padding: '7px'}} />
          {prompts && prompts.title}
          <hr className="mb-0" />
        </h2>

        <button onClick={() => addEditPet("", "New")} className="person-btn" key="new-pet-button" >
          Add a Pet{" "}
        </button>

        {prompts && ( 
          <div className="prompts">
            <strong>{prompts.name} </strong>  <p> {prompts.body} </p>
          </div>
        )}

        <div className="add-edit-modal">
          <div className="row mb-3">
            <div className="col-md-2">
              <label className="w-100 fw-semibold fz-18 text-left mb-2"></label>
            </div>
          </div>
          <div className="row">
            { petsList &&
              petsList.length > 0 &&
              petsList.map((pet, index) => {
                return (
                  <div className="col-md-3 mb-3" key={`pet-${index}`}>
                    <div className="imagePreviewList text-center position-relative"
                         style={{
                           borderColor: '#c8d0d1'
                         }}>
                      <div className="imageThumb py-2"
                           style={{
                              backgroundImage: `url(${pet.image_info && pet.image_info.url ? pet.image_info.url : DefaultPet})`,
                              backgroundPosition: 'center',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover'
                            }}>
                        <span>
                          <span onClick={() => viewPet(pet)}  className="position-absolute imageEditBtn h-18">
                            <img className="mr-3 cursor-pointer" src={View} alt="view-icon" />                          
                          </span>{' '}
                          <span onClick={() => onDeletePet(pet)} className="position-absolute imageDeleteBtn h-18">
                            <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon" />
                          </span>{' '}
                        </span>
                        {/*<img src={pet.image_info && pet.image_info.url ? pet.image_info.url : DefaultPet} className="img-fluid" alt="style-file" />*/}
                      </div>
                      <div className="imageName px-3 py-2 break-text"
                           style={{
                              borderBottomLeftRadius: '10px',
                              borderBottomRightRadius: '10px'
                           }}>
                        {pet.name}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Pets;
