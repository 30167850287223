import React, { useState, useEffect, useRef } from "react";
import { Input, Select } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import DeputyIcon from "../Assets/images/my-deputies-blue.svg";
import { createDeputy, editDeputy } from "../Utility/Services/deputy";

const initForm = {
  firstName: "",
  lastName: "",
  email: "",
  role: "",
  errors: {
    firstName: null,
    lastName: null,
    email: null,
    role: null,
  },
};

const roleOptions = [
  { value: "Insurance Agent", label: "Insurance Agent" },
  { value: "Executor", label: "Executor" },
  { value: "Attorney", label: "Attorney" },
  { value: "Family Member", label: "Family Member" },
  { value: "Spouse", label: "Spouse" },
  { value: "Viewer", label: "Viewer" },
  { value: "Clergy", label: "Clergy" },
  { value: "Pet Godparent", label: "Pet Godparent" },
];

const AddEditDeputies = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [loader, setLoader] = useState(false);
  // const [ userId, setUserId ] = useState('');
  const tempForm = useRef();

  let { form } = state;
  let location = useLocation();
  let { errors, firstName, lastName, email, role } = form;

  const formFun = () => {
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);

  const formUpdate = () => {
    let index = location.state && location.state.userid;
    let deputyData =
      location &&
      location.state &&
      location.state.data.filter((val, i) => i === index);
    form.firstName = deputyData && deputyData[0] && deputyData[0].first_name;
    form.lastName = deputyData && deputyData[0] && deputyData[0].last_name;
    form.email = deputyData && deputyData[0] && deputyData[0].email;
    form.role = deputyData && deputyData[0] && deputyData[0].role;
    // setUserId(deputyData && deputyData[0] && deputyData[0].id);
    setState({ ...state, form });
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onAddDeputies = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      createDeputy(firstName, lastName, email, role).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Deputy created successfully");
          setLoader(false);
          navigate(path_url.my_deputies);
        }
      });
    }
  };

  const onEditDeputies = () => {
    const { form } = state;
    let index = location.state && location.state.userid;
    let deputyData =
      location &&
      location.state &&
      location.state.data.filter((val, i) => i === index);
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      editDeputy(deputyData[0].id, firstName, lastName, email, role).then(
        (res) => {
          if (res && res.error) {
            te(res.message);
            setLoader(false);
            return;
          }
          if (res && !res.error) {
            ts("Deputy updated successfully");
            setLoader(false);
            navigate(path_url.my_deputies);
          }
        }
      );
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section deputy-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={DeputyIcon}
            alt="deputy-icon"
          />

          {location.state &&
          location.state.data &&
          location.state.data.length > 0
            ? "Update Deputies"
            : "Add Deputies"}
          <hr className="mb-0" />
        </h2>
        <div className="add-edit-modal">
          <div className="row mb-3 mx-auto">
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                First Name
              </label>
              <Input
                name="firstName"
                value={firstName}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="First Name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter first name"
                error={errors.firstName}
              />
            </div>
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Last Name
              </label>
              <Input
                name="lastName"
                value={lastName}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Last Name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter last name"
                error={errors.lastName}
              />
            </div>
          </div>

          <div className="row mb-3 mx-auto">
            <div className="col-lg-8">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Email Address
              </label>
              <Input
                name="email"
                value={email}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Email"
                isReq={true}
                reqType="email"
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter email"
                error={errors.email}
              />
            </div>
            <div className="col-lg-4">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">Role</label>
              <Select
                name="role"
                value={role}
                options={roleOptions}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Role"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Select role"
                error={errors.role}
              />
            </div>
          </div>

          <div className="col-lg-12 mt-4 deputy-form-action">
            <Link to={path_url.my_deputies}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>

            {location &&
              location.state &&
              location.state.data &&
              location.state.data.length > 0 && (
                <button
                  onClick={onEditDeputies}
                  type="button"
                  className="btn btn-primary br-10 fz-16 fw-bold"
                >
                  {loader ? "Updating..." : "Update"}
                  {loader && (
                    <i
                      className="ml-2 fa-spin fa-lg fa fa-spinner"
                      aria-hidden="true"
                    />
                  )}
                </button>
              )}
            {location && !location.state && (
              <button
                onClick={onAddDeputies}
                type="button"
                className="btn btn-primary br-10 fz-16 fw-bold"
              >
                {loader ? "Loading..." : "Add"}
                {loader && (
                  <i
                    className="ml-2 fa-spin fa-lg fa fa-spinner"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEditDeputies;
