import React from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../Common/Footer";

const AuthLayout = (props) => {
  return (
    <React.Fragment>
        <Outlet/>
      <Footer color={"bg-grey"} textColor={"text-black"} />
    </React.Fragment>
  );
};
export default AuthLayout;
