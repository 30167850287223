import { post, get, put, del } from "../httpInterceptor";

export const getTimeline = () => {
  return get(`/timelines`, true).then((res) => {
    return res;
  });
};

export const addEvent = (bodyObj) => {
  return post(`/events`, bodyObj, true, false).then((res) => {
    return res;
  });
};

export const delEvent = (eventId) => {
  return del(`/events/${eventId}`, {}, true, false).then((res) => {
    return res;
  });
};

export const editEvent = (bodyObj, eventId) => {
  return put(`/events/${eventId}`, bodyObj, true, false).then((res) => {
    return res;
  });
};
