import React from 'react';
import { Link } from 'react-router-dom';
import { path_url } from '../Utility/constant';
import ErrorPage from '../Assets/images/404.png';

const NotFound = (props) => {
	const user_id = localStorage.getItem('userID');

	return (
		<React.Fragment>
			<div className="pt-5 w-100 content-section">
				<div className="common-mt text-center d-flex justify-content-center">
					<div className="page-not-found">
						<img className="img-fluid error-img mb-4" src={ErrorPage} alt="error-page" />
						<h5 className="mb-3 text-black-2 fz-24 fw-bold">Oops! Page not found </h5>
						<h5 className="mb-5 fw-semibold"> Sorry, the page you're looking for doesn't exist</h5>
						{user_id ? (
							<div className="text-center d-inline-block mx-auto">
								<Link className="text-light-blue" to={`${path_url.personalInformation}/${user_id}`}>
									<h6 className="fw-bold fz-16 error-btn">Back to Personal Info</h6>
								</Link>
							</div>
						) : (
							<div className="text-center d-inline-block mx-auto">
								<Link className="text-light-blue" to={`${path_url.login}`}>
									<h6 className="fw-bold fz-16 error-btn">Back to Login</h6>
								</Link>
							</div>
						)}
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default NotFound;
