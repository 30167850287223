import React, { useState } from "react";
import { Input, TextArea } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import homeIcon from "../Assets/images/home-property-blue.svg";
import { addEstates } from "../Utility/Services/assets";

const initForm = [
  {
    ownerName: "",
    propertyName: "",
    address: "",
    errors: {
      ownerName: null,
      propertyName: null,
      address: null,
    },
  },
];

const AddEstate = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm) ? cloneDeep(initForm) : [],
  });

  let [estateData, setEstateData] = useState(1);

  const [loader, setLoader] = useState(false);

  let { form } = state;

  const onInputChange = (name, value, error = undefined, i) => {
    const { form } = state;
    form[i][name] = value;
    if (error !== undefined) {
      form[i].errors[name] = error;
    }
    setState({ form });
  };

  const onInputValidate = (name, error, i) => {
    let { form } = state;
    if (error) {
      form[i].errors[name] = error;
      setState({ form });
    } else {
      form[i].errors[name] = null;
      setState({ form });
    }
  };

  const onCounterAdd = (val) => {
    let { form } = state;
    if (val > 0) {
      setEstateData(val);
      form && form.push({ ...cloneDeep(initForm[0]) });
      setState({ ...state });
    }
  };

  const onDeleteEstate = (data, val, index) => {
    if (data > 0) {
      setEstateData(data);
      form.splice(index, 1);
      setState({ form });
    } else {
      val.ownerName = "";
      val.propertyName = "";
      val.address = "";
      setState({ form });
    }
  };

  const onAddEstate = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      let formObj = [];
      form &&
        form.map((val, i) =>
          formObj.push({
            owner_name: val.ownerName,
            property_name: val.propertyName,
            address: val.address,
          })
        );
      setLoader(true);
      addEstates({ estate: formObj }).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Estate added successfully");
          setLoader(false);
          navigate(path_url.home_property);
        }
      });
    } else {
      return false;
    }
  };

  let estateFormArray = [];
  for (let i = 0; i < estateData; i++) {
    estateFormArray.push(
      <React.Fragment>
        <div className="d-flex align-items-center mb-2">
          <div>
            <span className="fw-bold fz-18 text-blue ml-3">
              Real Estate {i + 1}
            </span>
          </div>
          {estateData > 1 && (
            <div
              onClick={() => {
                onDeleteEstate(estateData - 1, form[i], i);
              }}
              className="dynamic-icon cursor-pointer ml-5"
            >
              <i className="fa fa-trash fa-lg mr-2" aria-hidden="true" /> Delete
            </div>
          )}
        </div>
        <div className="row mb-2 mx-auto">
          <div className="col-lg-6">
            <label className="w-100 fw-semibold mb-2 text-left fz-18">
              Owner Name
            </label>
            <Input
              name="ownerName"
              value={form[i] && form[i].ownerName}
              className="form-control input-content text-black placeholder-black border-dark-grey"
              title="Owner Name"
              isReq={true}
              validationFunc={(name, error) => {
                onInputValidate(name, error, i);
              }}
              onChangeFunc={(name, value, error) => {
                onInputChange(name, value, error, i);
              }}
              placeholder="Enter owner name"
              error={form[i] && form[i].errors.ownerName}
            />
          </div>
          <div className="col-lg-6">
            <label className="w-100 fw-semibold mb-2 text-left fz-18">
              Property Name
            </label>
            <Input
              name="propertyName"
              value={form[i] && form[i].propertyName}
              className="form-control input-content text-black placeholder-black border-dark-grey"
              title="Property Name"
              isReq={true}
              validationFunc={(name, error) => {
                onInputValidate(name, error, i);
              }}
              onChangeFunc={(name, value, error) => {
                onInputChange(name, value, error, i);
              }}
              placeholder="Enter property name"
              error={form[i] && form[i].errors.propertyName}
            />
          </div>
        </div>
        <div className="row mb-3 mx-auto">
          <div className="col-lg-12">
            <label className="w-100 fw-semibold mb-2 text-left fz-18">Address</label>
            <TextArea
              name="address"
              value={form[i] && form[i].address}
              className="form-control input-content text-black placeholder-black border-dark-grey"
              title="Address"
              isReq={true}
              rows={4}
              validationFunc={(name, error) => {
                onInputValidate(name, error, i);
              }}
              onChangeFunc={(name, value, error) => {
                onInputChange(name, value, error, i);
              }}
              placeholder="Enter address"
              error={form[i] && form[i].errors.address}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  let estateStatus = false;
  let CheckForm = form.map((res, i) => {
    return Object.keys(form[i]).filter((res) => {
      if (res !== "errors" && !form[i][res]) {
        return res;
      }
    });
  });
  CheckForm.map((res, i) => (res.length === 0 ? (estateStatus = true) : null));
  estateStatus = CheckForm.every((res) => res.length === 0);

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={homeIcon}
            alt="home-icon"
          />
          Add Real Estate
          <hr className="mb-0" />
        </h2>
        <div className="common-mt add-edit-modal">
          <div className="row mx-auto d-flex justify-content-end">
            <div
              onClick={() => {
                onCounterAdd(estateData + 1);
              }}
              className="dynamic-icon cursor-pointer ml-3"
            >
              <i className="fa fa-plus-circle fa-lg mr-2" aria-hidden="true" />
              Add Property
            </div>
          </div>

          {estateFormArray ? estateFormArray : ""}
          <div className="col-lg-12 mt-4 home-action">
            <Link to={path_url.home_property}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>

            <button
              onClick={onAddEstate}
              disabled={!estateStatus}
              type="button"
              className="btn btn-primary br-10 fz-16 fw-bold"
            >
              {loader ? "Loading..." : "Add"}
              {loader && (
                <i
                  className="ml-2 fa-spin fa-lg fa fa-spinner"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEstate;
