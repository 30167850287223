import React from "react";
import PropTypes from "prop-types";
import { getRegExp } from "../../Utility/helper";
import NumberFormat from "react-number-format";

const changeHandler = (e, onChangeFunc) => {
  onChangeFunc(
    e.target.name.trim(""),
    e.target.value,
    e.target.name.trim("") && ""
  );
};

const validationHandler = (e, props, reqErrorMsg) => {
  if (!props.validationFunc) return;
  let { value, name } = e.target;
  const { title, isReq, reqType, minLength, validationMessage } = props;
  let errorMsg = isReq ? null : undefined;
  value = value.replace(/,/g, "");
  if (!value.replace(/\s/g, "").length === true && isReq) {
    errorMsg = validationMessage
      ? validationMessage.isReq
        ? validationMessage.isReq
        : reqErrorMsg
      : reqErrorMsg;
  } else if (value && reqType && !getRegExp(reqType).test(value)) {
    let msg = `Please enter valid ${title}`;

    errorMsg = validationMessage
      ? validationMessage[reqType]
        ? validationMessage[reqType]
        : msg
      : msg;
  } else if (minLength && value.length < minLength) {
    let msg = `${title} must be at least ${minLength} characters long`;
    errorMsg = validationMessage
      ? validationMessage.minLength
        ? validationMessage.minLength
        : msg
      : msg;
  }
  props.validationFunc(name, errorMsg);
};

const validateNumber = (evt, regex) => {
  var theEvent = evt || window.event;
  var key;
  // Handle paste
  if (theEvent.type === "paste") {
    key = theEvent.clipboardData.getData("text/plain");
  } else {
    // Handle key press
    key = theEvent.keyCode || theEvent.which;
    key = String.fromCharCode(key);
  }

  if (!regex.test(key)) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
};

const Input = (props) => {
  let msg = props.errorMsg || `Please Enter ${props.title}`;
  const reqErrorMsg = props.validationMessage
    ? props.validationMessage.isReq
      ? props.validationMessage.isReq
      : msg
    : msg;
  const {
    reqType,
    type,
    className,
    placeholder,
    disabled,
    onKeyPress,
    value,
    name,
    error,
    onKeyUpFunc,
    onChangeFunc,
    maxLength,
    minLength,
    min,
    id,
    max,
  } = props;
  const inputProps = {
    type: type,
    className: className,
    value: value,
  };

  const date_format = (month_year_value, max_value) => {
    if (month_year_value.length === 1 && month_year_value[0] > max_value[0]) {
      month_year_value = "0" + month_year_value;
    }
    if (month_year_value.length === 2) {
      if (Number(month_year_value) === 0) {
        month_year_value = "01";
      } else if (month_year_value > max_value) {
        month_year_value = max_value;
      }
    }
    return month_year_value;
  };

  const cardExpiry = (inputVal) => {
    let month = date_format(inputVal.substring(0, 2), "12");
    let date = date_format(inputVal.substring(2, 4), "40");
    return month + (date.length ? "/" + date : "");
  };

  if (min) inputProps.min = min;
  if (max) inputProps.max = max;
  if (id) inputProps.id = id;
  if (onKeyPress) inputProps.onKeyPress = onKeyPress;
  if (disabled) inputProps.disabled = disabled;
  if (placeholder) inputProps.placeholder = placeholder;
  if (name) inputProps.name = name;
  if (onKeyUpFunc) inputProps.onKeyUp = onKeyUpFunc;

  if (reqType === "number")
    inputProps.onKeyPress = (e) => {
      validateNumber(e, /[0-9]|\./);
    };
  if (maxLength) inputProps.maxLength = maxLength;
  if (minLength) inputProps.minLength = minLength;
  return (
    <div className="form-group text-left mb-2">
      {reqType === "cardNumber" ? (
        <NumberFormat
          format="#### #### #### ####"
          {...inputProps}
          onChange={(e) => {
            changeHandler(e, onChangeFunc);
          }}
          onBlur={(e) => {
            validationHandler(e, props, reqErrorMsg);
          }}
          autoComplete="none"
        />
      ) : reqType === "expiryDate" ? (
        <NumberFormat
          format={cardExpiry}
          {...inputProps}
          onChange={(e) => {
            changeHandler(e, onChangeFunc);
          }}
          onBlur={(e) => {
            validationHandler(e, props, reqErrorMsg);
          }}
          autoComplete="none"
        />
      ) : (
        // <div>
        <input
          {...inputProps}
          onChange={(e) => changeHandler(e, onChangeFunc)}
          onBlur={(e) => validationHandler(e, props, reqErrorMsg)}
          autoComplete="none"
          readOnly={true}
          onFocus={(e) => e.target.removeAttribute("readOnly")}
          onTouchStart={(e) => e.target.removeAttribute("readOnly")}
          style={{ backgroundColor: "#fff" }}
        />
        // </div>
      )}
      {error && (
        <span className="reqEstric mb-0">
          <i className="fas fa-exclamation-circle" />{" "}
          {error === true ? reqErrorMsg : error}
        </span>
      )}
    </div>
  );
};

Input.defaultProps = {
  type: "text",
  className: "form-control",
  isReq: null,
  reqType: "",
  value: "",
  onChangeFunc: () => {},
  onKeyUpFunc: () => {},
  loading: null,
};

Input.propTypes = {
  title: PropTypes.string,
  isReq: PropTypes.bool,
  disabled: PropTypes.bool,
  reqType: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.any,
  onChangeFunc: PropTypes.func,
  validationFunc: PropTypes.func,
  minLength: PropTypes.number,
  maxLength: PropTypes.number,
  onKeyPress: PropTypes.func,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  prefix: PropTypes.string,
};

export default React.memo(Input);
