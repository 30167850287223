import React, { useState } from "react";
import DefaultPet from '../Assets/images/malamute.jpg';
import Edit from '../Assets/images/edit.svg';
import Delete from '../Assets/images/delete.svg';
import PetsIcon from "../Assets/images/pets-blue.svg";
import { delPet } from "../Utility/Services/mypets";
import { path_url } from "../Utility/constant";
import { ts } from "../Utility/ReduxToaster";
import { useLocation, useNavigate } from "react-router-dom";
import 'react-responsive-modal/styles.css';
import FileViewerModal from "../Common/Input/FileViewerModal";

const Pet = (props) => {
  const navigation = useNavigate();
  let location = useLocation();

  const pet = location.state && location.state.data;

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);

  const showFileModal = (file) => {
    setFileType(file.file_type);
    setFileUrl(file.url);
    setIsOpen(true)
  }
  
  const editingPet = (val, type) => {
    if (type === "Edit") {
      navigation(path_url.add_edit_pet, {
        state: { id: val.id, data: val },
      });
    } else {
      navigation(path_url.add_edit_pet);
    }
  };

  const deletingPet = (val) => {
    delPet(val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        navigation(path_url.my_pets);
        ts("Pet deleted successfully");
      }
    });
  };
  
  return (
    <React.Fragment>
      <FileViewerModal open={modalIsOpen} setIsOpen={setIsOpen} fileType={fileType} fileUrl={fileUrl} />
      <div className="w-100 content-section upload-wrapper personal-info">
        <h2 className="fw-bold text-black content-title">
          <img className="mr-3 text-blue title-img" 
               src={PetsIcon} 
               alt="pet-icon"
               style={{padding: '7px'}} />
          Pet
          <hr className="mb-0" />
        </h2>
        <div className="card mb-4"
             style={{
              border: '1px solid rgb(200 208 209)',
             }}>
          <div className="card-header"
               style={{
                paddingBottom: '0'
               }}>
            <div className="d-flex justify-content-between">
              <p className="text-black fz-18 fw-semibold mb-2">
                {pet.name} 
              </p>
              <div>
                <span onClick={() => editingPet(pet, 'Edit')}>
                  <img src={Edit} 
                       className="mr-3 cursor-pointer" 
                       alt="Edit" />
                </span>{' '}
                <span onClick={() => deletingPet(pet)}>
                  <img src={Delete} 
                       className="mr-3 cursor-pointer" 
                       alt="Delete" />
                </span>{' '}
              </div>
            </div>
          </div>
          <div className="card-body"
               style={{
                paddingTop: '0'
               }}>
            <hr className="mb-0" />
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12">
                <div className="d-flex justify-content-between" >
                    <img src={pet.image_info && pet.image_info.url ? pet.image_info.url : DefaultPet}
                         className="img-responsive img-thumbnail"
                         alt='pet'
                         style={{
                          border: '1px solid #c1c1c1',
                          padding: '0.5rem'
                        }}/>
                </div>
              </div>
              <div className="col-lg-8 col-md-6 col-sm-12 col-xs-12" >
                <p className="text-dark-grey fz-15 mb-1" >
                  <span className="mr-1 fz-14 fw-bold">Breed: </span>
                  {pet.breed}
                </p>
                <p className="text-dark-grey fz-15 mb-1" >
                  <span className="mr-1 fz-14 fw-bold">Colors: </span>
                  {pet.colors}
                </p>
                <p className="text-dark-grey fz-15 mb-1" >
                  <span className="mr-1 fz-14 fw-bold">Chip: </span>
                  {pet.chip_number}
                </p>
                <p className="text-dark-grey fz-15 mb-1" >
                  <span className="mr-1 fz-14 fw-bold">Insurance Info: </span>
                  {pet.insurance_info}
                </p>
                <p className="text-dark-grey fz-15 mb-1" >
                  <span className="mr-1 fz-14 fw-bold">Special Instructions: </span>
                  {pet.special_instructions}
                </p>
                <p className="text-dark-grey fz-15 mb-1" >
                  <span className="mr-1 fz-14 fw-bold">Guardian Name: </span>
                  {pet.godparent_first_name} {pet.godparent_last_name}
                </p>
                <p className="text-dark-grey fz-15 mb-1" >
                  <span className="mr-1 fz-14 fw-bold">Guardian Email: </span>
                  {pet.godparent_email}
                </p>
                <div className="text-dark-grey fz-15 mb-1" >
                  <span className="mr-1 fz-14 fw-bold">Documents: </span>
                  <ul>
                    { pet.documents_info &&
                      pet.documents_info.length > 0 &&
                      pet.documents_info.map((val, index) => {
                          return (
                              <li key={val.id}>
                                 <span className="mr-1 fz-15 text-decoration-underline cursor-pointer"
                                       onClick={() => {
                                          showFileModal(val)
                                         //window.open(`${val.url}`, "_blank");
                                       }}>
                                    {val.filename}
                                 </span>
                              </li>
                          );
                        })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Pet;