import { get, post, put, del } from "../httpInterceptor";

export const getPet = (id) => {
  return get(`/pets/${id}`, {}, true, false).then((res) => {
    return res;
  });
};

export const getPets = () => {
  return get(`/pets/user_pets`, true).then((res) => {
    return res;
  });
};

export const createPet = (pet) => {

  return post(`/pets`, pet, true, false).then((res) => {
    return res;
  });
};

export const editPet = (pet, id) => {

  return put(`/pets/${id}`, pet, true, false).then((res) => {
    return res;
  });
};

export const createPetImage = (imageObj) => {
  return post(`/images/`, imageObj, true, true);
};

export const createPetDoc = (docObj) => {
  return post(`/images/`, docObj, true, true);
};

/*
export const editPet = (id, petName, breed, colors, chipNumber, godparents, insuranceInfo, specialInstructions, image, documents) => {
  return put(
    `/pets/${id}?[pet][name]=${petName}&[pet][breed]=${breed}&[pet][colors]=${colors}&[pet][chip_number]=${chipNumber}&[pet][godparents]=${godparents}&[pet][insurance_info]=${insuranceInfo}&[pet][special_instructions]=${specialInstructions}&[pet][image]=${image}&[pet][documents][]=${documents}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
}
*/

export const delPet = (id) => {
  return del(`/pets/${id}`, true, false).then((res) => {
    return res;
  });
};
