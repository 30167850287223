import React from "react";
import ReactFamilyTree from "react-family-tree";
import FamilyNode from "./FamilyNode";

const nodes = [
  {
    id: "0",
    gender: "male",
    name: "Ned Stark",
    parents: [
      { id: "6", type: "blood" },
      { id: "7", type: "blood" },
    ],
    spouses: [{ id: "1", type: "married" }],
    children: [
      { id: "2", type: "blood" },
      { id: "3", type: "blood" },
    ],
    siblings: [
      { id: "4", type: "blood" },
      { id: "5", type: "blood" },
    ],
  },
  {
    id: "1",
    gender: "female",
    name: "Catelyn Stark",
    parents: [],
    spouses: [{ id: "0", type: "married" }],
    children: [
      { id: "2", type: "blood" },
      { id: "3", type: "blood" },
    ],
    siblings: [],
  },
  {
    id: "2",
    gender: "male",
    name: "Robb Stark",
    parents: [
      { id: "0", type: "blood" },
      { id: "1", type: "blood" },
    ],
    spouses: [],
    children: [],
    siblings: [{ id: "3", type: "blood" }],
  },
  {
    id: "3",
    gender: "female",
    name: "Sansa Stark",
    parents: [
      { id: "0", type: "blood" },
      { id: "1", type: "blood" },
    ],
    spouses: [],
    children: [],
    siblings: [{ id: "2", type: "blood" }],
  },
  {
    id: "4",
    gender: "female",
    name: "Lyanna Stark",
    parents: [
      { id: "6", type: "blood" },
      { id: "7", type: "blood" },
    ],
    spouses: [{ id: "8", type: "married" }],
    children: [{ id: "9", type: "blood" }],
    siblings: [
      { id: "0", type: "blood" },
      { id: "5", type: "blood" },
    ],
  },
  {
    id: "5",
    gender: "male",
    name: "Benjen Stark",
    parents: [
      { id: "6", type: "blood" },
      { id: "7", type: "blood" },
    ],
    spouses: [],
    children: [],
    siblings: [
      { id: "0", type: "blood" },
      { id: "4", type: "blood" },
    ],
  },
  {
    id: "6",
    gender: "male",
    name: "Rickard Stark",
    parents: [],
    spouses: [{ id: "7", type: "married" }],
    children: [
      { id: "0", type: "blood" },
      { id: "4", type: "blood" },
      { id: "5", type: "blood" },
    ],
    siblings: [],
  },
  {
    id: "7",
    gender: "female",
    name: "Lyarra Stark",
    parents: [],
    spouses: [{ id: "6", type: "married" }],
    children: [
      { id: "0", type: "blood" },
      { id: "4", type: "blood" },
      { id: "5", type: "blood" },
    ],
    siblings: [],
  },
  {
    id: "8",
    gender: "male",
    name: "Rhaegar",
    parents: [],
    spouses: [{ id: "4", type: "married" }],
    children: [{ id: "9", type: "blood" }],
    siblings: [],
  },
  {
    id: "9",
    gender: "male",
    name: "Jon Snow",
    parents: [
      { id: "4", type: "blood" },
      { id: "8", type: "blood" },
    ],
    spouses: [],
    children: [],
    siblings: [],
  },
];

const FamilyTree = (props) => {
  const H = 250;
  const W = 300;
  return (
    <div className="w-100 content-section personal-info account-wrapper user-wrapper">
      <h2 className="fw-bold text-black content-title">
        <i className="fab fa-pagelines title-img text-blue mr-3 pl-3"></i>
        Family Tree
        <hr className="mb-0" />
      </h2>
      {/* <button
        className="person-btn"
        onClick={() => props.history.push(`${path_url.add_edit_family_tree}`)}
      >
        Add Family Member
      </button> */}
      <div className="family-tree-root">
        <ReactFamilyTree
          nodes={nodes}
          rootId="0"
          width={W}
          height={H}
          canvasClassName="family-tree"
          renderNode={(node) => (
            <FamilyNode
              key={node.id}
              node={node}
              isRoot={node.id === "0"}
              style={{
                width: W / 1.5,
                height: H / 1.5,
                marginTop: "0px",
                marginLeft: "55px",
                transform: `translate(${node.left * (W / 2)}px, ${
                  node.top * (H / 2)
                }px)`,
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default FamilyTree;
