import React, { useState, useEffect } from "react";
import { path_url } from "../Utility/constant";
import { ts } from "../Utility/ReduxToaster";
import LovedOnesIcon from "../Assets/images/msg-blue.svg";
import Edit from "../Assets/images/edit.svg";
import Delete from "../Assets/images/delete.svg";
import {
  listMessages,
  delMessage,
} from "../Utility/Services/message";
import { useNavigate } from "react-router-dom";

const MessageToLovedOnes = (props) => {
  const navigate = useNavigate();
  const [messagesList, setMessagesList] = useState([]);

  const [ prompts, setPrompts ] = useState(false);

  const addEditMessage = (message, type) => {
    if (type === "Edit") {
      navigate(path_url.add_edit_message_to_loved_once, {
        state: { message_id: message.id, data: message },
      });
    } else {
      navigate(path_url.add_edit_message_to_loved_once);
    }
  };
  useEffect(() => {
    fetchMessagesData();
  }, []);

  const fetchMessagesData = () => {
    listMessages().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setMessagesList(res.data.msgs);
        setPrompts(res.data.prompts);

      }
    });
  };

  const onDeleteMessage = (val) => {
    delMessage(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchMessagesData();
        ts("Message deleted successfully");
      }
    });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section personal-info account-wrapper user-wrapper">

        <h2 className="fw-bold text-black content-title text-center">
          <img className="ew-image" src={LovedOnesIcon} alt="lovedOnceIcon" />
           {prompts && prompts.title}
          <hr className="mb-0" />
        </h2>
        <button
          onClick={() => addEditMessage("", "New")}
          className="person-btn" >
          Add Messages
        </button>

        {prompts  && (   
          <div className="prompts">
            <strong>{prompts.name} </strong> <p> {prompts.body} </p>
          </div>
        )}

        <div className="row mx-0">
          <div className="flex flex-column mb-5 mt-1 w-100">
            <div className="card mb-4">
              <div className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                   id="" >
                <h5 className="flex-1 mb-0">
                  Messages
                </h5>
              </div>
              <div className="card-body">
                <div className="row mx-auto mb-2">
                  <div className="col-md-12 col-sm-12 col-xs-12 mb-3">           
                    {messagesList && messagesList.length > 0 && (
                      <React.Fragment>
                        {messagesList.map((message, index) => {
                          return (
                            <div className="property-card min-h-185 mb-3" key={index}>
                              <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                  <div className="d-flex justify-content-between">
                                    <p className="text-blue fz-18 fw-semibold mb-2">
                                      <span className="mr-1">Send to: </span>
                                      {message.to}
                                    </p>
                                    <div className="actions">
                                      <span onClick={() => addEditMessage(message, 'Edit')}>
                                        <img className="mr-3 cursor-pointer" src={Edit} alt="Edit" />
                                      </span>{" "}
                                      <span onClick={() => onDeleteMessage(message)}>
                                        <img className="mr-3 cursor-pointer" src={Delete} alt="Delete" />
                                      </span>
                                    </div>
                                  </div>
                                  <p className="text-dark-grey mb-1">
                                    <span className="mr-1 fz-14 fw-bold">Email: </span>
                                    <span className="fz-16">{message.email}</span>
                                  </p>
                                  <p className="text-dark-grey mb-1">
                                    <span className="mr-1 fz-14 fw-bold">Phone: </span>
                                    <span className="fz-16">{message.phone_number}</span>
                                  </p>
                                  <p className="text-dark-grey mb-1">
                                    <span className="mr-1 fz-14 fw-bold">Message: </span>
                                    <span className="fz-16">{message.body}</span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </React.Fragment>
                    )}
                    {messagesList &&
                      messagesList.data &&
                      messagesList.data.length === 0 && (
                        <div>
                          <h6 className="mb-0">No records found</h6>
                        </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default MessageToLovedOnes;