import React, { useState, useEffect, useRef } from "react";
import { Chrono } from "react-chrono";
import { titleCase } from "../Utility/helper";
import Edit from "../Assets/images/edit.svg";
import Delete from "../Assets/images/delete.svg";
import { path_url } from "../Utility/constant";
import { delEvent, getTimeline } from "../Utility/Services/timeline";
import { ts } from "../Utility/ReduxToaster";
import { useNavigate } from "react-router-dom";
import TimeIcon from "../Assets/images/timeline-blue.svg";


const Timeline = (props) => {
  const [items, setItems] = useState([]);
  const [itemsValues, setItemsValue] = useState([]);
  const fetchTimeline = useRef();
  const navigate = useNavigate();
  const [prompts, setPrompt] = useState(false); 

  const fetchTimelineFun = () => {
    fetchTimelineData();
  };

  fetchTimeline.current = fetchTimelineFun;
  
  useEffect(() => {
    fetchTimeline.current();
  }, []);

  const createDivArray = (timeline) => {
    setItemsValue(timeline.map((item) => item));
    return timeline.map((item) => (
      <div className="datebox">
        <div className="d-flex title-date">
          <h4 className="mt-2 ">{item.title && titleCase(item.title)}</h4>
          <div className="ml-auto mt-2 timeline-action">
            <span
              onClick={() =>
                navigate(path_url.add_edit_timeline, 
                  {state: {item: item, type: "Edit", id: item.id}}
                  )
              }
            >
              <img src={Edit} className="cursor-pointer" alt="edit-img" />
            </span>
            <span onClick={() => onDeleteTimeline(item)}>
              <img
                src={Delete}
                className="cursor-pointer ml-3"
                alt="delete-img"
              />
            </span>
          </div>
        </div>
        <hr
          style={{
            borderTop: "1px solid black",
            //width: 300,
          }}
        />
        <p className="timeline-dec">{item.description}</p>
      </div>
    ));
  };

  const fetchTimelineData = () => {
    getTimeline().then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && !res.error) {
        setPrompt(res.data.meta.prompts);
        const data = res.data.data && res.data.data.attributes.timeline_event;

        setItems(
          createDivArray(
            data.sort(
              (item1, item2) => new Date(item1.date) - new Date(item2.date)
            )
          )
        );

        if (data.length < 1) {
          navigate(path_url.add_edit_timeline);
        }
      }
    });
  };
  const onDeleteTimeline = (val) => {
    const temp = items.filter((item) => val.id !== item.id);
    setItems(temp);
    delEvent(val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchTimelineData();
        ts("Timeline deleted successfully");
      }
    });
  };

  return (
    <div className="w-100 content-section personal-info">

        <h2 className="fw-bold text-black content-title text-center">

        <img className="ew-image" src={TimeIcon} alt="time line" />
          {prompts && prompts.title}
        <button
          onClick={() =>
            navigate({
              pathname: path_url.add_edit_timeline,
              type: "Add",
            })
          }
          className="person-btn add-timeline d-flex justify-content-center align-items-center"
        >
          <span className="fz-18">Add an Event</span>
        </button>
        <hr className="mb-0" />
      </h2>

      {prompts && ( 
        <div className="prompts">
          <strong>{prompts.name} </strong>  <p> {prompts.body} </p>
        </div>
      )}

      <hr className="mb-0" />

      <div className="row mx-0 timeline-box common-mb">
        <div className="w-100">
          {items && items.length > 0 && (
            <div>
              <Chrono
                mode="VERTICAL_ALTERNATING"
                items={
                  itemsValues &&
                  itemsValues.length > 0 &&
                  itemsValues.map((item) => {
                    return { title: item.date };
                  })
                }
                hideControls={true}
                cardHeight={200}
                cardWidth={600}
                enableOutline={true}
                theme={{
                  primary: "black",
                  secondary: "#10b9e4",
                }}
              >
                {items &&
                  items.map((item) => {
                    return item;
                  })}
              </Chrono>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Timeline;
