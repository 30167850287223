import React, { useState, useEffect } from "react";
import Edit from "../Assets/images/edit.svg";
import Delete from "../Assets/images/delete.svg";
import DeputyIcon from "../Assets/images/my-deputies-blue.svg";
import { path_url } from "../Utility/constant";
import { ts } from "../Utility/ReduxToaster";
import {
  listDeputies,
  delDeputy,
  sendInvite,
} from "../Utility/Services/deputy";
import { useNavigate } from "react-router-dom";

const Deputies = (props) => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [id, setId] = useState(null);
  const [deputiesList, setDeputiesList] = useState([]);

  const addEditDeputies = (id, type) => {
    if (type === "Edit") {
      navigate(path_url.add_edit_deputy, {
        state: { userid: id, data: deputiesList },
      });
    } else {
      navigate(path_url.add_edit_deputy);
    }
  };

  useEffect(() => {
    fetchDeputiesData();
  }, []);

  const fetchDeputiesData = () => {
    listDeputies().then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && res.data && !res.error) {
        setDeputiesList(res.data.deputies);
      }
    });
  };

  const onDeleteDeputy = (val) => {
    delDeputy(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchDeputiesData();
        ts("Deputy deleted successfully");
      }
    });
  };

  const onSendInvite = (val) => {
    setId(val.id);
    setLoader(true);
    sendInvite(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        ts("Invitation sent successfully");
        setLoader(false);
        setId(null);
      }
    });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section personal-info account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img className="img-fluid mr-4" src={DeputyIcon} alt="deputy-icon" />
           Who do you Trust?
          <hr className="mb-0" />
        </h2>
        <button
          onClick={() => addEditDeputies("", "New")}
          className="person-btn"
        >
          Add Deputies
        </button>

        <div className="prompts">
          <strong>Who do you Trust? </strong> 
          <p> Here you can appoint your people to act on your behalf.</p>
        </div>

        <div className="row mx-0 ">
          <div className="flex flex-column mb-5 mt-1 w-100">
            <div id="accordion">
              {deputiesList && deputiesList.length > 0 && (
                  <>
                  {deputiesList.map((val, index) => {
                    return (
                        <React.Fragment>
                          <div className="card mb-4">
                            <div
                                className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                                id={"heading-" + index}
                            >
                              <h5 className="flex-1 mb-0">
                                <a
                                    className="collapsed"
                                    role="button"
                                    data-toggle="collapse"
                                    href={"#collapse-" + index}
                                    aria-expanded="false"
                                    aria-controls={"collapse-" + index}
                                >
                                  {val.full_name}
                                </a>
                              </h5>
                              <div className="d-flex justify-content-left">
                                <span
                                    onClick={() => addEditDeputies(index, "Edit")}
                                >
                                  <img
                                      src={Edit}
                                      className="mr-3 cursor-pointer"
                                      alt="edit-icon"
                                  />
                                </span>
                                <span onClick={() => onDeleteDeputy(val)}>
                                  <img
                                      className="mr-3 cursor-pointer"
                                      src={Delete}
                                      alt="delete-icon"
                                  />
                                </span>{" "}
                                <span onClick={() => onSendInvite(val)}>
                                  <button className="invite-btn cursor-pointer">
                                    {loader && val.id === id ? (
                                        "Sending..."
                                    ) : (
                                        <>
                                          <i
                                              className="fa fa-paper-plane mr-2"
                                              aria-hidden="true"
                                          />
                                          Send Invite
                                        </>
                                    )}
                                    {loader && val.id === id && (
                                        <i
                                            className="ml-2 fa-spin fa-lg fa fa-spinner"
                                            aria-hidden="true"
                                        />
                                    )}
                                  </button>
                                </span>
                              </div>

                            </div>
                            <div
                                id={"collapse-" + index}
                                className="collapse"
                                data-parent="#accordion"
                                aria-labelledby={"heading-" + index}
                            >
                              <div className="card-body">
                                {(
                                    <div className="property-card min-h-185 mb-3">
                                      <div className="d-flex align-items-center justify-content-between mb-4">
                                        <div className="d-flex align-items-center">
                                          <i className="far fa-user mr-2"></i>
                                          <p className="text-blue fz-15 fw-semibold mb-0 left-2">
                                            { val.role }
                                          </p>
                                        </div>
                                      </div>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="d-flex align-items-center">
                                          <i className="far fa-envelope mr-2"></i>
                                          <p className="text-dark-grey fz-15 mb-0 left-2">
                                            { val.email }
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                )}
                              </div>
                            </div>
                          </div>

                        </React.Fragment>
                    );
                  })}
                  </>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Deputies;
