import React, { useState, useEffect, useRef } from "react";
import { Input, Select } from "../Common/Input";
import { cloneDeep } from "lodash";
import {
  getFormDetails,
  accountTypeOptions,
  titleCase,
} from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import {
  addAccount,
  editAccount,
  getSubscriptionPlanDetails,
  getSubscriptionPlans,
  getCodeDetails,
} from "../Utility/Services/account";

const initForm = {
  accountType: "",
  subscriptionPlan: "",
  code: "",
  errors: {
    accountType: null,
  },
};

const AddEditAccount = (props) => {
  const [checked, setonChecked] = useState(false);
  const [applied, setApplied] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [planDetails, setPlanDetails] = useState([
    {
      planName: "",
      amount: "",
      expirationDate: "02/11",
      startDate: new Date().getDate(),
      discountAmount: "",
      couponCode: "",
      originalAmount: "",
      description: ""
    },
  ]);

  const [planOptions, setPlanOptions] = useState([]);

  const onCheck = () => {
    if (checked) {
      setonChecked(false);
    } else {
      setonChecked(true);
    }
  };

  const type =
    location.state && location.state.type === "edit"
      ? "edit"
      : "add";
  const [loader, setLoader] = useState(false);
  const [subPlanLoader, setSubPlanLoader] = useState(false);
  const user_id = localStorage.getItem("userID");

  let { form } = state;
  let { errors, accountType, subscriptionPlan, code } = form;

  let {
    planName,
    startDate,
    expirationDate,
    amount,
    couponCode,
    originalAmount,
    description,
  } = planDetails;

  const [accountId, setAccountId] = useState(null);
  const tempState = useRef();
  const tempApplied = useRef();

  const stateFunc = () => {
    const state = location.state;
    if (state && state.account) {
      if (state.type === "edit") {
        setAccountId(state.account.accountId);
        setState({
          ...state,
          form: {
            ...state.form,
            ...state.account,
            errors: errors,
            accountType:
              state.account.accountType &&
              state.account.accountType.toLowerCase(),
            subscriptionPlan:
              state.account.subscriptionPlan &&
              titleCase(state.account.subscriptionPlan),
            code: state.account.code,
          },
        });
      } else if (state.type === "add") {
        setState({
          ...state,
          form: {
            ...state.form,
            errors: errors,
            accountType:
              state.account.accountType &&
              state.account.accountType.toLowerCase(),
            subscriptionPlan:
              state.account.subscriptionPlan &&
              titleCase(state.account.subscriptionPlan),
            code: state.account.code,
          },
        });
      }
    }
  };

  tempState.current = stateFunc;

  useEffect(() => {
    tempState.current();
  }, []);

  const appliedFunc = () => {
    if (applied) {
      setApplied(false);
    }
    if (accountType) {
      getSubscriptionPlanOptions();
    }
    if (subscriptionPlan && subscriptionPlan !== "Free") {
      getSubscriptionPlanDetails(subscriptionPlan).then((res) => {
        if (res && res.error) {
          return;
        }
        setSubPlanLoader(true);
        if (res && !res.error) {
          if (res.data && res.data.subscription_plan) {
            setSubPlanLoader(false);
            const response = res.data.subscription_plan;
            setPlanDetails({
              planName: response.name,
              amount: response.amount,
              expirationDate: response.expiration_date,
              startDate: response.start_date,
              description: response.description
            });
          }
        }
      });
    }
  };

  tempApplied.current = appliedFunc;

  useEffect(() => {
    tempApplied.current();
  }, [subscriptionPlan, accountType]);

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onEditAccount = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);

    if (obj) {
      setLoader(true);

      let bodyObj = {
        "[account][name]":"",
        "[account][subscription_plan][name]":subscriptionPlan,
        "[account][subscription_plan][account_type]":accountType
      };
      if (subscriptionPlan !== "Free" && amount !== 0) {
        navigate(path_url.payment, {
          state: {
            subscriptionPlan,
            code,
            amount,
            bodyObj,
            type: "edit",
            accountId,
          },
        });
      } else {
        editAccount(accountId, bodyObj).then((resAcc) => {
          if (resAcc && resAcc.error) {
            te(resAcc.message);
            setLoader(false);
            return;
          }
          if (resAcc && !resAcc.error) {
            ts("Account updated successfully");
            setLoader(false);
            navigate(path_url.account_management);
          }
        });
      }
    } else {
      return false;
    }
  };

  const onAddAccount = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);

      let bodyObj = {
        "[account][name]":"",
        "[account][subscription_plan][name]":subscriptionPlan,
        "[account][subscription_plan][account_type]":accountType
      };

      if (subscriptionPlan !== "Free" && amount !== 0) {
        navigate(path_url.payment, {
          state: { subscriptionPlan, code, amount, bodyObj, type: "add" },
        });
      } else {
        addAccount(bodyObj).then((resAcc) => {
          if (resAcc && resAcc.error) {
            te(resAcc.message);
            setLoader(false);
            return;
          }
          if (resAcc && !resAcc.error) {
            ts("Account added successfully");
            setLoader(false);
            navigate(path_url.account_management);
          }
        });
      }
    } else {
      return false;
    }
  };

  const getSubscriptionPlanOptions = () => {
    getSubscriptionPlans(accountType).then((res) => {
      if (res && res.error) {
        return false;
      }

      if (res && res.data) {
        if (res.data.length > 0) {
          //console.log(res.data);
          setPlanOptions(
            res.data.map((options) => {
              return {
                value: options.subscription_plan_option_value,
                label: options.subscription_plan_option_value + " - $" + (options.subscription_plan_amount || "0"),
              };
            })
          );
        } else {
          setPlanOptions([]);
        }
      }
    });
  };

  const onCouponApplied = () => {
    if (code) {
      getCodeDetails(subscriptionPlan, code).then((res) => {
        if (res && res.error) {
          return;
        }
        setSubPlanLoader(true);
        if (res && !res.error) {
          if (res.data && res.data.subscription_plan) {
            setSubPlanLoader(false);
            const response = res.data.subscription_plan;
            if (!res.data.discount_amount) {
              te("Invalid Coupon Code");
              setApplied(false);
              setState({
                ...state,
                form: {
                  ...state.form,
                  errors: errors,
                  code: "",
                },
              });
              return;
            }

            setPlanDetails({
              planName: response.name,
              amount: response.amount,
              expirationDate: response.expiration_date,
              startDate: response.start_date,
              discountAmount: res.data.discount_amount,
              couponCode: res.data.coupon_code,
              originalAmount: res.data.original_amount,
              description: res.data.description
            });
          }
        }
        ts("Coupon Applied successfully");
        setApplied(true);
        setonChecked(false);
        setState({
          ...state,
          form: {
            ...state.form,
            errors: errors,
            code: "",
          },
        });
      });
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <i className="fas fa-users title-img text-blue mr-3" />
          {location.state && location.state.type === "edit"
            ? "Edit Account"
            : "Add Account"}
          <hr className="mb-0" />
        </h2>
        <div className="common-mt common-mb account-modal">
          <div className="row mx-auto">
            <div className="col-lg-6 mt-3">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">
                Account Type
              </label>
              <Select
                name="accountType"
                value={accountType}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Account Type"
                isReq={true}
                options={accountTypeOptions}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Select account type"
                error={errors.accountType}
              />
            </div>
            <div className="col-lg-6 mt-3">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">
                Subcription Plan (optional)
              </label>
              <Select
                name="subscriptionPlan"
                value={subscriptionPlan}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Subcription Plan"
                options={planOptions}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Select subscription Plan"
                error={errors.subscriptionPlan}
              />
            </div>

            {subPlanLoader && (
              <div className="col-lg-12 text-center mt-4">
                <i
                  className="ml-2 fa-spin fa-lg fa fa-spinner"
                  aria-hidden="true"
                />
              </div>
            )}
            {!subPlanLoader && subscriptionPlan && (
              <>
                <div className="col-lg-12 mt-3">
                  <div className="card mb-4">
                    <div
                      className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                      id="heading-1"
                    >
                      <p className="flex-1 mb-0 fw-bold text-center">
                        Subcription Plan Details
                      </p>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-lg-6 mt-3">
                          <div className="d-flex align-items-center">
                            <i className="fa fa-book mr-3"></i>
                            <p className="text-dark-grey fz-14 mb-0">
                              <strong> Plan</strong> :{" "}
                              {planName && titleCase(planName)}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-12 mt-3">
                          <div className="d-flex align-items-center">
                            <i className="fa fa-info-circle mr-3"></i>
                            <p className="text-dark-grey fz-14 mb-0">
                              <strong> Details</strong> :{" "}
                              {description && titleCase(description)}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="col-lg-6 mt-3">
                          <div className="d-flex align-items-center">
                            <i className="far fa-calendar-minus mr-3"></i>
                            <p className="text-dark-grey fz-14 mb-0">
                              <strong> Start Date </strong> :{" "}
                              {startDate && startDate}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 mb-2 mt-3">
                          <div className="d-flex align-items-center">
                            <i className="far fa-calendar-check mr-3"></i>{" "}
                            <p className="text-dark-grey fz-14 mb-0">
                              <strong>Expiration Date</strong> :{" "}
                              {expirationDate && expirationDate}
                            </p>
                          </div>
                        </div>
                        <div className="col-lg-6 mt-3">
                          <div className="d-flex align-items-center">
                            <i className="fa fa-money mr-3"></i>
                            <p className="text-dark-grey fz-14 mb-0">
                              <strong> Amount </strong>:{" "}
                              {originalAmount === amount ? (
                                `$${amount || 0}`
                              ) : (
                                <>
                                  <strike>{originalAmount}</strike>
                                  <span> ${amount}</span>
                                </>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                      {applied && (
                        <div className="row ">
                          <div className="col-lg-6 mb-2 mt-2">
                            <div className="d-flex align-items-center">
                              <p className="text-dark-grey fz-14 mb-0">
                                <strong style={{ color: "green" }}>
                                  {" "}
                                  Coupon {couponCode} Applied successfully
                                </strong>{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                      <hr />
                      <div className="row">
                        <div className="col-lg-12 mt-2 ">
                          <div className="d-flex align-items-center">
                            <label className="custom-checkbox ml-0">
                              <input
                                className="mr-3"
                                type="checkbox"
                                name="checked"
                                value={checked}
                                checked={checked}
                                onChange={onCheck}
                              />
                              <span className="checkmark" />
                              <span className="fz-15 text-dark-grey">
                                Apply Code
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {checked && (
                        <div className="row">
                          <div className="col-lg-6 mt-4">
                            <Input
                              name="code"
                              value={code}
                              className="form-control input-content"
                              title="Code"
                              validationFunc={onInputValidate}
                              onChangeFunc={(name, value, error) => {
                                onInputChange(name, value, error);
                              }}
                              reqType="text"
                              placeholder="Enter Code"
                              error={errors.code}
                            />
                          </div>
                          <div className="col-lg-6 mt-4">
                            <button
                              onClick={onCouponApplied}
                              type="button"
                              className="cursor-pointer btn btn-primary br-10 fz-16 fw-bold"
                              style={{ padding: "-10px" }}
                            >
                              {loader ? "Applying..." : "Apply"}
                              {loader && (
                                <i
                                  className="ml-2 fa-spin fa-lg fa fa-spinner"
                                  aria-hidden="true"
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="col-lg-12 mt-4 account-action">
              <Link
                to={
                  type === "edit"
                    ? path_url.account_management
                    : `${path_url.personalInformation}/${user_id}`
                }
              >
                <button className="cancel-btn mr-4">Cancel</button>
              </Link>

              {location.state && location.state.type === "edit" ? (
                <button
                  onClick={onEditAccount}
                  type="button"
                  className="cursor-pointer btn btn-primary br-10 fz-16 fw-bold"
                >
                  {loader ? "Updating..." : "Update"}
                  {loader && (
                    <i
                      className="ml-2 fa-spin fa-lg fa fa-spinner"
                      aria-hidden="true"
                    />
                  )}
                </button>
              ) : (
                <button
                  onClick={onAddAccount}
                  type="button"
                  className="cursor-pointer btn btn-primary br-10 fz-16 fw-bold"
                >
                  {loader ? "Saving..." : "Save"}
                  {loader && (
                    <i
                      className="ml-2 fa-spin fa-lg fa fa-spinner"
                      aria-hidden="true"
                    />
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEditAccount;
