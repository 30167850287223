import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input, Select } from "../Common/Input";
import { path_url } from "../Utility/constant";

const initForm = {
  name: "",
  relation: "",
  errors: {
    name: null,
    relation: null,
  },
};

const relationOptions = [
  {
    label: "Wife",
    value: "wife",
  },
  {
    label: "Son",
    value: {},
  },
  {
    label: "Daughter",
    value: {},
  },
  {
    label: "Brother",
    value: {},
  },
  {
    label: "Sister",
    value: {},
  },
  {
    label: "Father",
    value: {},
  },
  {
    label: "Mother",
    value: {},
  },
];

const AddEditFamilyTree = () => {
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const { form } = state;
  const { name, relation, errors } = form;

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <i className="fab fa-pagelines title-img text-blue mr-3 pl-3"></i>
          Add Family Member
          <hr className="mb-0" />
        </h2>
        <div className="common-mt add-edit-modal">
          <div className="row mb-3 mx-auto">
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">Name</label>
              <Input
                name="name"
                value={name}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="First Name"
                isReq={true}
                placeholder="Enter first name"
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                error={errors.name}
              />
            </div>
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Relation
              </label>
              <Select
                name="relation"
                value={relation}
                options={relationOptions}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Relation"
                isReq={true}
                placeholder="Select relation"
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                error={errors.relation}
              />
            </div>
            <div className="col-lg-12 mt-4">
              <Link to={path_url.family_tree}>
                <button className="cancel-btn mr-4">Cancel</button>
              </Link>
              <button
                type="button"
                className="btn btn-primary br-10 fz-16 fw-bold"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEditFamilyTree;
