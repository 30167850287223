import React, { useState, useEffect } from 'react';
import homeIcon from '../Assets/images/assets-blue.svg';
import Edit from '../Assets/images/edit.svg';
import Delete from '../Assets/images/delete.svg';
import PlusIcon from '../Assets/images/plus-circle.svg';
import { path_url } from '../Utility/constant';
import { ts } from '../Utility/ReduxToaster';
import { getEstates, delEstates, 
         getVehicles, delVehicles, 
         getAssets, delAssets } from '../Utility/Services/assets';
import { useNavigate } from 'react-router-dom';

const HomeProperty = (props) => {
  const navigate = useNavigate();
  const [ estateList, setEstateList ] = useState([]);
  const [ vehicleList, setVehicleList ] = useState([]);
  const [ assetList, setAssetList ] = useState([]);

  useEffect(() => {
    fetchEstateData();
    fetchVehicleData();
    fetchAssetData();
  }, []);

  const fetchEstateData = () => {
    getEstates().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setEstateList(res.data);
      }
    });
  };

  const fetchVehicleData = () => {
    getVehicles().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setVehicleList(res.data);
      }
    });
  };

  const fetchAssetData = () => {
    getAssets().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setAssetList(res.data);
      }
    });
  };

  const addEditEstate = (val, type) => {
    if (type === 'Edit') {
      navigate(path_url.edit_estate, {
        state: { estate_id: val.id, data: val }
      });
    } else {
      navigate(path_url.add_estate);
    }
  };

  const addEditVehicle = (val, type) => {
    if (type === 'Edit') {
      navigate(path_url.edit_vehicle, {
        state: { vehicle_id: val.id, data: val }
      });
    } else {
      navigate(path_url.add_vehicle);
    }
  };

  const addEditAsset = (val, type) => {
    if (type === 'Edit') {
      navigate(path_url.edit_assets, {
        state: { asset_id: val.id, data: val }
      });
    } else {
      navigate(path_url.add_assets);
    }
  };

  const onDeleteEstate = (val) => {
    delEstates(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchEstateData();
        ts('Estate deleted successfully');
      }
    });
  };

  const onDeleteVehicle = (val) => {
    delVehicles(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchVehicleData();
        ts('Vehicle deleted successfully');
      }
    });
  };

  const onDeleteAsset = (val) => {
    delAssets(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchAssetData();
        ts('Assets deleted successfully');
      }
    });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section personal-info">
        <h2 className="fw-bold text-black content-title text-center">
          <img className="img-fluid mr-4" src={homeIcon} alt="home-icon" />
            Home, Property & Other assets
          <hr className="mb-0" />
        </h2>
        <div className="prompts">
          <strong>Any possessions you want loved ones know about?</strong> 
          <p>Create a comprehensive list of assets, homes, properties and more.</p>
        </div>

        <div className="row mx-0 common-mt">
          <div className="flex flex-column mb-5 mt-1 w-100">
            <div id="accordion">
              <div className="card mb-4">
                <div
                  className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                  id="heading-1"
                >
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-1"
                      aria-expanded="false"
                      aria-controls="collapse-1"
                    >
                      Real Estate
                    </a>
                  </h5>

                  <button
                    onClick={() => addEditEstate('', 'New')}
                    className="share-btn d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
                  >
                    <img src={PlusIcon} className="img-fluid mr-2 h-20" alt="plusIcon" /> Add{' '}
                  </button>
                </div>
                <div id="collapse-1" className="collapse" data-parent="#accordion" aria-labelledby="heading-1">
                  <div className="card-body">
                    <div className="row mx-auto mb-2">
                      {estateList &&
                        estateList.length > 0 &&
                        estateList.map((val, index) => {
                          return (
                            <div key={index} className="col-lg-12 col-md-12 col-sm-12 mb-2">
                              <div className="property-card">
                                <div className="d-flex justify-content-between">
                                  <p className="text-blue fz-16 fw-semibold mb-2">{val.owner_name}</p>
                                  <div>
                                    <span onClick={() => addEditEstate(val, 'Edit')}>
                                      <img className="mr-3 cursor-pointer" src={Edit} alt="edit-icon" />
                                    </span>{' '}
                                    <span onClick={() => onDeleteEstate(val)}>
                                      <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon"/>
                                    </span>{' '}
                                  </div>
                                </div>
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">Property Name: </span>
                                  {val.property_name}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {' '}
                                  <span className="mr-1 fz-14 fw-bold">Address: </span>
                                  {val.address}{' '}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      {estateList &&
                      estateList.data &&
                      estateList.data.length === 0 && (
                        <div>
                          <h6 className="mb-0">No records found</h6>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-4">
                <div
                  className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                  id="heading-2"
                >
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-2"
                      aria-expanded="false"
                      aria-controls="collapse-2"
                    >
                      Vehicles
                    </a>
                  </h5>

                  <button
                    onClick={() => addEditVehicle('', 'New')}
                    className="share-btn d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
                  >
                    <img src={PlusIcon} className="img-fluid mr-2 h-20" alt='plus-icon' /> Add{' '}
                  </button>
                </div>
                <div id="collapse-2" className="collapse" data-parent="#accordion" aria-labelledby="heading-2">
                  <div className="card-body">
                    <div className="row mx-auto mb-2">
                      {vehicleList &&
                        vehicleList.length > 0 &&
                        vehicleList.map((val, index) => {
                          return (
                            <div key={index} className="col-lg-12 col-md-12 col-sm-12 mb-2">
                              <div className="property-card">
                              <div className="d-flex justify-content-between">
                                <p className="text-blue fz-16 fw-semibold mb-2">{val.vehicle_type}</p>
                                <div>
                                  <span onClick={() => addEditVehicle(val, 'Edit')}>
                                    <img className="mr-3 cursor-pointer" src={Edit} alt="edit-icon"/>
                                  </span>{' '}
                                  <span onClick={() => onDeleteVehicle(val)}>
                                    <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon" />
                                  </span>{' '}
                                </div>
                              </div>
                              <p className="text-dark-grey fz-15 mb-1">
                                <span className="mr-1 fz-14 fw-bold">Registration Number: </span>
                                {val.registration_number}
                              </p>
                              <p className="text-dark-grey fz-15 mb-1">
                                <span className="mr-1 fz-14 fw-bold">Registered State: </span>
                                {val.state}
                              </p>
                              <p className="text-dark-grey fz-15 mb-1">
                                <span className="mr-1 fz-14 fw-bold">Estimated Value: </span>
                                {val.estimated_value}
                              </p>
                            </div>
                            </div>
                          );
                        })}
                      {vehicleList &&
                      vehicleList.data &&
                      vehicleList.data.length === 0 && (
                        <div>
                          <h6 className="mb-0">No records found</h6>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-4">
                <div
                  className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                  id="heading-3"
                >
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-3"
                      aria-expanded="false"
                      aria-controls="collapse-3"
                    >
                      Other assets
                    </a>
                  </h5>

                  <button
                    onClick={() => addEditAsset('', 'New')}
                    className="share-btn d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
                  >
                    <img src={PlusIcon} className="img-fluid mr-2 h-20" alt='plus-icon' /> Add{' '}
                  </button>
                </div>
                <div id="collapse-3" className="collapse" data-parent="#accordion" aria-labelledby="heading-3">
                  <div className="card-body">
                    <div className="row mx-auto mb-2">
                      {assetList &&
                        assetList.length > 0 &&
                        assetList.map((val, index) => {
                          return (
                            <div key={index} className="col-lg-12 col-md-12 col-sm-12 mb-2">
                              <div className="property-card">
                              <div className="d-flex justify-content-between">
                                <p className="text-dark-grey fz-15 mb-1 mr-1">
                                  <span className="mr-1 fz-14 fw-bold">Description: </span>
                                  {val.description}
                                </p>
                                <div>
                                  <span onClick={() => addEditAsset(val, 'Edit')}>
                                    <img className="mr-3 cursor-pointer" src={Edit} alt="edit-icon" />
                                  </span>{' '}
                                  <span onClick={() => onDeleteAsset(val)}>
                                    <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon" />
                                  </span>{' '}
                                </div>
                              </div>
                              <p className="text-dark-grey fz-15 mb-1">
                                <span className="mr-1 fz-14 fw-bold">Location: </span>
                                {val.location}
                              </p>
                              <p className="text-dark-grey fz-15 mb-1">
                                <span className="mr-1 fz-14 fw-bold">Estimated Value: </span>
                                {val.estimated_value}
                              </p>
                            </div>
                            </div>
                          );
                        })}
                      {assetList &&
                      assetList.data &&
                      assetList.data.length === 0 && (
                        <div>
                          <h6 className="mb-0">No records found</h6>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default HomeProperty;
