import { cloneDeep } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { Input, TextArea } from "../Common/Input";
import DatePicker from "react-datepicker";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { getFormDetails } from "../Utility/helper";
import { addEvent, editEvent } from "../Utility/Services/timeline";
import { te, ts } from "../Utility/ReduxToaster";
import TimeIcon from "../Assets/images/timeline-blue.svg";

const today = `${
  new Date().getMonth() + 1 < 10
    ? "0" + (new Date().getMonth() + 1)
    : new Date().getMonth() + 1
    }/${
      new Date().getDate() < 10 ? "0" + new Date().getDate() : new Date().getDate()
    }/${new Date().getFullYear()}`;

const initForm = {
  date: "",
  title: "",
  description: "",
  errors: {
    date: null,
    title: null,
    description: null,
  },
};

const AddEditTimeline = (props) => {
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });
  const [loader, setLoader] = useState(false);
  const tempType = useRef();
  const [type, setType] = useState("");
  const [id, setId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const { form } = state;

  const { title, description, errors } = form;
  const location = useLocation();
  const navigate = useNavigate();

  const typeFun = () => {
    /*if (props.location && props.location.state && props.location.state.type) {
      setType(props.location.state.type);
      setId(props.location.state.id);
    }*/
    setId((location && location.state && location.state.id ) || "");
    setType((location && location.state && location.state.type ) || "Add");
    formUpdate();
  };

  tempType.current = typeFun;

  useEffect(() => {
    tempType.current();
  }, []);

  const formUpdate = () => {

    const item =
      location &&
      location.state &&
      location.state.item;

    let title,
      description,
      date = "";

    if (item) {
      title = item.title;
      description = item.description;

      date = new Date(item.date);

      setSelectedDate(date);
    }

    setState({
      ...state,
      form: {
        ...state.form,
        errors: errors,
        date: date,
        title: title,
        description: description,
      },
    });
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const addHandler = () => {
    const bodyObj = {
      event: {
        date: selectedDate
          ? `${
              selectedDate.getMonth() + 1 < 10
                ? "0" + (selectedDate.getMonth() + 1)
                : selectedDate.getMonth() + 1
            }/${
              selectedDate.getDate() < 10
                ? "0" + selectedDate.getDate()
                : selectedDate.getDate()
            }/${selectedDate.getFullYear()}`
          : today,
        title: title,
        description: description,
      },
    };

    const obj = getFormDetails(
      {
        title,
        description,
        errors: { title: errors.title, description: errors.description },
      },
      onInputValidate
    );

    if (obj) {
      setLoader(true);
      addEvent(bodyObj).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Timeline Added Successfully");
          setLoader(false);
          navigate(path_url.timeline);
        }
      });
    } else {
      return false;
    }
  };

  const editHandler = () => {
    const bodyObj = {
      event: {
        date: selectedDate
          ? `${
              selectedDate.getMonth() + 1 < 10
                ? "0" + (selectedDate.getMonth() + 1)
                : selectedDate.getMonth() + 1
            }/${
              selectedDate.getDate() < 10
                ? "0" + selectedDate.getDate()
                : selectedDate.getDate()
            }/${selectedDate.getFullYear()}`
          : today,
        title: title,
        description: description,
      },
    };

    const obj = getFormDetails(
      {
        title,
        description,
        errors: { title: errors.title, description: errors.description },
      },
      onInputValidate
    );

    if (obj) {
      setLoader(true);
      editEvent(bodyObj, id).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Timeline Edited Successfully");
          setLoader(false);
          navigate(path_url.timeline);
        }
      });
    } else {
      return false;
    }
  };

  return (
    <div className="w-100 content-section account-wrapper user-wrapper">
      <h2 className="fw-bold text-black content-title text-center">
          <img className="img-fluid mr-4" src={TimeIcon} alt="time line" />
          {type === "Edit" ? "Update This Milestone" : "What Events define Your Life?"}
      </h2>

      <div className="common-mt common-mb add-edit-modal upload-wrapper">
      <div className="q-prompts">
         <strong>Most cherished Memories:</strong>
         <p>In your own words, describe the event, what it meant to you and what it means now. How did this event change your life? </p>
      </div>
        <hr className="mb-0" />

        <div className="row mx-auto">
          <div className="col-lg-6">
            <label className="w-100 fw-semibold mb-2 fz-18 text-left">Date</label>
            <DatePicker
              className="form-control input-content text-black placeholder-black border-dark-grey"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="MM/dd/yyyy"
              maxDate={new Date()}
              showMonthDropdown
              showYearDropdown
              yearDropdownItemNumber={100}
              scrollableYearDropdown
            />
          </div>
          <div className="col-lg-6">
            <label className="w-100 fw-semibold mb-2 fz-18 text-left">Title</label>
            <Input
              name="title"
              value={title}
              className="form-control input-content text-black placeholder-black border-dark-grey"
              title="Title"
              isReq={true}
              validationFunc={onInputValidate}
              onChangeFunc={(name, value, error) => {
                onInputChange(name, value, error);
              }}
              placeholder="Enter title"
              error={errors.title}
            />
          </div>
          <div className="col-lg-12">
            <label className="w-100 fw-semibold mb-2 fz-18 text-left">
              Description
            </label>
            <TextArea
              name="description"
              value={description}
              className="form-control input-content text-black placeholder-black border-dark-grey"
              title="Description"
              isReq={true}
              validationFunc={onInputValidate}
              onChangeFunc={(name, value, error) => {
                onInputChange(name, value, error);
              }}
              placeholder="Enter description"
              error={errors.description}
            />
          </div>
          <div className="col-lg-12 mt-4 timeline-action">
            <Link to={path_url.timeline}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>
            {type && type === "Add" && (
              <button
                type="button"
                onClick={addHandler}
                className="cursor-pointer btn btn-primary br-10 fz-16 fw-bold"
              >
                {loader ? "Adding..." : "Add"}
                {loader && (
                  <i
                    className="ml-2 fa-spin fa-lg fa fa-spinner"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}
            {type && type === "Edit" && (
              <button
                type="button"
                onClick={editHandler}
                className="cursor-pointer btn btn-primary br-10 fz-16 fw-bold"
              >
                {loader ? "Updating..." : "Update"}
                {loader && (
                  <i
                    className="ml-2 fa-spin fa-lg fa fa-spinner"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddEditTimeline;
