import React, { useState, useEffect, useRef } from "react";
import { Input, TextArea } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import FuneralIcon from "../Assets/images/funeral-wishes-blue.svg";
import {
  addCremations,
  editCremations,
} from "../Utility/Services/funeralWishes";

const initForm = {
  cemeteryName: "",
  burialSite: "",
  description: "",
  errors: {
    cemeteryName: null,
    burialSite: null,
    description: null,
  },
};

const AddEditCemetery = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [loader, setLoader] = useState(false);
  const [cemeteryId, setCemeteryId] = useState("");
  const tempForm = useRef();

  let { form } = state;
  let location = useLocation();
  let { errors, cemeteryName, burialSite, description } = form;

  const formFun = () => {
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);

  const formUpdate = () => {
    let cemeteryData = location.state && location.state.data;
    if (cemeteryData) {
      form.cemeteryName = cemeteryData.cemetery_name;
      form.burialSite = cemeteryData.burial_site;
      form.description = cemeteryData.description;
      setState({ ...state, form });
      setCemeteryId(location.state.cemetery_id);
    }
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onAddCemetery = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      addCremations(cemeteryName, burialSite, description).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Cremations or plot added successfully");
          setLoader(false);
          navigate(path_url.funeral_wishes);
        }
      });
    } else {
      return false;
    }
  };

  const onEditCemetery = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      editCremations(cemeteryId, cemeteryName, burialSite, description).then(
        (res) => {
          if (res && res.error) {
            te(res.message);
            setLoader(false);
            return;
          }
          if (res && !res.error) {
            ts("Cremations or plot updated successfully");
            setLoader(false);
            navigate(path_url.funeral_wishes);
          }
        }
      );
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={FuneralIcon}
            alt="funeral-icon"
          />
          {location.state && location.state.data && location.state.data.id
            ? "Update Cemetery"
            : "Add Cemetery"}
          <hr className="mb-0" />
        </h2>
        <div className="add-edit-modal">
          <div className="row mb-3 mx-auto">
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Cemetery Name
              </label>
              <Input
                name="cemeteryName"
                value={cemeteryName}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Cemetery Name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter cemetery name"
                error={errors.cemeteryName}
              />
            </div>
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Burial Site
              </label>
              <Input
                name="burialSite"
                value={burialSite}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Burial Site"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter burial site"
                error={errors.burialSite}
              />
            </div>
          </div>

          <div className="row mb-3 mx-auto">
            <div className="col-lg-12">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Description
              </label>
              <TextArea
                name="description"
                value={description}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Description"
                isReq={true}
                rows={4}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter description"
                error={errors.description}
              />
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <Link to={path_url.funeral_wishes}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>
            {location &&
              location.state &&
              location.state.data &&
              location.state.data.id && (
                <button
                  onClick={onEditCemetery}
                  type="button"
                  className="btn btn-primary br-10 fz-16 fw-bold add-btn"
                >
                  {loader ? "Updating..." : "Update"}
                  {loader && (
                    <i
                      className="ml-2 fa-spin fa-lg fa fa-spinner"
                      aria-hidden="true"
                    />
                  )}
                </button>
              )}
            {location && !location.state && (
              <button
                onClick={onAddCemetery}
                type="button"
                className="btn btn-primary br-10 fz-16 fw-bold add-btn"
              >
                {loader ? "Loading..." : "Add"}
                {loader && (
                  <i
                    className="ml-2 fa-spin fa-lg fa fa-spinner"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEditCemetery;
