import { createStore, applyMiddleware } from "redux";
import rootReducer from "./Reducers";
import thunk from "redux-thunk";

const logger = (store) => (next) => (action) => {
  let result;
  console.groupCollapsed("dispatching", action.type);
  result = next(action);
  console.groupEnd();
  return result;
};

const storeFactory = () =>
  createStore(rootReducer, (applyMiddleware(thunk, logger)));
export default storeFactory;
