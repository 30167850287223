import React, { useState, useEffect } from 'react';
import { path_url } from '../Utility/constant';
import Edit from '../Assets/images/edit.svg';
import Delete from '../Assets/images/delete.svg';
import { listInsurances, delInsurance } from '../Utility/Services/insurance';
import { ts } from '../Utility/ReduxToaster';
import { useNavigate } from 'react-router-dom';
import InsuranceIcon from "../Assets/images/insure-blue.svg";

const InsuranceInformation = (props) => {
  const navigate = useNavigate();
  const [ insuracesList, setInsurancesList ] = useState([]);

  const addEditInsurance = (insurance, type) => {
    if (type === 'Edit') {
      navigate(path_url.add_edit_insurance, {
        state: { insurance_id: insurance.id, data: insurance }
      });
    } else {
      navigate(path_url.add_edit_insurance);
    }
  };

  useEffect(() => {
    fetchInsurancesData();
  }, []);

  const fetchInsurancesData = () => {
    listInsurances().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setInsurancesList(res.data);
      }
    });
  };

  const onDeleteInsurance = (val) => {
    delInsurance(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchInsurancesData();
        ts('Insurance deleted successfully');
      }
    });
  };

  return (
    <React.Fragment>

    <div className="w-100 content-section upload-wrapper personal-info">
        <h2 className="fw-bold text-black content-title text-center">
            <img className="img-fluid mr-4" src={InsuranceIcon} alt="Financial Accounts" style={{padding: '7px'}} />
             Insurance Info
          <hr className="mb-0" />
        </h2>
        <button onClick={() => addEditInsurance('', 'New')} className="person-btn">
          Add Insurance
        </button>

        <div className="prompts">
          <strong>Do you have Insurance?</strong> 
          <p>Share details about various insurance policies, including life, health, 
            and property insurance. This ensures that insurance information is 
            accessible for your beneficiaries.</p>
        </div>

        <div className="row mx-0 common-mt">
          <div className="flex flex-column mb-5 mt-1 w-100">
            <div id="accordion">
              {insuracesList && insuracesList.length > 0 && (
                  <>
                    {insuracesList.map((insurace, index) => {
                      return (
                        <React.Fragment>
                          <div className="card mb-4" 
                               key={index} >
                            <div className="card-header custom-py-15 d-flex align-items-center justify-content-between" 
                                 id={`heading-${index}`}>
                              <h5 className="flex-1 mb-0">
                                <a className="collapsed" 
                                   role="button" 
                                   data-toggle="collapse"
                                   href={`#collapse-${index}`} 
                                   aria-expanded="false" 
                                   aria-controls={`collapse-${index}`} >
                                  {/*<span className="mr-1">Company Name: </span>*/}
                                  {insurace.company_name}
                                </a>
                              </h5>
                              <div className="d-flex justify-content-left">
                                <span onClick={() => addEditInsurance(insurace, 'Edit')}>
                                  <img className="mr-3 cursor-pointer" src={Edit} alt="Edit" />
                                </span>{" "}
                                <span onClick={() => onDeleteInsurance(insurace)}>
                                  <img className="mr-3 cursor-pointer" src={Delete} alt="Delete" />
                                </span>
                              </div>
                            </div>
                            <div id={`collapse-${index}`}
                                 className="collapse" 
                                 data-parent="#accordion" 
                                 aria-labelledby={`heading-${index}`}>
                                <div className="card-body">
                                  <div className="property-card min-h-185 mb-3">
                                    <div className="row">
                                      <div className="col-md-12 col-sm-12 col-xs-12">
                                        <p className="text-dark-grey mb-1">
                                          <span className="mr-1 fz-14 fw-bold">Policy Number: </span>
                                          <span className="fz-16">{insurace.policy_number}</span>
                                        </p>
                                        <p className="text-dark-grey mb-1">
                                          <span className="mr-1 fz-14 fw-bold">Beneficiaries: </span>
                                          <span className="fz-16">{insurace.beneficiaries}</span>
                                        </p>
                                        <p className="text-dark-grey mb-1">
                                          <span className="mr-1 fz-14 fw-bold">Value: </span>
                                          <span className="fz-16">{insurace.value}</span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InsuranceInformation;
