import React from 'react';
import ReactTooltip from 'react-tooltip';

const ToolTip = (props) => {
	const { isButton = false, btnStyle, text, icon, imgSrc, imgStyle, className, isButtonImage, btnText, message } = props;
	return (
		<React.Fragment>
			<div data-tip={message ? `${message}`: "This feature is not yet available. But is coming soon."} className={className}>
				{!isButtonImage && icon && <i className={icon} />}
				{!isButtonImage && imgSrc && <img className={imgStyle} src={imgSrc} alt="style" />}
				{!isButtonImage && isButton && <button className={btnStyle}>{text}</button>}
				{text && !isButton ? text : ''}
				{isButtonImage && (
					<button className={btnStyle}>
						<img className={imgStyle} src={imgSrc} alt="style" /> {btnText}
					</button>
				)}
			</div>

			<ReactTooltip place="top" type="dark" effect="solid" />
		</React.Fragment>
	);
};

export default ToolTip;
