import React, { useState, useEffect, useRef } from "react";
import PersonalIcon from "../Assets/images/profile-blue.svg";
import ShareIcon from "../Assets/images/shareIcon.svg";
import ToolTip from "../Common/Input/ToolTip";
import { Input, Select } from "../Common/Input";
import Edit from "../Assets/images/edit.svg";
import { cloneDeep } from "lodash";
import { editPersonalInfo, getAccount } from "../Utility/Services/account";
import DatePicker from "react-datepicker";
import { getFormDetails } from "../Utility/helper";
import { ts } from "../Utility/ReduxToaster";

const initForm = {
  firstName: "",
  middleName: "",
  lastName: "",
  title: "",
  suffix: "",
  email: "",
  phoneNumber: "",
  dob: "",
  errors: {
    firstName: null,
    lastName: null,
    email: null,
  },
};

const PersonalInformation = (props) => {
  const [loader, setLoader] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const tempSearch = useRef();

  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });
  const { form } = state;
  const {
    firstName,
    middleName,
    lastName,
    suffix,
    title,
    email,
    phoneNumber,
    dob,
    errors,
  } = form;
  const [selectedDate, setSelectedDate] = useState(new Date());

  const searchFun = () => {
    let search = new URLSearchParams(window.location.search);
    if (search.get("t")) {
      setLoader(true);
      window.location.search = "";
      setLoader(false);
    }

    fetchAccountData();
  };

  tempSearch.current = searchFun;

  useEffect(() => {
    tempSearch.current();
  }, []);

  const fetchAccountData = () => {
    const userId =
      localStorage.getItem("userID") && localStorage.getItem("userID");

    getAccount(userId).then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && !res.error) {
        const response = res.data.data.attributes;
        if (response) {
          setSelectedDate(
            response.date_of_birth &&
              new Date(response.date_of_birth.split("-").reverse().join("/"))
          );
          setState({
            ...state,
            form: {
              ...form,
              firstName: response.first_name,
              middleName: response.middle_name,
              lastName: response.last_name,
              title: response.title,
              suffix: response.suffix,
              email: response.email,
              phoneNumber: response.phone_no,
              dob: response.date_of_birth,
              errors: errors,
            },
          });
        }
      }
    });
  };

  const saveHandler = () => {
    let bodyObj = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_no: phoneNumber,
      middle_name: middleName,
      title: title,
      suffix: suffix,
      dob:
        selectedDate &&
        `${
          selectedDate.getDate() < 10
            ? "0" + selectedDate.getDate()
            : selectedDate.getDate()
        }/${
          selectedDate.getMonth() + 1 < 10
            ? "0" + (selectedDate.getMonth() + 1)
            : selectedDate.getMonth() + 1
        }/${selectedDate.getFullYear()}`,
    };
    let obj = getFormDetails(
      { firstName, lastName, email, errors },
      onInputValidate
    );
    if (obj) {
      setLoader(true);
      const userId =
        localStorage.getItem("userID") && localStorage.getItem("userID");
      editPersonalInfo(userId, bodyObj).then((res) => {
        if (res && res.error) {
          return;
        }
        if (res && !res.error) {
          fetchAccountData();
          setLoader(false);
          ts("Personal Information edited successfully");
          setEditMode(false);
        }
      });
    } else {
      return false;
    }
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const cancelHandler = () => {
    fetchAccountData();
    setEditMode(false);
  };

  const formatDate = (date) => {
    let arr = date.split("-");
    return `${arr[1]}/${arr[0]}/${arr[2]}`;
  };
  
  return (
    <React.Fragment>
      <div className="w-100 content-section personal-info">
        <h2 className="fw-bold text-black content-title text-center">
          <img className="img-fluid mr-3" src={PersonalIcon} alt="personal-icon"/>
          Personal Information
          <hr className="mb-0" />
        </h2>

        <div className="prompts">
          <strong>Your Personal Information</strong> 
          <p>Information about yourself that is essential for account support.
          This information is carefully protected to ensure the smooth operation of your account. </p>
        </div>


        <div className="row mx-0 ">
          <div className="flex flex-column mb-5 mt-1 w-100">
            <div id="accordion">
              <div className="card mb-4">
                <div
                  className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                  id="heading-1"
                >
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-1"
                      aria-expanded="false"
                      aria-controls="collapse-1"
                    >
                      My Info
                    </a>
                  </h5>
                  <ToolTip
                    btnStyle="share-btn cursor-pointer fz-14 fw-bold text-blue"
                    isButtonImage={true}
                    imgStyle="img-fluid mr-2"
                    imgSrc={ShareIcon}
                    btnText={"Share"}
                  />
                </div>
                <div
                  id="collapse-1"
                  className="collapse show"
                  data-parent="#accordion"
                  aria-labelledby="heading-1"
                >
                  <div className="card-body">
                  <div className="property-card">
                      {editMode && (
                        <div className="row mx-auto">
                          <div className="col-lg-2 mt-3">
                            <label className="w-100 fw-semibold text-left mb-2 fz-18">
                              Title
                            </label>
                            <Select
                              name="title"
                              value={title}
                              className="form-control form-control-sm p-0 text-black placeholder-black border-dark-grey"
                              title="Title"
                              // isReq={true}
                              options={[
                                { value: "Mr.", label: "Mr." },
                                { value: "Miss.", label: "Miss." },
                                { value: "Mrs.", label: "Mrs." },
                                { value: "Dr.", label: "Dr." },
                              ]}
                              onChangeFunc={(name, value, error) => {
                                onInputChange(name, value, error);
                              }}
                              placeholder="Title"
                              isRegister={true}
                            />
                          </div>
                          <div className="col-lg-4 mt-3">
                            <label className="w-100 fw-semibold text-left mb-2 fz-18">
                              First Name
                            </label>
                            <Input
                              name="firstName"
                              value={firstName}
                              className="form-control input-content text-black placeholder-black border-dark-grey"
                              title="First Name"
                              isReq={true}
                              validationFunc={onInputValidate}
                              onChangeFunc={(name, value, error) => {
                                onInputChange(name, value, error);
                              }}
                              placeholder="Enter your first name"
                              error={errors.firstName}
                            />
                          </div>
                          <div className="col-lg-6 mt-3">
                            <label className="w-100 fw-semibold text-left mb-2 fz-18">
                              Middle Name
                            </label>
                            <Input
                              name="middleName"
                              value={middleName}
                              className="form-control input-content text-black placeholder-black border-dark-grey"
                              title="Middle Name"
                              onChangeFunc={(name, value, error) => {
                                onInputChange(name, value, error);
                              }}
                              placeholder="Enter your middle name"
                            />
                          </div>
                          <div className="col-lg-4 mt-3">
                            <label className="w-100 fw-semibold text-left mb-2 fz-18">
                              Last Name
                            </label>
                            <Input
                              name="lastName"
                              value={lastName}
                              className="form-control input-content text-black placeholder-black border-dark-grey"
                              title="Last Name"
                              isReq={true}
                              validationFunc={onInputValidate}
                              onChangeFunc={(name, value, error) => {
                                onInputChange(name, value, error);
                              }}
                              placeholder="Enter your last name"
                              error={errors.lastName}
                            />
                          </div>
                          <div className="col-lg-2 mt-3">
                            <label className="w-100 fw-semibold text-left mb-2 fz-18">
                              Suffix
                            </label>
                            <Select
                              name="suffix"
                              value={suffix}
                              className="form-control form-control-sm p-0 text-black placeholder-black border-dark-grey"
                              title="Suffix"
                              options={[
                                { value: "I", label: "I" },
                                { value: "II", label: "II" },
                                { value: "III", label: "III" },
                                { value: "Sr.", label: "Sr." },
                                { value: "Jr.", label: "Jr." },
                              ]}
                              validationFunc={onInputValidate}
                              onChangeFunc={(name, value, error) => {
                                onInputChange(name, value, error);
                              }}
                              placeholder="Suffix"
                              isRegister={true}
                            />
                          </div>
                          <div className="col-lg-6 mt-3">
                            <label className="w-100 fw-semibold text-left mb-2 fz-18">
                              Email-Id
                            </label>
                            <Input
                              name="email"
                              value={email}
                              className="form-control input-content text-black placeholder-black border-dark-grey"
                              title="Email"
                              isReq={true}
                              validationFunc={onInputValidate}
                              onChangeFunc={(name, value, error) => {
                                onInputChange(name, value, error);
                              }}
                              placeholder="Enter your Email-Id"
                              error={errors.email}
                              disabled
                            />
                          </div>
                          <div className="col-lg-6 mt-3">
                            <label className="w-100 fw-semibold text-left mb-2 fz-18">
                              Contact Number
                            </label>
                            <Input
                              name="phoneNumber"
                              value={phoneNumber}
                              className="form-control input-content text-black placeholder-black border-dark-grey"
                              onChangeFunc={(name, value, error) => {
                                onInputChange(name, value, error);
                              }}
                              placeholder="Enter your Contact Numbers"
                              type="number"
                            />
                          </div>
                          <div className="col-lg-6 mt-3 d-flex flex-column">
                            <label className="w-100 fw-semibold text-left mb-2 fz-18">
                              Date Of Birth
                            </label>
                            <DatePicker
                              className="form-control input-content text-black placeholder-black border-dark-grey"
                              selected={selectedDate}
                              onChange={(date) => setSelectedDate(date)}
                              dateFormat="MM/dd/yyyy"
                              maxDate={new Date()}
                              placeholderText="Select Date of birth"
                              showMonthDropdown
                              showYearDropdown
                              yearDropdownItemNumber={100}
                              scrollableYearDropdown
                            />
                          </div>
                          <div className="col-lg-12 mt-4 personal-action">
                            <button
                              onClick={cancelHandler}
                              type="button"
                              className="cursor-pointer btn btn-primary br-10 fz-14 fw-bold mr-3"
                            >
                              Cancel
                            </button>
                            <button
                              onClick={saveHandler}
                              type="button"
                              className="cursor-pointer btn btn-primary br-10 fz-14 fw-bold"
                            >
                              {loader ? "Saving..." : "Save"}
                              {loader && (
                                <i
                                  className="ml-2 fa-spin fa-lg fa fa-spinner"
                                  aria-hidden="true"
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      )}
                      {!editMode && (
                      <div>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <div className="d-flex align-items-center">
                            <i className="far fa-user mr-2"></i>
                            <p className="text-blue fz-15 fw-semibold mb-0 left-2">
                              {title} {firstName} {middleName} {lastName}{" "}
                              {suffix}
                            </p>
                          </div>
                          <div className="d-flex align-items-center">
                            <span onClick={() => setEditMode(true)}>
                              <img
                                className="mr-3 cursor-pointer"
                                src={Edit}
                                alt="edit-icon"
                              />
                            </span>{" "}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <i className="far fa-envelope mr-2"></i>
                            <p className="text-dark-grey fz-15 mb-0 left-2">
                              {email}
                            </p>
                          </div>
                        </div>
                        {phoneNumber && (
                          <div className="d-flex align-items-center justify-content-between mt-4">
                            <div className="d-flex align-items-center">
                              <i className="fas fa-mobile-alt mr-2 ml-1"></i>
                              <p className="text-dark-grey fz-15 mb-0 left-2">
                                {phoneNumber && phoneNumber}
                              </p>
                            </div>
                          </div>
                        )}
                        {dob && (
                          <div className="d-flex align-items-center justify-content-between mt-4 ">
                            <div className="d-flex align-items-center">
                              <i className="far fa-calendar-check mr-2"></i>
                              <p className="text-dark-grey fz-15 mb-0 left-2">
                                {dob && formatDate(dob)}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  </div>
                </div>
              </div>
              { /*<div className="card mb-4">
                <div className="card-header" id="heading-2">
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-2"
                      aria-expanded="false"
                      aria-controls="collapse-2"
                    >
                      IDs and Documentation
                    </a>
                  </h5>
                </div>
                <div
                  id="collapse-2"
                  className="collapse"
                  data-parent="#accordion"
                  aria-labelledby="heading-2"
                >
                  <div className="card-body">IDs and Documentation Tab</div>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header" id="heading-3">
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-3"
                      aria-expanded="false"
                      aria-controls="collapse-3"
                    >
                      Charity
                    </a>
                  </h5>
                </div>
                <div
                  id="collapse-3"
                  className="collapse"
                  data-parent="#accordion"
                  aria-labelledby="heading-3"
                >
                  <div className="card-body">Charity Info Tab</div>
                </div>
              </div>
              <div className="card mb-4">
                <div className="card-header" id="heading-4">
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-4"
                      aria-expanded="false"
                      aria-controls="collapse-4"
                    >
                      Trustees Info
                    </a>
                  </h5>
                </div>
                <div
                  id="collapse-4"
                  className="collapse"
                  data-parent="#accordion"
                  aria-labelledby="heading-4"
                >
                  <div className="card-body">Trustees Info Tab</div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PersonalInformation;
