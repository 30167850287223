import React, { useState, useEffect, useRef } from "react";
import { Input } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import PaymentIcon from "../Assets/images/payment-blue.svg";
import {
  addAccount,
  addPayment,
  editAccount,
} from "../Utility/Services/account";

const initForm = {
  first_name: "",
  last_name: "",
  street: "",
  city: "",
  states: "",
  zip: "",
  code: "",
  cardNumber: "",
  cardExpiry: "",
  amount: "",
  bodyObj: {},
  type: "",
  accountId: "",
  errors: {
    first_name: null,
    last_name: null,
    street: null,
    city: null,
    states: null,
    zip: null,
    code: null,
    cardNumber: null,
    cardExpiry: null,
    amount: null,
  },
};

const Payment = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });
  const [loader, setLoader] = useState(false);
  const tempFormUpdate = useRef();
  let { form } = state;
  let {
    errors,
    first_name,
    last_name,
    street,
    city,
    states,
    zip,
    code,
    cardExpiry,
    cardNumber,
    amount,
    bodyObj,
    type,
    accountId,
  } = form;
  const user_id = localStorage.getItem("userID");

  const formUpdateFun = () => {
    formUpdate();
  };

  tempFormUpdate.current = formUpdateFun;

  useEffect(() => {
    tempFormUpdate.current();
  }, []);

  const formUpdate = () => {
    let amountRes = "";
    let body = {};
    let type = "";
    let aId = null;

    if (location && location.state && location.state.amount) {
      amountRes = location.state.amount;
    }

    if (
      location &&
      location.state &&
      location.state.bodyObj
    ) {
      body = location.state.bodyObj;
      type = location.state.type;

      if (location.state.accountId) {
        aId = location.state.accountId;
      }
    }

    setState({
      ...state,
      form: {
        ...state.form,
        errors: errors,
        amount: amountRes,
        bodyObj: body,
        type: type,
        accountId: aId,
      },
    });
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onSubmit = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    let cc_number = cardNumber.replaceAll(" ", "");
    let expiration_date = cardExpiry.replace("/", "");
    if (obj) {
      setLoader(true);
      addPayment(
        user_id,
        cc_number,
        expiration_date,
        first_name,
        last_name,
        street,
        city,
        states,
        zip,
        code,
        amount
      ).then((res) => {
        let payment_success =
          res && res.data && res.data.data && res.data.data.attributes.success;

        if (!payment_success) {
          te(res && res.data && res.data.data.attributes.payment_status);
          setLoader(false);
          return;
        }
        if (payment_success) {
          if (type === "add") {
            addAccount(bodyObj).then((resAcc) => {
              if (resAcc && resAcc.error) {
                te(resAcc.message);
                setLoader(false);
                return;
              }
              if (resAcc && !resAcc.error) {
                ts("Payment Successful");
                ts("Account added successfully");
                setLoader(false);
                localStorage.setItem('is_valid_payment', 'true');
                navigate(path_url.payment_success);
                navigate(0);
              }
            });
          } else if (type === "edit") {
            editAccount(accountId, bodyObj).then((resAcc) => {
              if (resAcc && resAcc.error) {
                te(resAcc.message);
                setLoader(false);
                return;
              }
              if (resAcc && !resAcc.error) {
                ts("Payment Successful");
                ts("Account updated successfully");
                setLoader(false);
                localStorage.setItem('is_valid_payment', 'true');
                navigate(path_url.payment_success);
                navigate(0);

              }
            });
          }
        }
      });
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={PaymentIcon}
            alt="payment-icon"
          />
          Payment
          <hr className="mb-0" />{" "}
        </h2>
        <div className="common-mt common-mb account-modal">
          <div className="row mx-auto">
            <div className="col-lg-6 mb-3">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">
                First Name
              </label>
              <Input
                name="first_name"
                value={first_name}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="first_name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter your first name"
                error={errors.first_name}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">
                Last Name
              </label>
              <Input
                name="last_name"
                value={last_name}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="last_name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter your last name"
                error={errors.last_name}
              />
            </div>
            <div className="col-lg-6 mb-2">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">
                Street Address
              </label>
              <Input
                name="street"
                value={street}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Street Address"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter your street address"
                error={errors.street}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">
                City
              </label>
              <Input
                name="city"
                value={city}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="City"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter your City"
                error={errors.city}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">State</label>
              <Input
                name="states"
                value={states}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="States"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Select your state"
                error={errors.states}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">
                Zip Code
              </label>
              <Input
                name="zip"
                value={zip}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="zip"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter Zip Code"
                error={errors.zip}
              />
            </div>
            <div className="col-lg-12 mb-3">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">
                Credit Card Number
              </label>
              <Input
                name="cardNumber"
                value={cardNumber}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Credit Card Number"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                reqType="cardNumber"
                placeholder="XXXX-XXXX-XXXX-XXXX"
                error={errors.cardNumber}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">
                Credit Card Expiration
              </label>
              <Input
                name="cardExpiry"
                value={cardExpiry}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Expiration"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                reqType="expiryDate"
                placeholder="MM/YY"
                error={errors.cardExpiry}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">
                CVV 
              </label>
              <Input
                name="code"
                value={code}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="CVV"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                reqType="number"
                placeholder="Enter CVV"
                error={errors.code}
              />
            </div>
            <div className="col-lg-6 mb-3">
              <label className="w-100 fw-semibold text-left mb-2 fz-18">
                Amount
              </label>
              <Input
                name="amount"
                value={amount}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Amount"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                reqType="number"
                placeholder="Enter your amount"
                error={errors.amount}
                disabled
              />
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <Link to={`${path_url.account_management}`}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>
            <button
              onClick={onSubmit}
              type="button"
              className="cursor-pointer common-btn"
            >
              {loader ? "Paying..." : "Pay Now"}
              {loader && (
                <i
                  className="ml-2 fa-spin fa-lg fa fa-spinner"
                  aria-hidden="true"
                />
              )}{" "}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Payment;
