import React, { useState } from "react";
import { Input, Select } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails, syncLogin } from "../Utility/helper";
import { Link } from "react-router-dom";
import { path_url } from "../Utility/constant";
import Lock from "../Assets/images/lock.svg";
import Email from "../Assets/images/email.svg";
import Unlock from "../Assets/images/unlock.svg";
import Person from "../Assets/images/person.svg";
import { te, ts } from "../Utility/ReduxToaster";
import leftBanner from "../Assets/images/login-bg-new.svg";
//import Logo from "../Assets/images/logo1.svg";
//import Logo from "../Assets/images/EnduringWishesLogoV2_white.png";
import Logo from "../Assets/images/EnduringWishesLogoV2.png";
import CorporateIcon from "../Assets/images/corporate_icon.svg";
import { createAdminUser } from "../Utility/Services/authenticate";

const initForm = {
  //title: "",
  firstName: "",
  //middleName: "",
  lastName: "",
  //suffix: "",
  email: "",
  password: "",
  //confirmPassword: "",
  accountName: "",
  accepted: false,
  errors: {
    firstName: null,
    lastName: null,
    email: null,
    password: null,
    //confirmPassword: null,
    accountName: null,
  },
};

const RegisterAdmin = (props) => {
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });
  const [loader, setLoader] = useState(false);

  let { form } = state;
  let {
    errors,
    //title,
    firstName,
    //middleName,
    lastName,
    //suffix,
    email,
    password,
    //confirmPassword,
    accountName,
    accepted,
  } = form;

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    /*if (name === "confirmPassword") {
      if (password && confirmPassword && password !== confirmPassword) {
        error = "Confirm password should be same as password";
      }
    }*/
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onSubmit = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      createAdminUser(
        firstName,
        lastName,
        email,
        password,
        accepted,
        /*middleName,
        suffix,
        title,*/
        accountName
      ).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("User Registered successfully");
          setLoader(false);
          syncLogin(res.data);
          window.gtag_report_conversion();
          // loging user_admin
          // redirect to 
          //props.history.push(path_url.corporateDashboard);
          window.location.href = `${window.location.protocol}//${window.location.host}${path_url.corporateDashboard}?t=${res.data.meta.token}`;
        };
      });
    } else {
      return false;
    }
  };
  return (
    <React.Fragment>
      <div className=""
           style={{
                    backgroundColor: "#E3E3E3"
                  }}>
        <div className="row">
          <section className="col-lg-6 col-md-6 col-sm-12 login-right-image"
                   style={{
                            margin: "auto",
                            backgroundColor: "#FFFFFF"
                          }}>
            <div className="container-fluid px-0">
              <div className="login-form-part">
                <div className="login-form-part">
                  <div className="d-flex align-items-center h-100 w-100">
                    <div className="w-100 card login-form-main border-0 bg-transparent">
                      <div className="card-body px-0 mb-auto text-center">
                        <h3 className="fw-bold mb-3 h-responsive img-content-text">
                          <div className="text-light-blue">Make Your</div>
                          <img
                            src={Logo}
                            className="img-fluid img-logo"
                            alt="logo"
                            style={{height: "68px"}}
                          />
                        </h3>
                        
                        <p className="fw-bold mb-0 h-responsive img-content">
                          We're glad to see you!
                        </p>
                        <h3 className="fw-bold mb-3 mt-0 h-responsive">
                          Create a company account
                        </h3>
                        <div className="card-text register-form">
                          <div className="row">
                            <div className="form-group position-relative mb-4 col-12">
                              <span className="position-absolute custom-icon mr-4">
                                <img alt="" 
                                     src={CorporateIcon}
                                     className="img-fluid" />
                              </span>
                              <Input
                                name="accountName"
                                value={accountName}
                                className="form-control form-control-sm text-black placeholder-black border-black"
                                title="Account Name"
                                isReq={true}
                                validationFunc={onInputValidate}
                                onChangeFunc={(name, value, error) => {
                                  onInputChange(name, value, error);
                                }}
                                placeholder="Enter your account name"
                                error={errors.accountName}
                              />
                            </div>
                          </div>
                          <div className="row">
                            {/*<div className="form-group position-relative mb-4 col-lg-4 col-md-12 col-sm-12">
                              <Select
                                name="title"
                                value={title}
                                className="form-control form-control-sm p-0 text-black placeholder-black border-black"
                                title="Title"
                                options={[
                                  { value: "Mr.", label: "Mr." },
                                  { value: "Miss.", label: "Miss." },
                                  { value: "Mrs.", label: "Mrs." },
                                  { value: "Dr.", label: "Dr." },
                                ]}
                                validationFunc={onInputValidate}
                                onChangeFunc={(name, value, error) => {
                                  onInputChange(name, value, error);
                                }}
                                placeholder="Select Title"
                                isRegister={true}
                              />{" "}
                            </div>*/}
                            <div className="form-group position-relative mb-4 col-12">
                              <span className="position-absolute custom-icon mr-4">
                                <img alt="" 
                                     src={Person} 
                                     className="img-fluid" />
                              </span>
                              <Input
                                name="firstName"
                                value={firstName}
                                className="form-control form-control-sm text-black placeholder-black border-black"
                                title="First Name"
                                isReq={true}
                                validationFunc={onInputValidate}
                                onChangeFunc={(name, value, error) => {
                                  onInputChange(name, value, error);
                                }}
                                placeholder="Enter your first name"
                                error={errors.firstName}
                              />
                            </div>
                            {/*<div className="form-group position-relative mb-4 col-lg-4 col-md-12 col-sm-12">
                                <span className="position-absolute custom-icon">
                                  <img alt="" 
                                       src={Person} 
                                       className="img-fluid" />
                                </span>
                                <Input
                                  name="middleName"
                                  value={middleName}
                                  className="form-control form-control-sm text-black placeholder-black border-black"
                                  title="Middle Name"
                                  validationFunc={onInputValidate}
                                  onChangeFunc={(name, value, error) => {
                                    onInputChange(name, value, error);
                                  }}
                                  placeholder="Enter your middle name"
                                />
                            </div>*/}
                          </div>
                          <div className="row">
                            <div className="form-group position-relative mb-4 col-12">
                              {" "}
                              <span className="position-absolute custom-icon mr-4">
                                <img alt="" 
                                     src={Person} 
                                     className="img-fluid" />
                              </span>
                              <Input
                                name="lastName"
                                value={lastName}
                                className="form-control form-control-sm text-black placeholder-black border-black"
                                title="Last Name"
                                isReq={true}
                                validationFunc={onInputValidate}
                                onChangeFunc={(name, value, error) => {
                                  onInputChange(name, value, error);
                                }}
                                placeholder="Enter your last name"
                                error={errors.lastName}
                              />
                            </div>
                            {/*<div className="form-group position-relative mb-4 col-lg-4 col-md-12 col-sm-12">
                              <Select
                                name="suffix"
                                value={suffix}
                                className="form-control form-control-sm p-0 text-black placeholder-black border-black"
                                title="Suffix"
                                options={[
                                  { value: "I", label: "I" },
                                  { value: "II", label: "II" },
                                  { value: "III", label: "III" },
                                  { value: "Sr.", label: "Sr." },
                                  { value: "Jr.", label: "Jr." },
                                ]}
                                validationFunc={onInputValidate}
                                onChangeFunc={(name, value, error) => {
                                  onInputChange(name, value, error);
                                }}
                                placeholder="Select Suffix"
                                isRegister={true}
                              />{" "}
                            </div>*/}
                          </div>
                          <div className="row">
                            {/*<div className="col-12">*/}
                              <div className="form-group position-relative mb-4 col-12">
                                {" "}
                                <span className="position-absolute custom-icon mr-4">
                                  <img alt="" 
                                       src={Email} 
                                       className="img-fluid" />
                                </span>
                                <Input
                                  name="email"
                                  value={email}
                                  className="form-control form-control-sm text-black placeholder-black border-black"
                                  title="Email"
                                  isReq={true}
                                  reqType="email"
                                  validationFunc={onInputValidate}
                                  onChangeFunc={(name, value, error) => {
                                    onInputChange(name, value, error);
                                  }}
                                  placeholder="Enter your email"
                                  error={errors.email}
                                  disabled={accepted}
                                />
                              </div>
                            {/*</div>
                            <div className="col-12">*/}
                              <div className="form-group position-relative mb-4 col-12">
                                {" "}
                                <span className="position-absolute custom-icon mr-4">
                                  <img alt="" 
                                       src={Lock} 
                                       className="img-fluid" />
                                </span>
                                <Input
                                  name="password"
                                  className="form-control form-control-sm text-black placeholder-black border-black"
                                  value={password}
                                  validationFunc={onInputValidate}
                                  onChangeFunc={(name, value, error) => {
                                    onInputChange(name, value, error);
                                  }}
                                  isReq={true}
                                  title="Password"
                                  placeholder="Enter password"
                                  error={errors.password}
                                  type="password"
                                />
                              </div>
                            {/*</div>*/}
                            {/*<div className="col-lg-6 col-md-12 col-sm-12">
                              <div className="form-group position-relative mb-5">
                                {" "}
                                <span className="position-absolute custom-icon">
                                  <img alt="" 
                                       src={Unlock} 
                                       className="img-fluid" />
                                </span>
                                <Input
                                  name="confirmPassword"
                                  value={confirmPassword}
                                  className="form-control form-control-sm text-black placeholder-black border-black"
                                  title="Confirm Password"
                                  isReq={true}
                                  type="password"
                                  validationFunc={onInputValidate}
                                  onChangeFunc={(name, value, error) => {
                                    onInputChange(name, value, error);
                                  }}
                                  placeholder="Enter confirm password"
                                  error={errors.confirmPassword}
                                />
                              </div>
                            </div>*/}
                          </div>

                          <button
                            onClick={onSubmit}
                            className="btn btn-primary fz-20 px-5 fw-bold "
                          >
                            {loader ? "Loading..." : "Sign Up"}
                            {loader && (
                              <i
                                className="ml-2 fa-spin fa-lg fa fa-spinner"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                          <div className="register fz-14 mt-5 mb-5 pb-5">
                            {" "}
                            <span className="text-dark-grey">
                              Already have an account? &nbsp;
                            </span>
                            <Link
                              to={path_url.login}
                              className="fz-15 fw-bold text-light-blue cursor-pointer text-decoration-underline"
                            >
                              Login here
                            </Link>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};
export default RegisterAdmin;
