import React from 'react';
import DocViewer, {DocViewerRenderers} from '@cyntler/react-doc-viewer';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';

const FileViewerModal = ({open, setIsOpen, fileType, fileUrl}) => {
  const SupportedFileFormats =  [
    {
      format: 'application/pdf',
      formatCode: 'pdf' 
    },
    {
      format: 'application/msword',
      formatCode: 'doc' 
    },
    {
      format: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      formatCode: 'docx' 
    },
    {
      format: 'application/vnd.ms-powerpoint',
      formatCode: 'ppt' 
    },
    {
      format: 'applicatiapplication/vnd.openxmlformats-officedocument.presentationml.presentation',
      formatCode: 'pptx' 
    },
    {
      format: 'text/plain',
      formatCode: 'txt' 
    },
    {
      format: 'application/vnd.ms-excel',
      formatCode: 'xls' 
    },
    {
      format: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      formatCode: 'xlsx' 
    }
  ]

  const SupportedImageFormats = {
    PNG: 'image/png',
    JPG: 'image/jpg',
    JPEG: 'image/jpeg',
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const getFiletype = () => {
    const currentFormat = SupportedFileFormats.filter(fileFormat => fileFormat.format === fileType);
    return currentFormat[0]?.formatCode;
  }

  return (
    <Modal open={open} onClose={closeModal} center>
      {(fileType === SupportedImageFormats.PNG || fileType === SupportedImageFormats.JPEG || fileType === SupportedImageFormats.JPG) &&
        <img src={fileUrl} alt='pet'/>}
      {getFiletype() && (
        <DocViewer style={{ width: 500, height: 500 }}
          documents={[{uri: fileUrl, fileType: getFiletype()}]}
          pluginRenderers={DocViewerRenderers} 
          config={{
            header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true
            }}} 
        />)
      }
    </Modal>
  )
}

export default FileViewerModal;