import { post, get, put } from "../httpInterceptor";
import { toFormData } from "../helper";

export const getAccount = (user_id) => {
  return get(`/users/${user_id}`, true).then((res) => {
    return res;
  });
};

export const addAccount = (bodyObj) => {
  return post(`/accounts`, toFormData(bodyObj), true, false).then((res) => {
    return res;
  });
};
export const editAccount = (acc_id, bodyObj) => {
  return put(`/accounts/${acc_id}`, toFormData(bodyObj), true, false).then((res) => {
    return res;
  });
};

export const addPayment = (
  user_id,
  cc_number,
  expiration_date,
  first_name,
  last_name,
  street,
  city,
  state,
  zip,
  code,
  amount
) => {
  return post(
    `/users/${user_id}/payment?[payment][cc_number]=${cc_number}&[payment][expire]=${expiration_date}&[payment][first_name]=${first_name}&[payment][last_name]=${last_name}&[payment][street]=${street}&[payment][city]=${city}&[payment][state]=${state}&[payment][zip]=${zip}&[payment][code]=${code}&[payment][amount]=${amount}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const getFeatures = () => {
  return get("/subscription_plans/get_allowed_features", true).then((res) => {
    return res;
  });
};

export const editPersonalInfo = (userId, bodyObj) => {
  return put(`/users/${userId}`, bodyObj, true, false).then((res) => {
    return res;
  });
};

export const getSubscriptionPlanDetails = (plan) => {
  return get(
    `/subscription_plans/get_subscription_plan?option=${plan}`,
    true
  ).then((res) => {
    return res;
  });
};

export const getCodeDetails = (plan,code) => {
  return get(
    `/subscription_plans/get_subscription_plan?option=${plan}&&code=${code}`,
    true
  ).then((res) => {
    return res;
  });
};

export const getSubscriptionPlans = (accountType) => {
  return get(
    `/subscription_plans/get_subscription_plans_options?account_type=${accountType}`,
    true
  ).then((res) => {
    return res;
  });
};

export const getCorporateUsers = (userId) => {
  return get(
      `/users/${userId}/corporate_users`, 
      true
    ).then((res) => {
    return res;
  });
};

