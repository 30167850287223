import React from 'react';
import PaymentBg from '../Assets/images/payment-bg.svg';
import { Link } from 'react-router-dom';
import { path_url } from '../Utility/constant';
import SuccessLogo from '../Assets/images/success.svg';
import PaymentIcon from '../Assets/images/payment-blue.svg';

const PaymentSuccess = (props) => {
  const user_id = localStorage.getItem('userID');

  return (
    <React.Fragment>
      <div className="w-100 content-section">
        <h2 className="fw-bold text-black content-title">
          <img className="img-fluid mr-3 title-img" src={PaymentIcon} alt="payment-icon" />Payment
          <hr className="mb-0" />{' '}
        </h2>
        <div className="row common-mt common-mb">
          <div className="col-md-7">
            <h4 className="fw-bold text-black mb-4 pb-1">
              <img className="icon-size img-fluid mr-3" src={SuccessLogo} alt="success-logo"/>Payment Successful...!!!
            </h4>
            <p className="text-black-2 mb-4 pb-1">
              Your payment was successful. You can now continue with EnduringWishes.
            </p>
            <Link to={`${path_url.personalInformation}/${user_id}`}>
              <button className="common-btn w-250 br-5">Go to Personal Information</button>
            </Link>
          </div>
          <div className="col-md-5 text-center right-0">
            <img className="img-fluid" src={PaymentBg} alt="paymentBg"/>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentSuccess;
