import React, { useEffect } from 'react';

const Footer = (props) => {
  useEffect(() => {
    window.fbq("init", "571998657221340");
    window.fbq("track", "PageView");

    window.gtag("js", new Date());
    window.gtag("config", "UA - 206903890 - 3");
  });

  return (
    <React.Fragment>
      <div className={`${props.color} d-flex justify-content-between align-items-center footer-wrapper`}>
        <div className="w-100 d-block d-md-flex align-items-center justify-content-between">
          <div
            className={`d-flex flex-column flex-md-row justify-content-between align-items-center fw-semibold ${props.textColor} fz-15 w-100`}
          >
            <div className="copyright-text">
              <i className="fas fa-copyright mr-2" />
              <span>2024 Copyright EnduringWishes, Inc. | All Rights Reserved</span>
            </div>
            <div>
              <i className="fa-lg fa fa-facebook mr-4" aria-hidden="true" />
              <i className="fa-lg fa fa-instagram mr-4" aria-hidden="true" />
              <i className="fa-lg fa fa-twitter mr-2" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
