import {get, post, put, del} from '../httpInterceptor'

export const getMessage = (id) => {
  return get(`/messages/${id}`, {}, true, false).then((res) => {
    return res
  })
}

export const listMessages = () => {
	return get(`/messages`, true).then((res) => {
		return res
	})
}

export const createMessage = (to, body,email,phoneNumber) => {
  return post(
    `/messages?[message][to]=${to}&[message][body]=${body}&[message][email]=${email}&[message][phone_number]=${phoneNumber}`,
    {},
    true,
    false
  ).then((res) => {
    return res
  })
}

export const editMessage = (message_id, to, body,email,phoneNumber) => {
  return put(
    `/messages/${message_id}?[message][to]=${to}&[message][body]=${body}&[message][email]=${email}&[message][phone_number]=${phoneNumber}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const delMessage = (id) => {
  return del(`/messages/${id}`, {}, true, false).then((res) => {
    return res
  })
}