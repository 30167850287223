import React, { useState, useEffect, useRef } from "react";
import { Input, TextArea } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import homeIcon from "../Assets/images/home-property-blue.svg";
import { editEstates } from "../Utility/Services/assets";

const initForm = {
  ownerName: "",
  propertyName: "",
  address: "",
  errors: {
    ownerName: null,
    propertyName: null,
    address: null,
  },
};

const EditEstate = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [loader, setLoader] = useState(false);
  const [estateId, setEstateId] = useState("");
  const tempForm = useRef();

  let { form } = state;
  let location = useLocation();
  let { errors, ownerName, propertyName, address } = form;

  const formFun = () => {
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);

  const formUpdate = () => {
    let estateData = location.state.data;
    form.ownerName = estateData.owner_name;
    form.propertyName = estateData.property_name;
    form.address = estateData.address;
    setState({ ...state, form });
    setEstateId(location.state.estate_id);
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onEditEstate = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      editEstates(estateId, ownerName, propertyName, address).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Estate updated successfully");
          setLoader(false);
          navigate(path_url.home_property);
        }
      });
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={homeIcon}
            alt="home-icon"
          />
          Update Real Estate
          <hr className="mb-0" />
        </h2>
        <div className="common-mt add-edit-modal">
          <React.Fragment>
            <div className="row mb-2 mx-auto">
              <div className="col-lg-6">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">
                  Owner Name
                </label>
                <Input
                  name="ownerName"
                  value={ownerName}
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  title="Owner Name"
                  isReq={true}
                  validationFunc={(name, error) => {
                    onInputValidate(name, error);
                  }}
                  onChangeFunc={(name, value, error) => {
                    onInputChange(name, value, error);
                  }}
                  placeholder="Enter owner name"
                  error={errors.ownerName}
                />
              </div>
              <div className="col-lg-6">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">
                  Property Name
                </label>
                <Input
                  name="propertyName"
                  value={propertyName}
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  title="Property Name"
                  isReq={true}
                  validationFunc={(name, error) => {
                    onInputValidate(name, error);
                  }}
                  onChangeFunc={(name, value, error) => {
                    onInputChange(name, value, error);
                  }}
                  placeholder="Enter property name"
                  error={errors.propertyName}
                />
              </div>
            </div>
            <div className="row mb-3 mx-auto">
              <div className="col-lg-12">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">
                  Address
                </label>
                <TextArea
                  name="address"
                  value={address}
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  title="Address"
                  isReq={true}
                  rows={4}
                  validationFunc={(name, error) => {
                    onInputValidate(name, error);
                  }}
                  onChangeFunc={(name, value, error) => {
                    onInputChange(name, value, error);
                  }}
                  placeholder="Enter address"
                  error={errors.address}
                />
              </div>
            </div>
          </React.Fragment>
          <div className="col-lg-12 mt-4 home-action">
            <Link to={path_url.home_property}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>

            <button
              onClick={onEditEstate}
              type="button"
              className="btn btn-primary br-10 fz-16 fw-bold"
            >
              {loader ? "Loading..." : "Update"}
              {loader && (
                <i
                  className="ml-2 fa-spin fa-lg fa fa-spinner"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditEstate;
