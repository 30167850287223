import { del, get, post, put } from "../httpInterceptor";

export const getMyStories = () => {
  return get(`/my_stories/user_stories`, {}, true, false).then((res) => {
    return res;
  });
};

export const addMyStory = (bodyObj) => {
  return post(`/my_stories`, bodyObj, true, false).then((res) => {
    return res;
  });
};

export const deleteMyStory = (userId) => {
  return del(`/my_stories/${userId}`, {}, true, false).then((res) => {
    return res;
  });
};

export const updateMyStory = (userId, bodyObj) => {
  return put(`/my_stories/${userId}`, bodyObj, true, false).then((res) => {
    return res;
  });
};
