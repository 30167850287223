import { get, post, put, del } from '../httpInterceptor';

export const getCremations = () => {
  return get(`/plot_burial_cremations`, {}, true, false).then((res) => {
    return res;
  });
};

export const addCremations = (cemetery_name, burial_site, description) => {
  return post(
    `/plot_burial_cremations?[plot_burial_cremation][cemetery_name]=${cemetery_name}&[plot_burial_cremation][burial_site]=${burial_site}&[plot_burial_cremation][description]=${description}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const editCremations = (cremations_id, cemetery_name, burial_site, description) => {
  return put(
    `/plot_burial_cremations/${cremations_id}?[plot_burial_cremation][cemetery_name]=${cemetery_name}&[plot_burial_cremation][burial_site]=${burial_site}&[plot_burial_cremation][description]=${description}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const delCremations = (id) => {
  return del(`/plot_burial_cremations/${id}`, {}, true, false).then((res) => {
    return res;
  });
};

export const getReadings = () => {
  return get(`/readings`, {}, true, false).then((res) => {
    return res;
  });
};

export const addReadings = (book, verse, content, reflection) => {
  return post(
    `/readings?[reading][book]=${book}&[reading][verse]=${verse}&[reading][content]=${content}&[reading][reflection]=${reflection}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const editReadings = (reading_id, book, verse, content, reflection) => {
  return put(
    `/readings/${reading_id}?[reading][book]=${book}&[reading][verse]=${verse}&[reading][content]=${content}&[reading][reflection]=${reflection}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const delReadings = (id) => {
  return del(`/readings/${id}`, {}, true, false).then((res) => {
    return res;
  });
};

export const getSongs = () => {
  return get(`/music_and_songs`, {}, true, false).then((res) => {
    return res;
  });
};

export const addSongs = (title, link) => {
  return post(
    `/music_and_songs?[music_and_song][title]=${title}&[music_and_song][link]=${link}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const editSongs = (song_id, title, link) => {
  return put(
    `/music_and_songs/${song_id}?[music_and_song][title]=${title}&[music_and_song][link]=${link}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const delSongs = (id) => {
  return del(`/music_and_songs/${id}`, {}, true, false).then((res) => {
    return res;
  });
};

export const getInstructions = () => {
  return get(`/special_instructions`, {}, true, false).then((res) => {
    return res;
  });
};

export const addInstructions = (title, assignee, description) => {
  return post(
    `/special_instructions?[special_instruction][title]=${title}&[special_instruction][description]=${description}&[special_instruction][assignee]=${assignee}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const editInstructions = (instruction_id, title, assignee, description) => {
  return put(
    `/special_instructions/${instruction_id}?[special_instruction][title]=${title}&[special_instruction][description]=${description}&[special_instruction][assignee]=${assignee}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const delInstructions = (id) => {
  return del(`/special_instructions/${id}`, {}, true, false).then((res) => {
    return res;
  });
};
