import React from "react";
import PropTypes from "prop-types";

const changeHandler = (e, onChangeFunc, props) => {
  let error = "";
  props.validationFunc(props.name, error);
  Object.keys(e.target.files).map((res, index) => {
    let file = e.target.files[index];
    if (!file) {
      return false;
    }
    let response = validationHandlerImage(e, props, file);
    if (response === true) {
      onChangeFunc(props.name, file, null);
    }
  });
};

const validationHandlerImage = (e, props, file) => {
  if (!props.validationFunc) return;
  let type = file.type.split("/");
  console.log(type[1].trim());
  if (
    !props.fileType
      .trim()
      .toLowerCase()
      .includes(type[1].trim().toLowerCase()) ||
    parseFloat(file.size / 1000000) > parseInt(props.size / 1024)
  ) {
    let error = " ";
    if (
      !props.fileType
        .trim()
        .toLowerCase()
        .includes(type[1].trim().toLowerCase())
    ) {
      error = `This file type is not supported (${file.name})`;
    }
    props.validationFunc(props.name, error);
    return false;
  } else {
    return true;
  }
};

const File = (props) => {
  const reqErrorMsg = `Please Select ${props.title}`;
  const {
    className,
    placeholder,
    value,
    name,
    error,
    onKeyUpFunc,
    loading,
    onChangeFunc,
    prefix,
    id,
    fileType,
    disabled,
    ismultiple,
    isFile,
  } = props;
  const inputProps = {
    type: "file",
    className: className,
    value: value,
    id: id,
  };
  if (placeholder) inputProps.placeholder = placeholder;
  if (name) inputProps.name = name;
  if (onKeyUpFunc) inputProps.onKeyUp = onKeyUpFunc;
  return (
    <div
      className={`form-group ${loading !== null ? "input-loading" : ""} ${
        prefix && "input-prefix"
      } error-msg image-upload`}
    >
      <div className="avatar-upload">
        {props.iconUpload === false ? (
          <div>
            <div className="avatar-btn">
              <input
                type="file"
                id={props.id}
                onChange={(e) => changeHandler(e, onChangeFunc, props)}
                accept={fileType}
                disabled={disabled}
                multiple={ismultiple}
                {...(isFile ? inputProps : {})}                                
              />
              <label htmlFor={props.id} />
            </div>
          </div>
        ) : null}
      </div>

      {error && (
        <span className="reqEstric mb-0">
          <i className="fas fa-exclamation-circle" />{" "}
          {error === true ? reqErrorMsg : error}
        </span>
      )}
    </div>
  );
};
File.defaultProps = {
  iconUpload: false,
  type: "text",
  className: "form-control",
  isReq: null,
  reqType: "",
  value: "",
  onChangeFunc: () => {},
  onKeyUpFunc: () => {},
  loading: null,
  disabled: false,
  size: "1000001",
  ismultiple: true,
};
File.propTypes = {
  title: PropTypes.string,
  isReq: PropTypes.bool,
  reqType: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.any,
  error: PropTypes.any,
  onChangeFunc: PropTypes.func,
  validationFunc: PropTypes.func,
  minLength: PropTypes.number,
  size: PropTypes.number,
  maxLength: PropTypes.number,
  onKeyUpFunc: PropTypes.func,
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  prefix: PropTypes.string,
  iconUpload: PropTypes.bool,
  disabled: PropTypes.bool,
  ismultiple: PropTypes.bool,
};
export default React.memo(File);
