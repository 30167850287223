import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import DigitalInformation from "../Component/DigitalInformation";
import Login from "../Component/Login";
import PersonalInformation from "../Component/PersonalInformation";
import Register from "../Component/Register";
import { path_url } from "../Utility/constant";
import NotFound from "../Component/NotFound";
import Payment from "../Component/Payment";
import PaymentSuccess from "../Component/PaymentSuccess";
import Profile from "../Component/Profile";
import UserManagement from "../Component/UserManagement";
import AddEditUsers from "../Component/AddEditUsers";
import AddEditPerson from "../Component/AddEditPerson";
import Legals from "../Component/Legals";
import HomeProperty from "../Component/HomeProperty";

import Financials from "../Component/Financials";
import Recipes from "../Component/Recipes";

import FuneralWishes from "../Component/FuneralWishes";
import MessageToLovedOnes from "../Component/MessageToLovedOnes";
import Deputies from "../Component/Deputies";
import PrivacySettings from "../Component/PrivacySettings";
import AddEditDeputies from "../Component/AddEditDeputies";
import AddEditMessages from "../Component/AddEditMessages";
import InsuranceInformation from "../Component/InsuranceInformation";

import AddEditFinancial from "../Component/AddEditFinancial";
import AddEditRecipe from "../Component/AddEditRecipe";

import AddEditInsurance from "../Component/AddEditInsurance";
import Images from "../Component/Images";
import AddEstate from "../Component/AddEstate";
import EditEstate from "../Component/EditEstate";
import AddVehicle from "../Component/AddVehicle";
import EditVehicle from "../Component/EditVehicle";
import AddAssets from "../Component/AddAssets";
import EditAssets from "../Component/EditAssets";
import AddEditCemetery from "../Component/AddEditCemetery";
import AddEditReadings from "../Component/AddEditReadings";
import AddEditSongs from "../Component/AddEditSongs";
import AddEditInstructions from "../Component/AddEditInstructions";
import AccountManagement from "../Component/AccountManagement";
import AddEditAccount from "../Component/AddEditAccount";
import AddEditLegals from "../Component/AddEditLegals";
import Timeline from "../Component/Timeline";
import FamilyTree from "../Component/FamilyTree";
import AddEditFamilyTree from "../Component/AddEditFamilyTree";
import MyStory from "../Component/MyStory";
import AddEditTimeline from "../Component/AddEditTimeline";
import DeputyView from "../Component/DeputyView";
import Pets from "../Component/Pets";
import AddEditPet from "../Component/AddEditPet";
import PetView from "../Component/PetView";
import RegisterAdmin from "../Component/RegisterAdmin";
import DashboardAdmin from "../Component/DashboardAdmin";
import Nominators from "../Component/Nominators";
import RequireAuth from "./requireAuth";
import MainLayout from "../layouts/MainLayout/mainLayout";
import AuthLayout from "../layouts/AuthLayout/authLayout";

const AppRoutes = (props) => {
  const userID = localStorage.getItem("userID");
  // const [isPublic, setPublic] = useState(true);
  // const tempPublicUserId = useRef()

  // const setPublicUserId = () => {
  //   if (window.location.pathname.includes(path_url.login) && !userID) {
  //     setPublic(true);
  //   } else if (window.location.pathname.includes(path_url.signUp && !userID)) {
  //     setPublic(true);
  //   } else if (
  //     window.location.pathname.endsWith(path_url.landing_page && !userID)
  //   ) {
  //     setPublic(true);
  //   } else if (
  //     window.location.pathname.includes(path_url.forgot_password && !userID)
  //   ) {
  //     setPublic(true);
  //   } else {
  //     if (!userID) {
  //       setPublic(true);
  //     } else {
  //       setPublic(false);
  //     }
  //   }
  // };
  
  // tempPublicUserId.current = setPublicUserId

  // useEffect(() => {
  //   tempPublicUserId.current()
  // }, []);

  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={path_url.landing_page} element={protectedLayout}>
          <Route
            path="/"
            element={<Navigate to={`${path_url.personalInformation}/${userID}`} replace />}
          />
          <Route path={path_url.forgot_password} />
          <Route
            exact
            path={`${path_url.personalInformation}/:userId`}
            element={<PersonalInformation/>}
          />
          <Route exact path={path_url.persons} element={<AddEditPerson/>} />
          <Route
            exact
            path={path_url.digitalInformation}
            element={<DigitalInformation/>}
          />
          <Route
            exact
            path={path_url.account_management}
            element={<AccountManagement/>}
          />
          <Route
            exact
            path={path_url.add_edit_account}
            element={<AddEditAccount/>}
          />
          <Route
            exact
            path={path_url.user_management}
            element={<UserManagement/>}
          />
          <Route exact path={path_url.users} element={<AddEditUsers/>} />
          <Route exact path={path_url.payment} element={<Payment/>} />
          <Route exact path={path_url.legals} element={<Legals/>} />
          <Route
            exact
            path={path_url.add_edit_legals}
            element={<AddEditLegals/>}
          />
          <Route
            exact
            path={path_url.home_property}
            element={<HomeProperty/>}
          />
          <Route exact path={path_url.add_estate} element={<AddEstate/>} />
          <Route exact path={path_url.edit_estate} element={<EditEstate/>} />
          <Route exact path={path_url.add_vehicle} element={<AddVehicle/>} />
          <Route
            exact
            path={path_url.edit_vehicle}
            element={<EditVehicle/>}
          />
          <Route exact path={path_url.add_assets} element={<AddAssets/>} />
          <Route exact path={path_url.edit_assets} element={<EditAssets/>} />
          <Route exact path={path_url.profile} element={<Profile/>} />

          <Route exact path={path_url.financial} element={<Financials/>} />
          <Route exact path={path_url.recipes} element={<Recipes/>} />

          <Route exact path={path_url.add_edit_financial} element={<AddEditFinancial/>} />
          <Route exact path={path_url.add_edit_recipe} element={<AddEditRecipe/>} />

          <Route
            exact
            path={path_url.funeral_wishes}
            element={<FuneralWishes/>}
          />
          <Route
            exact
            path={path_url.add_edit_cemetary}
            element={<AddEditCemetery/>}
          />
          <Route
            exact
            path={path_url.add_edit_readings}
            element={<AddEditReadings/>}
          />
          <Route
            exact
            path={path_url.add_edit_songs}
            element={<AddEditSongs/>}
          />
          <Route
            exact
            path={path_url.add_edit_instructions}
            element={<AddEditInstructions/>}
          />
          <Route
            exact
            path={path_url.message_to_loved_once}
            element={<MessageToLovedOnes/>}
          />
          <Route
            exact
            path={path_url.add_edit_message_to_loved_once}
            element={<AddEditMessages/>}
          />
          <Route exact path={path_url.my_deputies} element={<Deputies/>} />
          <Route
            exact
            path={path_url.add_edit_deputy}
            element={<AddEditDeputies/>}
          />
          <Route
            exact
            path={path_url.privacy_settings}
            element={<PrivacySettings/>}
          />
          <Route
            exact
            path={path_url.insuranceInformation}
            element={<InsuranceInformation/>}
          />
          <Route
            exact
            path={path_url.add_edit_insurance}
            element={<AddEditInsurance/>}
          />
          <Route
            exact
            path={path_url.payment_success}
            element={<PaymentSuccess/>}
          />
          <Route exact path={path_url.images} element={<Images/>} />
          <Route exact path={path_url.timeline} element={<Timeline/>} />
          <Route exact path={path_url.family_tree} element={<FamilyTree/>} />
          <Route
            exact
            path={path_url.add_edit_family_tree}
            element={<AddEditFamilyTree/>}
          />
          <Route exact path={path_url.my_story} element={<MyStory />} />
          <Route
            exact
            path={path_url.add_edit_timeline}
            element={<AddEditTimeline/>}
          />
          <Route
            exact
            path={`${path_url.deputy_view}/:userId`}
            element={<DeputyView/>}
          />
          <Route
            exact
            path={`${path_url.nominators}`}
            element={<Nominators/>}
          />
          <Route exact path={path_url.my_pets} element={<Pets/>} />
          <Route
            exact
            path={path_url.add_edit_pet}
            element={<AddEditPet/>}
          />
          <Route exact path={path_url.view_pet} element={<PetView/>} />
          <Route
            exact
            path={path_url.corporateDashboard}
            element={<DashboardAdmin/>}
          />
          {/* <Route
            exact
            path={[path_url.landing_page, path_url.login, path_url.signUp]}
            render={() =>
              props.history.push(
                `${path_url.personalInformation}/${userID}`
              )
            }
          /> */}
          <Route element={NotFound} />
        </Route>

        <Route exact path="/sign-up" element={<Register/>} />

        <Route path="/auth" element={<AuthLayout />}>
          <Route path={path_url.login} element={<Login/>} />
          <Route path={path_url.signUp} element={<Register />} />
          <Route
            exact
            path={path_url.corporateSignUp}
            element={<RegisterAdmin/>}
          />
          <Route exact path="*" element={<Login/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
export default AppRoutes;
