import React from "react";
import { features } from "../../Utility/constant";
import Item from "./Item";

const DefaultItems = (props) => {
    const { allowedFeatureNames } = props;

    return (
        <>
            {features.map((feature) =>
                !allowedFeatureNames.includes(feature.name) ? (
                    <Item item={feature} disable={true} />
                ) : null
            )}
        </>
    );
};

export default DefaultItems;
