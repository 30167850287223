import {get, post, put, del} from '../httpInterceptor'

export const getInsurance = (id) => {
  return get(`/insurances/${id}`, {}, true, false).then((res) => {
    return res
  })
}

export const listInsurances = () => {
	return get(`/insurances`, true).then((res) => {
		return res
	})
}

export const createInsurance = (companyName, policyNumber, beneficiaries, value) => {
  return post(
    `/insurances?[insurance][company_name]=${companyName}&[insurance][policy_number]=${policyNumber}&[insurance][beneficiaries]=${beneficiaries}&[insurance][value]=${value}`,
    {},
    true,
    false
  ).then((res) => {
    return res
  })
}

export const editInsurance = (insurance_id, companyName, policyNumber, beneficiaries, value) => {
  return put(
    `/insurances/${insurance_id}?[insurance][company_name]=${companyName}&[insurance][policy_number]=${policyNumber}&[insurance][beneficiaries]=${beneficiaries}&[insurance][value]=${value}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const delInsurance = (id) => {
  return del(`/insurances/${id}`, {}, true, false).then((res) => {
    return res
  })
}