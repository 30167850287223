import PersonalIcon from "../Assets/images/personal-info.svg";
import StoryIcon from "../Assets/images/my-story-menu.svg";
import TimelineIcon from "../Assets/images/timeline.svg";
import ImagesIcon from "../Assets/images/images-menu.svg";
import LegalIcon from "../Assets/images/doc-menu.svg";
import HomeIcon from "../Assets/images/home-property.svg";
import PetsIcon from "../Assets/images/pets-white.svg";
import FuneralIcon from "../Assets/images/funeral-wishes.svg";

import FinancialIcon from "../Assets/images/financial-menu.svg";
import RecipeIcon from "../Assets/images/recipe.svg";

import InsuranceIcon from "../Assets/images/insure-menu.svg";
import DeputyIcon from "../Assets/images/my-deputies.svg";
import LovedOnesIcon from "../Assets/images/msg-menu.svg";
import DigitalIcon from "../Assets/images/pwd-menu.svg";
import PrivacyIcon from "../Assets/images/private-menu.svg";
import CorporateIcon from "../Assets/images/corporate_icon.svg";

export const path_url = {
  auth: "/auth",
  landing_page: "/",
  login: "/auth/login",
  signUp: "/auth/sign-up",
  corporateSignUp: "/auth/corporate-sign-up",
  forgot_password: "/users/password/new",
  personalInformation: "/personal-information",
  persons: "/persons",
  digitalInformation: "/digital-information",
  account_management: "/account-management",
  payment: "/payment",
  payment_success: "/payment/success",
  user_management: "/user-management",
  users: "/user-management/users",
  profile: "/profile",
  home_property: "/home-property",
  add_estate: "/home-property/add-estate",
  edit_estate: "/home-property/edit-estate",
  add_vehicle: "/home-property/add-vehicle",
  edit_vehicle: "/home-property/edit-vehicle",
  add_assets: "/home-property/add-assets",
  edit_assets: "/home-property/edit-assets",
  financial: "/financial-management",
  add_edit_financial: "/financial-management/finance",
  recipes: "/recipes",
  add_edit_recipe: "/recipe/add-edit-recipe",
  legals: "/legals",
  add_edit_legals: "/legals/add-edit-legals",
  funeral_wishes: "/funeral-wishes",
  add_edit_cemetary: "/funeral-wishes/add-edit-cemetery",
  add_edit_readings: "/funeral-wishes/add-edit-readings",
  add_edit_songs: "/funeral-wishes/add-edit-songs",
  add_edit_instructions: "/funeral-wishes/add-edit-instructions",
  add_edit_account: "/account-management/add-edit-account",
  message_to_loved_once: "/message-to-loved-once",
  add_edit_message_to_loved_once: "/message-to-loved-once/messages",
  my_deputies: "/deputy-management",
  add_edit_deputy: "/deputy-management/deputies",
  privacy_settings: "/privacy-settings",
  insuranceInformation: "/insurance-information",
  add_edit_insurance: "/insurance-information/insurance",
  images: "/images",
  timeline: "/timeline",
  family_tree: "/family-tree",
  add_edit_family_tree: "/family-tree/add-edit",
  my_story: "/my-story",
  add_edit_timeline: "/timeline/add-edit-timeline",
  deputy_view: "/deputy-view",
  nominators: "/nominators",
  my_pets: "/my-pets",
  add_edit_pet: "/my-pets/add-edit-pet",
  view_pet: "/my-pets/view-pet",
  corporateDashboard: "/corporate-dashboard"
};

export const side_menu_icons = {
  personalInfo: PersonalIcon,
  mystory: StoryIcon,
  timeline: TimelineIcon,
  images: ImagesIcon,
  legal: LegalIcon,
  homeAndProperty: HomeIcon,
  pets: PetsIcon,
  funeralWishes: FuneralIcon,

  financials: FinancialIcon,
  recipes: RecipeIcon,

  insurance: InsuranceIcon,
  deputy: DeputyIcon,
  messages: LovedOnesIcon,
  digitalInfo: DigitalIcon,
  privacy: PrivacyIcon,
  questionnaire: PrivacyIcon,
  planning: PrivacyIcon,
  military: PrivacyIcon,
  corporate: CorporateIcon
};

export const features = [
  {
    id: "1",
    name: "personalInfo",
    url: "personalInformation",
    className: "img-fluid mr-2",
    value: "Personal Information"
  },
  {
    id: "11",
    name: "deputy",
    url: "my_deputies",
    className: "img-fluid mr-2",
    value: "Appoint Deputies"
  },
  {
    id: "2",
    name: "mystory",
    url: "my_story",
    className: "img-fluid pr-2 m-side",
    value: "Legacies & Stories"
  },
  {
    id: "3",
    name: "timeline",
    url: "timeline",
    className: "img-fluid pr-2 m-side",
    value: "Important Milestones"
  },
  {
    id: "4",
    name: "images",
    url: "images",
    className: "img-fluid pr-2 m-side",
    value: "Upload Pictures"
  },
  {
    id: "5",
    name: "legal",
    url: "legals",
    className: "img-fluid mr-2 ",
    value: "Upload Documents"
  },
  {
    id: "6",
    name: "homeAndProperty",
    url: "home_property",
    className: "img-fluid mr-2",
    value: "Properties & Assets"
  },
  {
    id: "7",
    name: "pets",
    url: "my_pets",
    className: "img-fluid pr-2 m-side",
    value: "Any Pets"
  },
  {
    id: "8",
    name: "funeralWishes",
    url: "funeral_wishes",
    className: "img-fluid mr-2",
    value: "Living Directives"
  },
  {
    id: "9",
    name: "financials",
    url: "financial",
    className: "img-fluid pr-2 m-side",
    value: "Financial Accounts"
  },
  {
    id: "10",
    name: "insurance",
    url: "insuranceInformation",
    className: "img-fluid pr-2 m-side",
    value: "Insurance Polices"
  },
  {
    id: "12",
    name: "messages",
    url: "message_to_loved_once",
    className: "img-fluid mr-2",
    value: "Messages To Loved Ones",
  },
  {
    id: "13",
    name: "digitalInfo",
    url: "digitalInformation",
    className: "img-fluid mr-2",
    value: "Password Manager",
  },
  {
    id: "14",
    name: "privacy",
    url: "privacy_settings",
    className: "img-fluid mr-2",
    value: "Privacy Settings",
  },
  {
    id: "15",
    name: "recipes",
    url: "recipes",
    className: "img-fluid mr-2",
    value: "Recipes"
  },
  // {
  //   id: "15",
  //   name: "questionnaire",
  //   url: "privacy_settings",
  //   className: "img-fluid mr-2",
  //   value: "Questionnaire",
  // },
  // {
  //   id: "16",
  //   name: "planning",
  //   url: "privacy_settings",
  //   className: "img-fluid mr-2",
  //   value: "Planning & Guide",
  // },
  // {
  //   id: "17",
  //   name: "military",
  //   url: "privacy_settings",
  //   className: "img-fluid mr-2",
  //   value: "Military",
  // },
];
