import { post } from "../httpInterceptor";

export const createUser = (
  firstName,
  lastName,
  email,
  password,
  accepted,
  /*middleName,
  suffix,
  title,*/
  account_id
) => {
  return post(
    /*account_id
      ? `/signup?email=${email}&password=${password}&first_name=${firstName}&last_name=${lastName}&accepted=${accepted}&account_id=${account_id}&middle_name=${middleName}&title=${title}&suffix=${suffix}`
      : `/signup?email=${email}&password=${password}&first_name=${firstName}&last_name=${lastName}&accepted=${accepted}&middle_name=${middleName}&title=${title}&suffix=${suffix}`,*/
    account_id
      ? `/signup?email=${email}&password=${password}&first_name=${firstName}&last_name=${lastName}&accepted=${accepted}&account_id=${account_id}`
      : `/signup?email=${email}&password=${password}&first_name=${firstName}&last_name=${lastName}&accepted=${accepted}`,
    {},
    false,
    false
  ).then((res) => {
    return res;
  });
};

export const createAdminUser = (
  firstName,
  lastName,
  email,
  password,
  accepted,
  /*middleName,
  suffix,
  title,*/
  accountName
) => {
  return post(
    /*`/signup?email=${email}&password=${password}&first_name=${firstName}&last_name=${lastName}&accepted=${accepted}&account_name=${accountName}&middle_name=${middleName}&title=${title}&suffix=${suffix}`,*/
    `/signup?email=${email}&password=${password}&first_name=${firstName}&last_name=${lastName}&accepted=${accepted}&account_name=${accountName}`,
    {},
    false,
    false
  ).then((res) => {
    return res;
  });
};

export const userLogin = (email, password) => {
  return post(
    `/login?email=${email}&password=${password}`,
    {},
    false,
    false
  ).then((res) => {
    return res;
  });
};
