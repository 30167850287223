import { get, post, put, del } from '../httpInterceptor';

export const getEstates = (id) => {
  return get(`/estates`, {}, true, false).then((res) => {
    return res;
  });
};

export const addEstates = (bodyObj = {}) => {
  return post(`/estates`, bodyObj, true, false).then((res) => {
    return res;
  });
};

export const editEstates = (estate_id, ownerName, propertyName, address) => {
  return put(
    `/estates/${estate_id}?[estate][owner_name]=${ownerName}&[estate][property_name]=${propertyName}&[estate][address]=${address}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const delEstates = (id) => {
  return del(`/estates/${id}`, {}, true, false).then((res) => {
    return res;
  });
};

export const getVehicles = (id) => {
  return get(`/vehicles`, {}, true, false).then((res) => {
    return res;
  });
};

export const addVehicles = (bodyObj = {}) => {
  return post(`/vehicles`, bodyObj, true, false).then((res) => {
    return res;
  });
};

export const editVehicles = (vehicle_id, vehicle_type, registration_number, state, estimated_value) => {
  return put(
    `/vehicles/${vehicle_id}?[vehicle][vehicle_type]=${vehicle_type}&[vehicle][registration_number]=${registration_number}&[vehicle][state]=${state}&[vehicle][estimated_value]=${estimated_value}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const delVehicles = (id) => {
  return del(`/vehicles/${id}`, {}, true, false).then((res) => {
    return res;
  });
};

export const getAssets = (id) => {
  return get(`/assets`, {}, true, false).then((res) => {
    return res;
  });
};

export const addAssets = (bodyObj = {}) => {
  return post(`/assets`, bodyObj, true, false).then((res) => {
    return res;
  });
};

export const editAssets = (assets_id, description, location, estimated_value) => {
  return put(
    `/assets/${assets_id}?[asset][description]=${description}&[asset][location]=${location}&[asset][estimated_value]=${estimated_value}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const delAssets = (id) => {
  return del(`/assets/${id}`, {}, true, false).then((res) => {
    return res;
  });
};
