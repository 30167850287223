import React, { useState, useEffect } from 'react';
import { path_url } from '../Utility/constant';
import RecipeIcon from '../Assets/images/recipe-blue.svg';
import Edit from '../Assets/images/edit.svg';
import Delete from '../Assets/images/delete.svg';
import { listRecipes, delRecipe } from '../Utility/Services/recipe';
import { ts } from '../Utility/ReduxToaster';
import { useNavigate } from 'react-router-dom';

const Recipes = (props) => {
  const navigate = useNavigate();
  const [ recipesList, setRecipesList ] = useState([]);
  const [prompts, setPrompt] = useState(false); 

  const addEditRecipes = (id, type, recipe) => {
    if (type === 'Edit') {
      navigate(path_url.add_edit_recipe, {
        state: { userid: id, data: recipesList, recipe_id: recipe['id'] }
      });
    } else {
      navigate(path_url.add_edit_recipe);
    }
  };

  useEffect(() => {
    fetchRecipesData();
  }, []);

  const fetchRecipesData = () => {
    listRecipes().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setRecipesList(res.data.recipes);
        setPrompt(res.data.prompts);
      }
    });
  };

  const onDeleteRecipe = (val) => {
    delRecipe(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchRecipesData();
        ts('Recipe deleted successfully');
      }
    });
  }; 
  return (
      <React.Fragment>
        <div className="w-100 content-section personal-info account-wrapper user-wrapper">

          <h2 className="fw-bold text-black content-title text-center">
              <img className="ew-image" src={RecipeIcon} alt="financial-icon" />
              {prompts && prompts.title}
              <hr className="mb-0" />
          </h2>
          
          <button onClick={() => addEditRecipes('', 'New')} className="person-btn">
            Add Recipe
          </button>

          {prompts && (   
            <div className="prompts">
              <strong>{prompts.name} </strong> <p> {prompts.body} </p>
            </div>
          )}

          <div className="row common-mt common-mb ">
            <div className="flex flex-column mb-5 mt-1 w-100">
              <div id="accordion">
                {recipesList && recipesList.length > 0 && (
                    <>
                      {recipesList.map((val, index) => {
                        return (
                            <React.Fragment>
                              <div className="card mb-4">
                                <div
                                    className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                                    id={"heading-" + index}
                                >
                                  <h5 className="flex-1 mb-0">
                                    <a
                                        className="collapsed"
                                        role="button"
                                        data-toggle="collapse"
                                        href={"#collapse-" + index}
                                        aria-expanded="false"
                                        aria-controls={"collapse-" + index}
                                    >
                                      {val.recipe_name}
                                    </a>
                                  </h5>
                                  <div className="d-flex justify-content-left">
                                  <span onClick={() => addEditRecipes(index, 'Edit', val)}>
                                    <img className="mr-3 cursor-pointer" src={Edit} alt="edit-icon" />
                                  </span>{' '}
                                    <span onClick={() => onDeleteRecipe(val)}>
                                    <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon" />
                                  </span>{' '}
                                  </div>
                                </div>
                                <div id={"collapse-" + index}
                                    className="collapse"
                                    data-parent="#accordion"
                                    aria-labelledby={"heading-" + index}
                                >
                                  <div className="card-body">
                                    {(
                                        <div className="property-card">
                                          <div className="d-flex align-items-center justify-content-between mb-4">
                                            <div className="d-flex align-items-center">
                                              <p className="text-dark-grey fz-15 mb-1">
                                                <span className="mr-1 fz-14 fw-bold">
                                                  Recipe:
                                                </span>
                                                { val.recipe_name }
                                              </p>
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                              <p className="text-dark-grey fz-15 mb-1">
                                                <span className="mr-1 fz-14 fw-bold">
                                                 Notes:
                                                </span>
                                                { val.notes }
                                              </p>
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                              <p className="text-dark-grey fz-15 mb-1">
                                                <span className="mr-1 fz-14 fw-bold">
                                                  Instruction:
                                                </span>
                                                { val.instructions }
                                              </p>
                                            </div>
                                          </div>
                                          
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                              <p className="text-dark-grey fz-15 mb-1">
                                                <span className="mr-1 fz-14 fw-bold">
                                                  Servings:
                                                </span>
                                                { val.servings }
                                              </p>
                                            </div>
                                          </div>

                                          <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                              <p className="text-dark-grey fz-15 mb-1">
                                                <span className="mr-1 fz-14 fw-bold">
                                                  Video Links:
                                                </span>
                                                { val.video_link }
                                              </p>
                                            </div>
                                          </div>


                                        </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                            </React.Fragment>
                        );
                      })}
                    </>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
  );
};
export default Recipes;