import React from "react";
export default function FamilyNode({ node, style }) {
  return (
    <div className="family-node-root" style={style} title={node.id}>
        <div className="familybox">
          <span className="imgbox"></span>
          <div className="familyinfo">
            <h5>Ronnie Jordan</h5>
          </div>
        </div>
    </div>
  //   <div style={{ overflow: "hidden", position: "relative" }}>
  //   <img
  //     src={node.gender === "male" ? female : female}
  //     className="pb-2"
  //     style={{
  //       borderRadius: "50%",
  //       zIndex: "1000",
  //       width: "auto",
  //       height: "75px",
  //       marginLeft: "20px",
  //     }}
  //   />
  //   <div
  //     className={classNames(
  //       "family-node-inner",
  //       node.gender === "male" ? "family-node-male" : "family-node-female",
  //       isRoot && "family-node-isRoot",
  //       "d-flex",
  //       "flex-column",
  //       "justify-content-center",
  //       "align-items-center"
  //     )}
  //     style={{
  //       position: "relative",
  //       top: "-40px",
  //       zIndex: "-1",

  //       width: "200px",
  //     }}
  //   >
  //     <p
  //       className="text-center"
  //       style={{ flex: 0, marginTop: "40px", paddingRight: "88px" }}
  //     >
  //       <strong>{node.name}</strong>
  //     </p>
  //   </div>

  //   {/* {node.hasSubTree && (
  //   <div
  //   className={classNames("sub", "female")}
  //   onClick={() => onSubClick(node.id)}
  //   />
  // )} */}
  // </div>
  );
}