import { get, post, put, del } from "../httpInterceptor";

export const createLegal = (bodyObj) => {
  return post("/legals", bodyObj, true, true).then((res) => {
    return res;
  });
};

export const listLegals = () => {
  return get(`/legals`, {}, true, false).then((res) => {
    return res;
  });
};

export const delLegal = (id) => {
  return del(`/legals/${id}`, {}, true, false).then((res) => {
    return res;
  });
};

export const updateLegal = (bodyObj, id) => {
  return put(`/legals/${id}`, bodyObj, true, true).then((res) => {
    return res;
  });
};
