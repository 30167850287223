import React, { useState, useEffect, useRef } from "react";
import Delete from "../Assets/images/delete.svg";
import ToolTip from "../Common/Input/ToolTip";
import { Input } from "../Common/Input";
import Edit from "../Assets/images/edit.svg";
import { path_url } from "../Utility/constant";
import { cloneDeep } from "lodash";
import {
  editPersonalInfo,
  getAccount,
  getCorporateUsers,
} from "../Utility/Services/account";
import { userInvite } from "../Utility/Services/user";
import { getFormDetails } from "../Utility/helper";
import { ts } from "../Utility/ReduxToaster";
import { useNavigate } from "react-router-dom";

const initForm = {
  firstName: "",
  lastName: "",
  email: "",
  errors: {
    firstName: null,
    lastName: null,
    email: null,
  },
};

const DashboardAdmin = (props) => {
  const navigate = useNavigate()
  const [loader, setLoader] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const tempFetch = useRef();

  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });
  const { form } = state;
  const { firstName, lastName, email, errors } = form;
  const [userList, setUserList] = useState([]);
  const [account, setAccount] = useState([]);
  const userId =
    localStorage.getItem("userID") && localStorage.getItem("userID");
  const accountId =
    localStorage.getItem("accountID") && localStorage.getItem("accountID");

  const fetchFun = () => {
    fetchAccountData();
    fetchUserList();
  };

  tempFetch.current = fetchFun;

  useEffect(() => {
    tempFetch.current();
  }, []);

  const fetchAccountData = () => {
    getAccount(userId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        const response = res.data.data.attributes;
        if (response) {
          setAccount(response.account);
          setState({
            ...state,
            form: {
              ...form,
              firstName: response.first_name,
              lastName: response.last_name,
              email: response.email,
              errors: errors,
            },
          });
        }
      }
    });
  };

  const fetchUserList = () => {
    getCorporateUsers(userId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setUserList(res.data.data);
      }
    });
  };

  const saveHandler = () => {
    let bodyObj = {
      first_name: firstName,
      last_name: lastName,
      email: email,
    };
    let obj = getFormDetails(
      { firstName, lastName, email, errors },
      onInputValidate
    );
    if (obj) {
      setLoader(true);
      //const userId = localStorage.getItem("userID") && localStorage.getItem("userID");
      editPersonalInfo(userId, bodyObj).then((res) => {
        if (res && res.error) {
          return;
        }
        if (res && !res.error) {
          fetchAccountData();
          setLoader(false);
          ts("Personal Information edited successfully");
          setEditMode(false);
        }
      });
    } else {
      return false;
    }
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const cancelHandler = () => {
    fetchAccountData();
    setEditMode(false);
  };

  const addEditUser = (id, type) => {
    if (type === "Edit") {
      navigate({
        pathname: path_url.users,
        accountid: accountId,
        returnTo: path_url.dashboardAdmin,
        state: { userid: id, data: userList },
      });
    } else {
      navigate({
        pathname: path_url.users,
        accountId: accountId,
        returnTo: path_url.dashboardAdmin,
      });
    }
  };

  const sendInvite = (id) => {
    setLoader(true);
    userInvite(id).then((res) => {
      if (res && res.error) {
        setLoader(false);
        return;
      }
      if (res && !res.error) {
        ts("Invitation sent successfully");
        setLoader(false);
      }
    });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-info" key="admin-dashboard">
        <h2 className="fw-bold text-black content-title" key="content-title">
          <i className="fas fa-building title-img text-blue mr-3" />
          Account
          <hr className="mb-0" />
        </h2>

        <div className="row mx-0" key="main-row">
          <div className="flex flex-column mb-5 mt-1 w-100" key="flex-1">
            <div id="accordion" key="main-accordion">
              <div className="card mb-4" key="card-1">
                <div
                  key="heading-1"
                  className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                  id="heading-1"
                >
                  <h5 key="card-1-h5" className="flex-1 mb-0">
                    <a
                      key="collapse-1"
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-1"
                      aria-expanded="false"
                      aria-controls="collapse-1"
                    >
                      Account Info
                    </a>
                  </h5>
                </div>
                <div
                  key="collapse-1"
                  id="collapse-1"
                  className="collapse"
                  data-parent="#accordion"
                  aria-labelledby="heading-1"
                >
                  <div className="card-body">
                    {editMode && (
                      <div className="row mx-auto" key="edit-user-row">
                        <div className="col-lg-4 mt-3" key="edit-user-div">
                          <label className="w-100 fw-semibold text-left mb-2">
                            First Name
                          </label>
                          <Input
                            key="firstName"
                            name="firstName"
                            value={firstName}
                            className="form-control input-content"
                            title="First Name"
                            isReq={true}
                            validationFunc={onInputValidate}
                            onChangeFunc={(name, value, error) => {
                              onInputChange(name, value, error);
                            }}
                            placeholder="Enter your first name"
                            error={errors.firstName}
                          />
                        </div>
                        <div className="col-lg-4 mt-3">
                          <label className="w-100 fw-semibold text-left mb-2">
                            Last Name
                          </label>
                          <Input
                            key="lastName"
                            name="lastName"
                            value={lastName}
                            className="form-control input-content"
                            title="Last Name"
                            isReq={true}
                            validationFunc={onInputValidate}
                            onChangeFunc={(name, value, error) => {
                              onInputChange(name, value, error);
                            }}
                            placeholder="Enter your last name"
                            error={errors.lastName}
                          />
                        </div>
                        <div className="col-lg-6 mt-3">
                          <label className="w-100 fw-semibold text-left mb-2">
                            Email-Id
                          </label>
                          <Input
                            key="email"
                            name="email"
                            value={email}
                            className="form-control input-content"
                            title="Email"
                            isReq={true}
                            validationFunc={onInputValidate}
                            onChangeFunc={(name, value, error) => {
                              onInputChange(name, value, error);
                            }}
                            placeholder="Enter your Email-Id"
                            error={errors.email}
                            disabled
                          />
                        </div>
                        <div className="col-lg-6 mt-4 personal-action">
                          <button
                            key="cancel-btn"
                            onClick={cancelHandler}
                            type="button"
                            className="cursor-pointer btn btn-primary br-10 fz-14 fw-bold mr-3"
                          >
                            Cancel
                          </button>
                          <button
                            key="save-btn"
                            onClick={saveHandler}
                            type="button"
                            className="cursor-pointer btn btn-primary br-10 fz-14 fw-bold"
                          >
                            {loader ? "Saving..." : "Save"}
                            {loader && (
                              <i
                                className="ml-2 fa-spin fa-lg fa fa-spinner"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                        </div>
                      </div>
                    )}
                    {!editMode && (
                      <div key="admin-user-info">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <div className="d-flex align-items-center">
                            <i className="far fa-user mr-2"></i>
                            <p className="text-blue fz-15 fw-semibold mb-0 left-2">
                              {firstName} {lastName}
                            </p>
                          </div>
                          <div className="d-flex align-items-center">
                            <span onClick={() => setEditMode(true)}>
                              <img
                                alt=""
                                className="mr-3 cursor-pointer"
                                src={Edit}
                              />
                            </span>{" "}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <i className="far fa-envelope mr-2"></i>
                            <p className="text-dark-grey fz-15 mb-0 left-2">
                              {email}
                            </p>
                          </div>
                        </div>
                        {account && (
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <i className="far fa-building mr-2"></i>
                              <p className="text-dark-grey fz-15 mb-0 left-2">
                                {account.name}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card mb-4" key="card-2">
                <div key="heading-2" className="card-header" id="heading-2">
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-2"
                      aria-expanded="false"
                      aria-controls="collapse-2"
                    >
                      User Management
                    </a>
                  </h5>
                </div>
                <div
                  key="collapse-2"
                  id="collapse-2"
                  className="collapse"
                  data-parent="#accordion"
                  aria-labelledby="heading-2"
                >
                  <div className="card-body" key="card-body-2">
                    <div className="w-100 account-wrapper user-wrapper">
                      <button
                        onClick={() => addEditUser("", "New")}
                        className="btn btn-primary"
                        key="new-user"
                      >
                        Add New User
                      </button>
                      <div className="row common-mb" key="users-div">
                        <div className="col-md-12" key="users-table-div">
                          <div
                            className="custom-table-main shadow table-responsive"
                            key="users-table-responsive"
                          >
                            <table
                              className="table table-striped"
                              key="usersList-table"
                            >
                              <thead key="usersList-head">
                                <tr>
                                  <th width="20%">First Name</th>
                                  <th width="20%">Last Name</th>
                                  <th width="25%">Email Address</th>
                                  <th width="15%">Role</th>
                                  <th width="20%">Actions</th>
                                </tr>
                              </thead>
                              {userList && userList.length > 0 && (
                                <tbody key="usersList-body">
                                  {userList.map((val, index) => {
                                    return (
                                      <React.Fragment>
                                        {!val.attributes.account_admin && (
                                          <tr key={`index-${index}`}>
                                            <td className="d-flex align-items-center">
                                              {val.attributes.first_name}
                                            </td>
                                            <td>{val.attributes.last_name}</td>
                                            <td>{val.attributes.email}</td>
                                            <td>
                                              {val.attributes.account_admin
                                                ? "Admin"
                                                : "Member"}
                                            </td>
                                            <td>
                                              <div className="d-flex justify-content-left">
                                                <span
                                                  onClick={() =>
                                                    addEditUser(index, "Edit")
                                                  }
                                                >
                                                  <img
                                                    alt=""
                                                    src={Edit}
                                                    className="mr-3 cursor-pointer"
                                                  />
                                                </span>
                                                <span>
                                                  <ToolTip
                                                    className="mr-3 cursor-pointer"
                                                    imgSrc={Delete}
                                                  />
                                                  {/* <img alt="" src={Delete} className="mr-3 cursor-pointer" /> */}
                                                </span>{" "}
                                                <span>
                                                  <button
                                                    onClick={() =>
                                                      sendInvite(val.id)
                                                    }
                                                    className="invite-btn cursor-pointer"
                                                  >
                                                    <i
                                                      className="fa fa-paper-plane mr-2"
                                                      aria-hidden="true"
                                                    />
                                                    Send Invite
                                                  </button>
                                                </span>
                                              </div>
                                            </td>
                                          </tr>
                                        )}
                                      </React.Fragment>
                                    );
                                  })}
                                </tbody>
                              )}
                            </table>
                            {userList && userList.length === 0 && (
                              <h4
                                className="pt-3 fz-16 pb-4 text-center"
                                key="no-data-found"
                              >
                                <i className="fas fa-exclamation-triangle mr-2" />
                                No Data Found
                              </h4>
                            )}
                            {userList && userList.length === 1 && (
                              <React.Fragment>
                                {userList.map((val, index) => {
                                  return (
                                    <React.Fragment>
                                      {val.attributes.account_admin && (
                                        <h4
                                          className="pt-3 fz-16 pb-4 text-center"
                                          key="account-admin"
                                        >
                                          <i className="fas fa-exclamation-triangle mr-2" />
                                          No Data Found
                                        </h4>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </React.Fragment>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default DashboardAdmin;
