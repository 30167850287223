import React from 'react';
import DigitalIcon from '../Assets/images/pwd-blue.svg';
import FacebookIcon from '../Assets/images/facebook.png';
import InstagramIcon from '../Assets/images/instagram.png';
import LinkedinIcon from '../Assets/images/linkedin.png';
import TwitterIcon from '../Assets/images/twitter.png';
import ToolTip from '../Common/Input/ToolTip';
import PlusIcon from '../Assets/images/plus-circle.svg';
import Edit from '../Assets/images/edit.svg';
import Delete from '../Assets/images/delete.svg';

const DigitalInformation = (props) => {
	return (
		<React.Fragment>
			<div className="w-100 content-section personal-info">
				<h2 className="fw-bold text-black content-title text-center">
					<img className="img-fluid mr-4" src={DigitalIcon} alt="digital-icon" />
					  Passwords & Digital Assets
					<hr className="mb-0" />
				</h2>

				<div className="prompts">
                  <strong>Emergency Access to Passwords & Social Media</strong> 
                  <p>Passwords and social media account credentials, ensuring that loved ones can access them in case of an emergency </p>
                 </div>

				<div className="row mx-0">
					<div className="flex flex-column mb-5 mt-1 w-100">
						<div id="accordion">
							<div className="card mb-4">
								<div
									className="card-header custom-py-15 d-flex align-items-center justify-content-between"
									id="heading-1"
								>
									<h5 className="flex-1 mb-0">
										<a
											className="collapsed"
											role="button"
											data-toggle="collapse"
											href="#collapse-1"
											aria-expanded="false"
											aria-controls="collapse-1"
										>
											Facebook
										</a>
									</h5>
									<ToolTip
										btnStyle="share-btn d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
										isButtonImage={true}
										imgStyle="img-fluid mr-2 h-20"
										btnText={'Add'}
										imgSrc={PlusIcon}
									/>
								</div>
								<div
									id="collapse-1"
									className="collapse"
									data-parent="#accordion"
									aria-labelledby="heading-1"
								>
									<div className="card-body">
										<div className="d-flex social-box property-card col-md-12 pt-4 pb-2">
											<div className="col-12 col-md-2 col-lg-1">
												<img className="img-fluid h-75 mr-4" src={FacebookIcon} alt="facebook-icon"/>
											</div>
											<div className="col-12 col-md-8 col-lg-9">
												<p>
													<b>Account Link : </b> https://www.facebook.com/login
												</p>
												<p>
													<b>login ID : </b>
												</p>
												<p>
													<b>Password : </b>
												</p>
											</div>
											<div className="col-12 col-md-2 col-lg-2 d-flex justify-content-right">
												<span>
													<ToolTip className="mr-4 cursor-pointer" imgSrc={Edit} />
												</span>{' '}
												<span>
													<ToolTip className="cursor-pointer" imgSrc={Delete} />
												</span>{' '}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card mb-4">
								<div
									className="card-header custom-py-15 d-flex align-items-center justify-content-between"
									id="heading-2"
								>
									<h5 className="flex-1 mb-0">
										<a
											className="collapsed"
											role="button"
											data-toggle="collapse"
											href="#collapse-2"
											aria-expanded="false"
											aria-controls="collapse-2"
										>
											Instagram
										</a>
									</h5>
									<ToolTip
										btnStyle="share-btn d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
										isButtonImage={true}
										imgStyle="img-fluid mr-2 h-20"
										btnText={'Add'}
										imgSrc={PlusIcon}
									/>
								</div>
								<div
									id="collapse-2"
									className="collapse"
									data-parent="#accordion"
									aria-labelledby="heading-2"
								>
									<div className="card-body">
										<div className="d-flex social-box property-card col-md-12 pt-4 pb-2">
											<div className="col-12 col-md-2 col-lg-1">
												<img className="img-fluid h-75 mr-4" src={InstagramIcon} alt="instagram-icon" />
											</div>
											<div className="col-12 col-md-8 col-lg-9">
												<p>
													<b>Account Link : </b> https://www.instagram.com/login
												</p>
												<p>
													<b>login ID : </b>
												</p>
												<p>
													<b>Password : </b>
												</p>
											</div>
											<div className="col-12 col-md-2 col-lg-2 d-flex justify-content-right">
												<span>
													<ToolTip className="mr-4 cursor-pointer" imgSrc={Edit} />
												</span>{' '}
												<span>
													<ToolTip className="cursor-pointer" imgSrc={Delete} />
												</span>{' '}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card mb-4">
								<div
									className="card-header custom-py-15 d-flex align-items-center justify-content-between"
									id="heading-3"
								>
									<h5 className="flex-1 mb-0">
										<a
											className="collapsed"
											role="button"
											data-toggle="collapse"
											href="#collapse-3"
											aria-expanded="false"
											aria-controls="collapse-3"
										>
											LinkedIn
										</a>
									</h5>
									<ToolTip
										btnStyle="share-btn d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
										isButtonImage={true}
										imgStyle="img-fluid mr-2 h-20"
										btnText={'Add'}
										imgSrc={PlusIcon}
									/>
								</div>
								<div
									id="collapse-3"
									className="collapse"
									data-parent="#accordion"
									aria-labelledby="heading-3"
								>
									<div className="card-body">
										<div className="d-flex social-box property-card col-md-12 pt-4 pb-2">
											<div className="col-12 col-md-2 col-lg-1">
												<img className="img-fluid h-75 mr-4" src={LinkedinIcon} alt="linkedin-icon" />
											</div>
											<div className="col-12 col-md-8 col-lg-9">
												<p>
													<b>Account Link : </b> https://www.linkedin.com/login
												</p>
												<p>
													<b>login ID : </b>
												</p>
												<p>
													<b>Password : </b>
												</p>
											</div>
											<div className="col-12 col-md-2 col-lg-2 d-flex justify-content-right">
												<span>
													<ToolTip className="mr-4 cursor-pointer" imgSrc={Edit} />
												</span>{' '}
												<span>
													<ToolTip className="cursor-pointer" imgSrc={Delete} />
												</span>{' '}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card mb-4">
								<div
									className="card-header custom-py-15 d-flex align-items-center justify-content-between"
									id="heading-4"
								>
									<h5 className="flex-1 mb-0">
										<a
											className="collapsed"
											role="button"
											data-toggle="collapse"
											href="#collapse-4"
											aria-expanded="false"
											aria-controls="collapse-4"
										>
											Twitter
										</a>
									</h5>
									<ToolTip
										btnStyle="share-btn d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
										isButtonImage={true}
										imgStyle="img-fluid mr-2 h-20"
										btnText={'Add'}
										imgSrc={PlusIcon}
									/>
								</div>
								<div
									id="collapse-4"
									className="collapse"
									data-parent="#accordion"
									aria-labelledby="heading-4"
								>
									<div className="card-body">
										<div className="d-flex social-box property-card col-md-12 pt-4 pb-2">
											<div className="col-12 col-md-2 col-lg-1">
												<img className="img-fluid h-75 mr-4" src={TwitterIcon} alt="twitter-icon" />
											</div>
											<div className="col-12 col-md-8 col-lg-9">
												<p>
													<b>Account Link : </b> https://www.twitter.com/login
												</p>
												<p>
													<b>login ID : </b>
												</p>
												<p>
													<b>Password : </b>
												</p>
											</div>
											<div className="col-12 col-md-2 col-lg-2 d-flex justify-content-right">
												<span>
													<ToolTip className="mr-4 cursor-pointer" imgSrc={Edit} />
												</span>{' '}
												<span>
													<ToolTip className="cursor-pointer" imgSrc={Delete} />
												</span>{' '}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};
export default DigitalInformation;
