import {get, post, put, del} from '../httpInterceptor'

export const getFinancial = (id) => {
  return get(`/financials/${id}`, {}, true, false).then((res) => {
    return res
  })
}

export const listFinanicals = () => {
	return get(`/financials`, true).then((res) => {
		return res
	})
}

export const createFinancial = (bankName, accountNumber, loginName, password) => {
  return post(
    `/financials?[financial][bank_name]=${bankName}&[financial][account_number]=${accountNumber}&[financial][login_name]=${loginName}&[financial][password]=${password}`,
    {},
    true,
    false
  ).then((res) => {
    return res
  })
}

export const editFinancial = (finance_id, bankName, accountNumber, loginName, password) => {
  return put(
    `/financials/${finance_id}?[financial][bank_name]=${bankName}&[financial][account_number]=${accountNumber}&[financial][login_name]=${loginName}&[financial][password]=${password}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const delFinancial = (id) => {
  return del(`/financials/${id}`, {}, true, false).then((res) => {
    return res
  })
}