import React, { useEffect, useRef, useState } from "react";
import { path_url } from "../Utility/constant";
import { getAccount } from "../Utility/Services/account";
import Edit from "../Assets/images/edit.svg";
import { titleCase } from "../Utility/helper";
import { useNavigate } from "react-router-dom";
const AccountManagement = (props) => {
  const navigate = useNavigate();
  const [account, setAccount] = useState({
    organizationName: "",
    subdomain: "",
    accountType: "",
    subscriptionPlan: "",
    code: "",
    accountId: "",
  });
  const tempAccount = useRef();

  const user_id = localStorage.getItem("userID");

  const { accountType, subscriptionPlan } = account;

  const fetchAccFun = () => {
    fetchAccountData();
  };

  tempAccount.current = fetchAccFun;

  useEffect(() => {
    tempAccount.current();
  }, []);

  const fetchAccountData = () => {
    getAccount(user_id).then((res) => {
      if (res && res.error) {
        return;
      }
      const resData = res.data.data.attributes;

      if (res && resData.account && !res.error) {
        setAccount({
          organizationName: resData.account.name,
          subdomain: resData.account.subdomain,
          accountType:
            resData.subscription_plan_arr &&
            resData.subscription_plan_arr.account_type,
          subscriptionPlan:
            resData.subscription_plan_arr && resData.subscription_plan_arr.name,
          accountId: resData.account.id,
        });
      } else if (!resData.account) {
        let account = {
          accountType:
            resData.subscription_plan_arr &&
            resData.subscription_plan_arr.account_type,
          subscriptionPlan:
            resData.subscription_plan_arr && resData.subscription_plan_arr.name,
        };
        navigate(path_url.add_edit_account, {
          state: { type: "add", account },
        });
      }
    });
  };

  return (
    <React.Fragment>
      {subscriptionPlan && (
        <div className="w-100 content-section account-wrapper user-wrapper">
          <h2 className="fw-bold text-black content-title">
            <i className="fas fa-users title-img text-blue mr-3" />
            Account <hr className="mb-0" />
          </h2>

          <div className="row">
            <div className="col-lg-12">
              <div className="custom-table-main shadow table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr className="text-center">
                      <th>Account Type</th>
                      {/* <th>Organization</th> */}
                      {/* <th>Sub Domain</th> */}
                      <th>Subscription</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="text-center">
                      <td>{accountType && titleCase(accountType)}</td>
                      {/* <td>{organizationName}</td> */}
                      {/* <td>{subdomain}</td> */}
                      <td>{subscriptionPlan && titleCase(subscriptionPlan)}</td>
                      <td>
                        <span
                          onClick={() =>
                            navigate(path_url.add_edit_account, {
                              state: { type: "edit", account },
                            })
                          }
                        >
                          <img
                            className="mr-3 cursor-pointer"
                            src={Edit}
                            alt="edit-icon"
                          />
                        </span>{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default AccountManagement;
