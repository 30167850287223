import React, { useState } from 'react';
import { Input, Select, TextArea } from '../Common/Input';
import { cloneDeep } from 'lodash';
import { Link } from 'react-router-dom';
import { path_url } from '../Utility/constant';
import Calendar from '../Assets/images/calendar.svg';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PersonalIcon from '../Assets/images/person-blue.svg';

const initForm = {
	firstName: '',
	lastName: '',
	address: '',
	email: '',
	contactNumber: '',
	gender: '',
	errors: {
		firstName: null,
		lastName: null,
		address: null,
		email: null,
		contactNumber: null,
		gender: null
	}
};

const genderOptions = [ { value: 'Male', label: 'Male' }, { value: 'Female', label: 'Female' } ];

const AddEditPerson = (props) => {
	const [ state, setState ] = useState({
		form: cloneDeep(initForm)
	});
	const [ birthDate, setBirthDate ] = useState('');

	const loader = false;
	const user_id = localStorage.getItem('userID');

	let { form } = state;
	let { errors, firstName, lastName, address, email, contactNumber, gender } = form;

	const onInputValidate = (name, error) => {
		let { errors } = state.form;
		errors[name] = error;
		setState({ ...state, form: { ...state.form, errors: errors } });
	};

	const onInputChange = (name, value, error = undefined) => {
		const { form } = state;
		form[name] = value;
		if (error !== undefined) {
			let { errors } = form;
			errors[name] = error;
		}
		setState({ form });
	};

	return (
		<React.Fragment>
			<div className="w-100 content-section account-wrapper user-wrapper">
				<h2 className="fw-bold text-black content-title">
					<img className="img-fluid mr-3 title-img" src={PersonalIcon} alt="personal-icon" />Add Person
					<hr className="mb-0" />
				</h2>
				<div className="common-mt add-edit-modal">
					<div className="row mb-3 mx-auto">
						<div className="col-lg-6">
							<label className="w-100 fw-semibold mb-2 text-left fz-18">First Name</label>
							<Input
								name="firstName"
								value={firstName}
								className="form-control input-content text-black placeholder-black border-dark-grey"
								title="First Name"
								isReq={true}
								validationFunc={onInputValidate}
								onChangeFunc={(name, value, error) => {
									onInputChange(name, value, error);
								}}
								placeholder="Enter first name"
								error={errors.firstName}
							/>
						</div>
						<div className="col-lg-6">
							<label className="w-100 fw-semibold mb-2 text-left fz-18">Last Name</label>
							<Input
								name="lastName"
								value={lastName}
								className="form-control input-content text-black placeholder-black border-dark-grey"
								title="Last Name"
								isReq={true}
								validationFunc={onInputValidate}
								onChangeFunc={(name, value, error) => {
									onInputChange(name, value, error);
								}}
								placeholder="Enter last name"
								error={errors.lastName}
							/>
						</div>
					</div>
					<div className="row mb-1 mx-auto">
						<div className="col-lg-12">
							<label className="w-100 fw-semibold mb-2 text-left fz-18">Address</label>
							<TextArea
								name="address"
								value={address}
								className="form-control input-content text-black placeholder-black border-dark-grey"
								title="Address"
								isReq={true}
								validationFunc={onInputValidate}
								onChangeFunc={(name, value, error) => {
									onInputChange(name, value, error);
								}}
								rows={4}
								placeholder="Enter address"
								error={errors.address}
							/>
						</div>
					</div>
					<div className="row mb-3 mx-auto">
						<div className="col-lg-6">
							<label className="w-100 fw-semibold text-left mb-2 fz-18">Email</label>
							<Input
								name="email"
								value={email}
								className="form-control input-content text-black placeholder-black border-dark-grey"
								title="Email"
								isReq={true}
								reqType="email"
								validationFunc={onInputValidate}
								onChangeFunc={(name, value, error) => {
									onInputChange(name, value, error);
								}}
								placeholder="Enter your email address"
								error={errors.email}
							/>
						</div>
						<div className="col-lg-6">
							<label className="w-100 fw-semibold text-left mb-2 fz-18">Contact Number</label>
							<Input
								name="contactNumber"
								value={contactNumber}
								className="form-control input-content text-black placeholder-black border-dark-grey"
								title="Contact Number"
								isReq={true}
								reqType="number"
								validationFunc={onInputValidate}
								onChangeFunc={(name, value, error) => {
									onInputChange(name, value, error);
								}}
								placeholder="Enter your contact number"
								error={errors.contactNumber}
							/>
						</div>
					</div>

					<div className="row mb-3 mx-auto">
						<div className="col-lg-6 position-relative">
							<label className="w-100 fw-semibold text-left mb-2">Birthdate</label>
							<img className="img-fluid mr-3 position-absolute date-icon" src={Calendar} alt="calendar-icon" />

							<DatePicker
								className="form-control input-content"
								selected={birthDate}
								onChange={(date) => setBirthDate(date)}
								placeholderText="Select birthdate"
								dateFormat="dd/MM/yyyy"
							/>
						</div>
						<div className="col-lg-6">
							<label className="w-100 fw-semibold text-left mb-2">Gender</label>
							<Select
								name="gender"
								value={gender}
								options={genderOptions}
								className="form-control input-content"
								title="Gender"
								isReq={true}
								validationFunc={onInputValidate}
								onChangeFunc={(name, value, error) => {
									onInputChange(name, value, error);
								}}
								placeholder="Select gender"
								error={errors.gender}
							/>
						</div>
					</div>

					<div className="col-lg-12 mt-4">
						<Link to={`${path_url.personalInformation}/${user_id}`}>
							<button className="cancel-btn mr-4">Cancel</button>
						</Link>

						<button type="button" className="btn btn-primary br-10 fz-16 fw-bold">
							{loader ? 'Loading...' : 'Add'}
							{loader && <i className="ml-2 fa-spin fa-lg fa fa-spinner" aria-hidden="true" />}
						</button>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default AddEditPerson;
