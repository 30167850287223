import { cloneDeep } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import PersonalIcon from "../Assets/images/person-blue.svg";
import PdfIcon from "../Assets/images/pdfIcon2.png";
import PNGIcon from "../Assets/images/PNGIcon.png";
import JPGIcon from "../Assets/images/JPGIcon.png";
import DefaultPet from '../Assets/images/malamute.jpg';
import View from '../Assets/images/view.svg';
import { path_url } from "../Utility/constant";
import {
  getDeputyData,
  getDeputyViewAllowedFeatures,
  getDeputyViewCremations,

  getDeputyViewFinancials,
  getDeputyViewRecipes,
  
  getDeputyViewHomeAndProp,
  getDeputyViewImages,
  getDeputyViewInfo,
  getDeputyViewInstructionData,
  getDeputyViewInsurances,
  getDeputyViewLegalsData,
  getDeputyViewMessages,
  getDeputyViewReadingData,
  getDeputyViewSongsData,
  getDeputyViewStories,
  getDeputyViewTimeline,
  getDeputyViewPets

} from "../Utility/Services/deputyView";
import { Chrono } from "react-chrono";
import { titleCase } from "../Utility/helper";
import { useLocation, useParams } from "react-router-dom";

const initForm = {
  name: "",
  email: "",
  phoneNumber: "",
  dob: "",

  errors: {
    name: null,
    email: null,
  },
};

const user_id = localStorage.getItem("userID");

const DeputyView = (props) => {
  const { userId } = useParams();
  
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });
  const [nominatorId, setNominatorId] = useState("");
  const [description, setDescription] = useState([]);
  const [cremationsList, setCremationsList] = useState([]);
  const [readingList, setReadingList] = useState([]);
  const [songsList, setSongsList] = useState([]);
  const [instructionList, setInstructionsList] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [featuresList, setFeaturesList] = useState([]);
  const [realEstate, setRealEstate] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [otherAssets, setOtherAssets] = useState([]);
  const [messages, setMessages] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [myDeputies, setMyDeputies] = useState([]);
  const [images, setImages] = useState([]);

  const [financials, setFinancials] = useState([]);
  const [recipes, setRecipes] = useState([]);
  
  const [timeline, setTimeline] = useState([]);
  const [pets, setPets] = useState([]);
  const [itemsValues, setItemsValue] = useState([]);
  const tempNominator = useRef();
  const location = useLocation();

  const { form } = state;
  const { name, email, phoneNumber, dob, errors } = form;

  useEffect(() => {
    nominatorFun();
  }, [location.pathname]);

  const nominatorFun = () => {
    setNominatorId(userId);
    if (nominatorId) {
      fetchAllowedFeatures();
      fetchAccountData();
      fetchStories();
      fetchCremationData();
      fetchInstructionData();
      fetchReadingData();
      fetchSongsData();
      fetchLegalsData();
      fetchHomeAndProp();
      fetchMessages();
      fetchInsurances();
      fetchDeputies();
      fetchImages();

      fetchFinancials();
      fetchRecipes();

      fetchTimeline();
      fetchMyPets();
    }
  };

  tempNominator.current = nominatorFun;

  useEffect(() => {
    tempNominator.current();
  }, [nominatorId, props]);

  const fetchAccountData = () => {
    getDeputyViewInfo(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && !res.error) {
        const response = res.data && res.data.data.attributes;
        if (response) {
          setState({
            ...state,
            form: {
              ...form,
              name: `${response.first_name} ${response.last_name}`,
              email: response.email,
              phoneNumber: response.phone_no,
              dob: response.date_of_birth,
              errors: errors,
            },
          });
        }
      }
    });
  };

  const fetchAllowedFeatures = () => {
    getDeputyViewAllowedFeatures(user_id, nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && !res.error) {
        const stories = res.data && res.data.privacy_settings;
        if (stories) {
          setFeaturesList(stories);
        }
      }
    });
  };

  const fetchStories = () => {
    getDeputyViewStories(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && !res.error) {
        const stories = res.data && res.data.my_stories;
        if (stories) {
          setDescription(stories);
        }
      }
    });
  };

  const fetchCremationData = () => {
    getDeputyViewCremations(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setCremationsList(res.data && res.data.plot_burial_cremations);
      }
    });
  };

  const fetchReadingData = () => {
    getDeputyViewReadingData(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setReadingList(res.data && res.data.readings);
      }
    });
  };

  const fetchSongsData = () => {
    getDeputyViewSongsData(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setSongsList(res.data && res.data.music_and_songs);
      }
    });
  };

  const fetchInstructionData = () => {
    getDeputyViewInstructionData(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setInstructionsList(res.data && res.data.special_instructions);
      }
    });
  };

  const fetchLegalsData = () => {
    getDeputyViewLegalsData(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setDocumentList(res.data);
      }
    });
  };

  const fetchHomeAndProp = () => {
    getDeputyViewHomeAndProp(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        const data = res && res.data;

        if (data) {
          setRealEstate(data.real_estate);
          setVehicles(data.vehicles);
          setOtherAssets(data.other_assets);
        }
      }
    });
  };

  const fetchMessages = () => {
    getDeputyViewMessages(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        const data = res && res.data;

        if (data) {
          setMessages(data.messages);
        }
      }
    });
  };

  const fetchInsurances = () => {
    getDeputyViewInsurances(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        const data = res && res.data;

        if (data) {
          setInsurances(data.insurances);
        }
      }
    });
  };

  const fetchDeputies = () => {
    getDeputyData(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        const data = res && res.data;
        if (data) {
          setMyDeputies(data.my_deputies);
        }
      }
    });
  };

  const fetchImages = () => {
    getDeputyViewImages(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && !res.error) {
        const data = res && res.data;

        if (data) {
          setImages(data);
        }
      }
    });
  };

  const fetchFinancials = () => {
    getDeputyViewFinancials(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        const data = res && res.data;

        if (data) {
          setFinancials(data.financials);
        }
      }
    });
  };

  const fetchRecipes = () => {
    getDeputyViewRecipes(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        const data = res && res.data;

        if (data) {
          setRecipes(data.recipes);
        }
      }
    });
  };




  const fetchMyPets = () => {
    getDeputyViewPets(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        const data = res && res.data;

        if (data) {
          setPets(data.pets);
        }
      }
    });
  };
  
  const viewPet = (val) => {
      props.history.push({
        pathname: path_url.view_pet,
        state: { id: val.id, data: val },
      });
  };

  const createDivArray = (timeline) => {
    setItemsValue(timeline.map((item) => item));
    return timeline.map((item) => (
      <div className="text-center datebox">
        <div className="d-flex title-date">
          <h4 className="mt-2 ">{item.title && titleCase(item.title)}</h4>
        </div>
        <hr
          style={{
            borderTop: "1px solid black",
            width: 300,
          }}
        />
        <p className="timeline-dec">{item.description}</p>
      </div>
    ));
  };

  const fetchTimeline = () => {
    getDeputyViewTimeline(nominatorId).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        const data = res && res.data;

        if (data) {
          setTimeline(
            createDivArray(
              data.timeline.sort(
                (item1, item2) => new Date(item1.date) - new Date(item2.date)
              )
            )
          );
        }
      }
    });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section personal-info">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={PersonalIcon}
            alt="personal-icon"
          />{" "}
          Deputy View <hr className="mb-0" />
        </h2>

        <div className="row mx-0 common-mt">
          <div className="flex flex-column mb-5 mt-1 w-100">
            <div id="accordion">
            {featuresList.includes("pets") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-4"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-4"
                        aria-expanded="false"
                        aria-controls="collapse-4"
                      >
                        Pets
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-4"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-4"
                  >
                    <div className="card-body" key="card-body">
                      <div className="upload-wrapper personal-info">
                        <div className="row mx-auto mb-2" key="pet-row">
                            { pets &&
                              pets.length > 0 &&
                              pets.map((pet, index) => {
                                return (                                
                                  <div className="col-md-3 mb-3" key={`pet-${index}`}>
                                    <div className="imagePreviewList text-center position-relative"
                                         style={{
                                           borderColor: '#c8d0d1'
                                         }}>
                                      <div className="imageThumb py-2"
                                           style={{
                                              backgroundImage: `url(${pet.image_info && pet.image_info.url ? pet.image_info.url : DefaultPet})`,
                                              backgroundPosition: 'center',
                                              backgroundRepeat: 'no-repeat',
                                              backgroundSize: 'cover'
                                            }}>
                                        {/*<img src={pet.image_info && pet.image_info.url ? pet.image_info.url : DefaultPet} className="img-fluid" alt="style-file" />*/}
                                      
                                        <span>
                                          <span onClick={() => viewPet(pet)}  className="position-absolute imageEditBtn h-18">
                                            <img className="mr-3 cursor-pointer" src={View} alt="view-icon" />                          
                                          </span>{' '}
                                        </span>
                                      </div>
                                      <div className="imageName px-3 py-2 break-text"
                                           style={{
                                              borderBottomLeftRadius: '10px',
                                              borderBottomRightRadius: '10px'
                                           }}>
                                        {pet.name}
                                      </div>
                                    </div>
                                  </div>
                                );
                            })}
                            {pets && pets.data && pets.data.length === 0 && (
                              <div key="no-records">
                                <h6 className="mb-0">No records found</h6>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("personalInfo") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-1"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-1"
                        aria-expanded="false"
                        aria-controls="collapse-1"
                      >
                        Info
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-1"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-1"
                  >
                    <div className="card-body">
                      <div className="property-card">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <div className="d-flex align-items-center">
                            <i class="far fa-user mr-2"></i>
                            <p className="text-blue fz-15 fw-semibold mb-0 left-2">
                              {name}
                            </p>
                          </div>
                          <div className="d-flex align-items-center">
                            <span>
                              <img
                                className="mr-3 cursor-pointer"
                                alt="pointer-img"
                              />
                            </span>{" "}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="d-flex align-items-center">
                            <i class="far fa-envelope mr-2"></i>
                            <p className="text-dark-grey fz-15 mb-0 left-2">
                              {email}
                            </p>
                          </div>
                        </div>
                        {phoneNumber && (
                          <div className="d-flex align-items-center justify-content-between mt-4">
                            <div className="d-flex align-items-center">
                              <i class="fas fa-mobile-alt mr-2 ml-1"></i>
                              <p className="text-dark-grey fz-15 mb-0 left-2">
                                {phoneNumber && phoneNumber}
                              </p>
                            </div>
                          </div>
                        )}
                        {dob && (
                          <div className="d-flex align-items-center justify-content-between mt-4 ">
                            <div className="d-flex align-items-center">
                              <i class="far fa-calendar-check mr-2"></i>
                              <p className="text-dark-grey fz-15 mb-0 left-2">
                                {dob && dob}
                              </p>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("recipes") && (
                <div className="card mb-4">
                  <div className="card-header d-flex align-items-center justify-content-between" id="heading-2">
                  <h5 className="flex-1 mb-0">
                      <a className="collapsed" role="button" data-toggle="collapse" href="#collapse-2" aria-expanded="false" aria-controls="collapse-2">
                        Recipes
                      </a>
                    </h5>
                  </div>
                  <div id="collapse-2" className="collapse" data-parent="#accordion" aria-labelledby="heading-2">


                  <div className="card-body">
                      <div className="row mx-auto">
                        {recipes && recipes.length > 0 && recipes.map((val, index) => {
                            return (
                              <div className="col-md-12 property-card mb-4">
                                <div className="d-flex justify-content-between">
                                  <p className="text-blue fz-16 fw-semibold mb-2">
                                    {val.recipe_name}
                                  </p>
                                </div>
                                <div className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Instructions:{" "}
                                  </span>
                                  {val.instructions}
                                </div>
                                <div className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Servings:{" "}
                                  </span>
                                  {val.servings}
                                </div>
                                <div className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Notes:{" "}
                                  </span>
                                  {val.notes}
                                </div>
                                <div className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Video Link:{" "}
                                  </span>
                                  {val.video_link}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>







                  </div>

                </div>
              )}
              {featuresList.includes("mystory") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-2"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-2"
                        aria-expanded="false"
                        aria-controls="collapse-2"
                      >
                        Stories
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-2"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-2"
                  >
                    <div className="card-body">
                      <div className="row mx-auto">
                        {description &&
                          description.length > 0 &&
                          description.map((val, index) => {
                            return (
                              <div className="col-md-12 property-card mb-4">
                                <div className="d-flex justify-content-between">
                                  <p className="text-blue fz-16 fw-semibold mb-2">
                                    {val.title}
                                  </p>
                                </div>
                                <p className="text-dark-grey fz-15 mb-1" style={{ whiteSpace: 'pre-line' }}>

                                  <span className="mr-1 fz-14 fw-bold">
                                    Description:{" "}
                                  </span>
                                  {val.input}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {featuresList.includes("funeralWishes") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-3"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-3"
                        aria-expanded="false"
                        aria-controls="collapse-3"
                      >
                        Funeral Wishes
                      </a>
                    </h5>
                  </div>

                  <div
                    id="collapse-3"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-3"
                  >
                    <h5 className="ml-4 mt-4"> Plot / Burial / Cremation</h5>

                    <div className="card-body">
                      <div className="row mx-auto">
                        {cremationsList &&
                          cremationsList.length > 0 &&
                          cremationsList.map((val, index) => {
                            return (
                              <div className="col-md-12 property-card mb-4">
                                <div className="d-flex justify-content-between">
                                  <p className="text-blue fz-16 fw-semibold mb-2">
                                    {val.cemetery_name}
                                  </p>
                                </div>
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Burial Site:{" "}
                                  </span>
                                  {val.burial_site}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Description:{" "}
                                  </span>
                                  {val.description}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <h5 className="ml-4"> Passages & Readings</h5>

                    <div className="card-body">
                      <div className="row mx-auto">
                        {readingList &&
                          readingList.length > 0 &&
                          readingList.map((val, index) => {
                            return (
                              <div className="col-md-12 property-card mb-4">
                                <div className="d-flex justify-content-between">
                                  <p className="text-blue fz-16 fw-semibold mb-1">
                                    {val.book}
                                  </p>
                                </div>
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Verse:{" "}
                                  </span>
                                  {val.verse}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Content:{" "}
                                  </span>
                                  {val.content}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Reflection:{" "}
                                  </span>
                                  {val.reflection}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <h5 className="ml-4"> Hymns and Songs</h5>

                    <div className="card-body">
                      <div className="row mx-auto">
                        {songsList &&
                          songsList.length > 0 &&
                          songsList.map((val, index) => {
                            return (
                              <div className="col-md-3 col-sm-12 property-card mb-4 mr-4">
                                <div className="d-flex justify-content-between">
                                  <p className="text-blue fz-16 fw-semibold mb-1">
                                    {val.title}
                                  </p>
                                </div>
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Link:{" "}
                                  </span>
                                  <span
                                    className="mr-1 fz-14 fw-bold text-decoration-underline cursor-pointer"
                                    onClick={() => {
                                      window.open(`${val.link}`, "_blank");
                                    }}
                                  >
                                    {val.link}
                                  </span>
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>

                    <h5 className="ml-4"> Special Instructions</h5>

                    <div className="card-body">
                      <div className="row mx-auto">
                        {instructionList &&
                          instructionList.length > 0 &&
                          instructionList.map((val, index) => {
                            return (
                              <div className="col-md-12 property-card mb-4">
                                <div className="d-flex justify-content-between">
                                  <p className="text-blue fz-16 fw-semibold mb-1">
                                    {val.title}
                                  </p>
                                </div>
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Description:{" "}
                                  </span>
                                  {val.description}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Assignee:{" "}
                                  </span>
                                  {val.assignee}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("legals") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-4"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-4"
                        aria-expanded="false"
                        aria-controls="collapse-4"
                      >
                        Legals
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-4"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-4"
                  >
                    <div className="card-body">
                      <div className="row mx-auto">
                        {documentList &&
                          documentList.length > 0 &&
                          documentList.map((val, index) => {
                            return (
                              <div className="col-md-12 property-card mb-4">
                                <p className="text-dark-grey fz-15 mb-1">
                                <div className="imageThumb p-2">
                                  <img
                                    src={ val.file_type === 'image/png'? PNGIcon: val.file_type === 'image/jpeg'? JPGIcon:  PdfIcon }
                                    className="image-legal-document imageThumb p-2"
                                    alt="style-url"
                                    onClick={() => {
                                      window.open(`${val.document}`, "_blank");
                                    }}
                                  />
                                </div>
                                  <span className="mr-1 fz-14 fw-bold">
                                    Document:{" "}
                                  </span>
                                  <span
                                    className="mr-1 fz-14 fw-bold text-decoration-underline cursor-pointer"
                                    onClick={() => {
                                      window.open(`${val.document}`, "_blank");
                                    }}
                                  >
                                    {val.filename}
                                  </span>
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Name:{" "}
                                  </span>
                                  {val.name}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Description:{" "}
                                  </span>
                                  {val.description}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Share with:{" "}
                                  </span>
                                  {val.deputy && val.deputy.first_name}{" "}
                                  {val.deputy && val.deputy.last_name}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Reminder in:{" "}
                                  </span>
                                  {val.reminder_in}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("digitalInfo") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-5"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-5"
                        aria-expanded="false"
                        aria-controls="collapse-5"
                      >
                        Digital Information
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-5"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-5"
                  >
                    <div className="card-body">
                      <h6 className="mb-0">No records found</h6>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("homeAndProperty") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-6"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-6"
                        aria-expanded="false"
                        aria-controls="collapse-6"
                      >
                        Home And Property
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-6"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-6"
                  >
                    <div className="card-body">
                      <h5 className="ml-4"> Real Estate</h5>

                      <div className="card-body">
                        <div className="row mx-auto">
                          {realEstate &&
                            realEstate.length > 0 &&
                            realEstate.map((val, index) => {
                              return (
                                <div className="col-md-12 property-card mb-4">
                                  <div className="d-flex justify-content-between">
                                    <p className="text-blue fz-16 fw-semibold mb-1">
                                      {val.book}
                                    </p>
                                  </div>
                                  <p className="text-dark-grey fz-15 mb-1">
                                    <span className="mr-1 fz-14 fw-bold">
                                      Owner name:{" "}
                                    </span>
                                    {val.owner_name}
                                  </p>
                                  <p className="text-dark-grey fz-15 mb-1">
                                    {" "}
                                    <span className="mr-1 fz-14 fw-bold">
                                      Property name:{" "}
                                    </span>
                                    {val.property_name}
                                  </p>
                                  <p className="text-dark-grey fz-15 mb-1">
                                    {" "}
                                    <span className="mr-1 fz-14 fw-bold">
                                      Reflection:{" "}
                                    </span>
                                    {val.address}
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      <h5 className="ml-4"> Vehicles</h5>

                      <div className="card-body">
                        <div className="row mx-auto">
                          {vehicles &&
                            vehicles.length > 0 &&
                            vehicles.map((val, index) => {
                              return (
                                <div className="col-md-12 property-card mb-4">
                                  <p className="text-dark-grey fz-15 mb-1">
                                    {" "}
                                    <span className="mr-1 fz-14 fw-bold">
                                      Vehicle type:{" "}
                                    </span>
                                    {val.vehicle_type}
                                  </p>

                                  <p className="text-dark-grey fz-15 mb-1">
                                    {" "}
                                    <span className="mr-1 fz-14 fw-bold">
                                      State:{" "}
                                    </span>
                                    {val.state}
                                  </p>
                                  <p className="text-dark-grey fz-15 mb-1">
                                    {" "}
                                    <span className="mr-1 fz-14 fw-bold">
                                      Registration number:{" "}
                                    </span>
                                    {val.registration_number}
                                  </p>
                                  <p className="text-dark-grey fz-15 mb-1">
                                    {" "}
                                    <span className="mr-1 fz-14 fw-bold">
                                      Estimated value:{" "}
                                    </span>
                                    {val.estimated_value}
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                      </div>

                      <h5 className="ml-4"> Other Assets</h5>

                      <div className="card-body">
                        <div className="row mx-auto">
                          {otherAssets &&
                            otherAssets.length > 0 &&
                            otherAssets.map((val, index) => {
                              return (
                                <div className="col-md-12 property-card mb-4">
                                  <p className="text-dark-grey fz-15 mb-1">
                                    {" "}
                                    <span className="mr-1 fz-14 fw-bold">
                                      Description:{" "}
                                    </span>
                                    {val.description}
                                  </p>

                                  <p className="text-dark-grey fz-15 mb-1">
                                    {" "}
                                    <span className="mr-1 fz-14 fw-bold">
                                      location:{" "}
                                    </span>
                                    {val.location}
                                  </p>
                                  <p className="text-dark-grey fz-15 mb-1">
                                    {" "}
                                    <span className="mr-1 fz-14 fw-bold">
                                      Estimated value:{" "}
                                    </span>
                                    {val.estimated_value}
                                  </p>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("timeline") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-7"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-7"
                        aria-expanded="false"
                        aria-controls="collapse-7"
                      >
                        Timeline
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-7"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-7"
                  >
                    <div className="row mx-0 timeline-box common-mb">
                      <div className="w-100">
                        {timeline && timeline.length > 0 && (
                          <div>
                            <Chrono
                              mode="VERTICAL_ALTERNATING"
                              items={
                                itemsValues &&
                                itemsValues.length > 0 &&
                                itemsValues.map((item) => {
                                  return { title: item.date };
                                })
                              }
                              hideControls={true}
                              cardHeight={150}
                              cardWidth={350}
                              enableOutline={true}
                              theme={{
                                primary: "black",
                                secondary: "#10b9e4",
                              }}
                            >
                              {timeline &&
                                timeline.map((item) => {
                                  return item;
                                })}
                            </Chrono>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("familytree") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-8"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-8"
                        aria-expanded="false"
                        aria-controls="collapse-8"
                      >
                        Family tree
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-8"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-8"
                  >
                    <div className="card-body">
                      <h6 className="mb-0">No records found</h6>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("messages") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-9"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-9"
                        aria-expanded="false"
                        aria-controls="collapse-9"
                      >
                        Messages to Loved Ones
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-9"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-9"
                  >
                    <div className="card-body">
                      <div className="row mx-auto">
                        {messages &&
                          messages.length > 0 &&
                          messages.map((val, index) => {
                            return (
                              <div className="col-md-12 property-card mb-4">
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Sent to:{" "}
                                  </span>
                                  {val.to}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Message:{" "}
                                  </span>
                                  {val.body}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Email:{" "}
                                  </span>
                                  {val.email}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Phone number:{" "}
                                  </span>
                                  {val.phone_number}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("financials") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-10"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-10"
                        aria-expanded="false"
                        aria-controls="collapse-10"
                      >
                        Financials
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-10"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-10"
                  >
                    <div className="card-body">
                      <div className="row mx-auto">
                        {financials &&
                          financials.length > 0 &&
                          financials.map((val, index) => {
                            return (
                              <div className="col-md-12 property-card mb-4">
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Bank name:{" "}
                                  </span>
                                  {val.bank_name}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Account number:{" "}
                                  </span>
                                  {val.account_number}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("deputy") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-11"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-11"
                        aria-expanded="false"
                        aria-controls="collapse-11"
                      >
                        Deputies
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-11"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-11"
                  >
                    <div className="card-body">
                      <div className="row mx-auto">
                        {myDeputies &&
                          myDeputies.length > 0 &&
                          myDeputies.map((val, index) => {
                            return (
                              <div className="col-md-12 property-card mb-4">
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">
                                    Name:{" "}
                                  </span>
                                  {val.first_name} {val.last_name}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Email:{" "}
                                  </span>
                                  {val.email}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {" "}
                                  <span className="mr-1 fz-14 fw-bold">
                                    Role:{" "}
                                  </span>
                                  {val.role}
                                </p>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("insurances") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-13"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-13"
                        aria-expanded="false"
                        aria-controls="collapse-13"
                      >
                        Insurances
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-13"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-13"
                  >
                    <div className="card-body">
                      <div className="row mx-auto">
                      {insurances &&
                        insurances.length > 0 &&
                        insurances.map((val, index) => {
                          return (
                            <div className="col-md-12 property-card mb-4">
                              <p className="text-dark-grey fz-15 mb-1">
                                <span className="mr-1 fz-14 fw-bold">
                                  Policy number:{" "}
                                </span>
                                {val.policy_number}
                              </p>
                              <p className="text-dark-grey fz-15 mb-1">
                                {" "}
                                <span className="mr-1 fz-14 fw-bold">
                                  Beneficiaries:{" "}
                                </span>
                                {val.beneficiaries}
                              </p>
                              <p className="text-dark-grey fz-15 mb-1">
                                {" "}
                                <span className="mr-1 fz-14 fw-bold">
                                  Value:{" "}
                                </span>
                                {val.value}
                              </p>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {featuresList.includes("images") && (
                <div className="card mb-4">
                  <div
                    className="card-header d-flex align-items-center justify-content-between"
                    id="heading-12"
                  >
                    <h5 className="flex-1 mb-0">
                      <a
                        className="collapsed"
                        role="button"
                        data-toggle="collapse"
                        href="#collapse-12"
                        aria-expanded="false"
                        aria-controls="collapse-12"
                      >
                        Images
                      </a>
                    </h5>
                  </div>
                  <div
                    id="collapse-12"
                    className="collapse"
                    data-parent="#accordion"
                    aria-labelledby="heading-12"
                  >
                    <div className="card-body">
                      <div className="row">
                        {images &&
                          images.length > 0 &&
                          images.map((val) => {
                            return (
                              <div className="col-md-3 property-card mb-4 mr-4">
                                <div className="d-flex flex-column align-items-center justify-content-center ">
                                  <img
                                    src={val.file}
                                    className="img-fluid image-deputyview"
                                    alt="style-file"
                                  />

                                  <div className="imageName px-3 py-2 break-text image-deputyview-name">
                                    {val.filename}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default DeputyView;
