import React from "react";
import {onLogout} from "../../Utility/helper";

const LogoutItem = (props) => {

    return (
        <>
            <li>
                <a href="/#" className="logout-action" onClick={onLogout}>
                      <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                        >
                          <g
                              id="Group_10"
                              data-name="Group 10"
                              transform="translate(-860 -2604)"
                          >
                            <path
                                id="Icon_awesome-power-off"
                                data-name="Icon awesome-power-off"
                                d="M13.01,1.718a7.874,7.874,0,1,1-9.15,0,.764.764,0,0,1,1.109.245l.5.892a.762.762,0,0,1-.21.984,5.334,5.334,0,1,0,6.348,0,.758.758,0,0,1-.206-.982l.5-.892a.761.761,0,0,1,1.109-.248Zm-3.3,6.665V.763A.761.761,0,0,0,8.945,0H7.928a.761.761,0,0,0-.763.763v7.62a.761.761,0,0,0,.763.763H8.945a.761.761,0,0,0,.763-.763Z"
                                transform="translate(862.44 2607)"
                                fill="#747474"
                            />
                            <rect
                                id="Rectangle_13"
                                data-name="Rectangle 13"
                                width="22"
                                height="22"
                                transform="translate(860 2604)"
                                fill="none"
                            />
                          </g>
                        </svg>
                      </span>
                    Logout
                </a>
            </li>
        </>
    );
};

export default LogoutItem;
