import React, { useState, useEffect, useRef } from "react";
import Logo from "../Assets/images/logo_blackNwhite.png";
import Profile from "../Assets/images/profile.svg";
import Menu from "../Assets/images/menu.svg";
import { Link } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { onLogout, sessionTimer } from "../Utility/helper";
import { getAccount } from "../Utility/Services/account";
import MenuList from "./MenuItems/MenuList";

const Header = (props) => {
  const [search, setSearch] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const tempTimer = useRef();
  const [account, setAccount] = useState(null);
  const user_id = localStorage.getItem("userID");

  const timerFunc = () => {
    sessionTimer();
    fetchAccount();
  };

  tempTimer.current = timerFunc;

  useEffect(() => {
    tempTimer.current();
  }, []);

  const fetchAccount = () => {
    getAccount(user_id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setFirstName(res.data.data.attributes.first_name);
        setLastName(res.data.data.attributes.last_name);
        setAccount(res.data.data.attributes.account);
        setIsAdmin(res.data.data.attributes.account_admin);
      }
    });
  };

  const handleChange = (event) => {
    setSearch(event.target.value);
  };

  return (
    <React.Fragment>
      <div className="bg-light-blue d-flex justify-content-between align-items-center header-wrapper">
        <div className="w-100 d-flex align-items-center justify-content-between header-bar">
          <div>
            <Link to={`${path_url.personalInformation}/${user_id}`}>
              <img
                className="header-logo img-fluid"
                alt="ed-header-logo"
                src={Logo}
              />
            </Link>
          </div>
          <div className="d-flex justify-content-between align-items-center ">
            <div className="col header-search">
              <i className="fas fa-search search-icon" />
              <input
                className="search-input"
                type="text"
                value={search}
                onChange={handleChange}
              />
            </div>
            <div className="dropdown">
              <button
                className="dropdown-toggle border-0 outline-0 bg-transparent"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="cursor-pointer profile-logo img-fluid"
                  alt="profile-logo"
                  src={Profile}
                />
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <span className="text-light-blue ml-4 fw-bold">
                  Hi, {firstName} {lastName}
                </span>
                <hr />
                {isAdmin && (
                  <Link
                    to={path_url.corporateDashboard}
                    className="dropdown-item menu-icon mb-2 fw-semibold d-flex align-items-center"
                  >
                    <i className="fas fa-building mr-3 text-blue fa-lg" />
                    <span>Corporate Dashboard</span>
                  </Link>
                )}
                <Link to={path_url.account_management}>
                  <a
                    className="dropdown-item menu-icon mb-2 fw-semibold d-flex align-items-center"
                    href="#"
                  >
                    <i className="fas fa-user-cog mr-3 text-blue fa-lg" />
                    <span>Account</span>
                  </a>
                </Link>
                {/*<Link
                  to={path_url.payment}
                  className="dropdown-item menu-icon mb-2 fw-semibold d-flex align-items-center"
                  >
                  <i className="far fa-credit-card mr-3 text-blue fa-lg" />
                  <span>Payment</span>
                  </Link>*/}
                <Link
                  to={path_url.profile}
                  className="dropdown-item menu-icon mb-2 fw-semibold d-flex align-items-center"
                >
                  <i className="fas fa-user-circle mr-3 pr-1 text-blue fa-lg" />
                  <span>Profile</span>
                </Link>
                <a
                  onClick={onLogout}
                  className="dropdown-item menu-icon mb-2 fw-semibold d-flex align-items-center"
                  href="/#"
                >
                  <i className="fas fa-power-off mr-3 pr-1 text-blue fa-lg" />
                  <span>Logout</span>
                </a>
              </div>
            </div>
            <div className="dropdown mobile_menu ml-3">
              <button
                className="dropdown-toggle border-0 outline-0 bg-transparent"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="cursor-pointer menu-logo img-fluid"
                  alt="menu"
                  src={Menu}
                />
              </button>
              <div className="dropdown-menu mobile_menu-box default-menu"
                   aria-labelledby="dropdownMenuButton" >
                <MenuList showLogout={true}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Header;
