import { cloneDeep } from "lodash";
import { path_url } from "./constant";

export const getToken = () => {
  return localStorage.getItem("auth_token");
};

export const isEmpty = (value) => {
  return (
    (typeof value == "string" && !value.trim()) ||
    typeof value == "undefined" ||
    value === null
  );
};

export const syncLogin = (obj) => {
  localStorage.setItem("sessionStart", new Date());
  localStorage.setItem("auth_token", obj.meta.token);
  localStorage.setItem("userID", obj.data.id);
  localStorage.setItem("accountID", obj.data.attributes.account && obj.data.attributes.account.id);
  localStorage.setItem("isAdmin", obj.data.attributes.account_admin);
  localStorage.setItem("sessionStart", new Date());
  localStorage.setItem("is_valid_payment", obj.meta.is_valid_payment);
};

export const onLogout = () => {
  localStorage.removeItem("auth_token");
  localStorage.removeItem("userID");
  localStorage.removeItem("accountAdmin");
  localStorage.removeItem("accountID");
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("sessionStart");
  localStorage.removeItem("is_valid_payment");

  if (window.location.hostname.includes("localhost")) {
    window.location.href = `${window.location.protocol}//localhost:${window.location.port}${path_url.login}`;
  } else if (window.location.hostname.includes("staging")) {
    window.location.href = `${window.location.protocol}//staging.enduringwishes.com${path_url.login}`;
  }else{
    window.location.href = `${window.location.protocol}//app.enduringwishes.com${path_url.login}`;
  }
};

// get regexp by type
export const getRegExp = (type) => {
  let regx = null;
  switch (type) {
    case "email":
      regx =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      break;
    case "mobile":
      regx = /^((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}$/;
      break;
    case "number":
      regx = /^[0-9]*$/;
      break;
    case "password":
      regx = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      break;
    case "onlyAlphbate":
      regx = /^[a-zA-Z ]*$/;
      break;
    case "alphaNumeric":
      regx = /^([a-zA-Z0-9 _-]+)$/;
      break;
    case "expiryDate":
      regx = /(0[1-9]|10|11|12)\/[0-9]{2}|\./;
      break;
    case "cardNumber":
      regx = /[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{4} {0,1}[0-9]{1}/;
      break;
    default:
      break;
  }
  return regx;
};

export const getFormDetails = (form, changeValidation) => {
  let failed;
  for (let val in form.errors) {
    const fieldError = form.errors[val];
    if (fieldError) {
      failed = true;
    } else if (fieldError === null && !form[val]) {
      failed = true;
      changeValidation(val, true);
    }
  }
  if (failed) {
    return false;
  } else {
    const cloneObj = cloneDeep(form);
    delete cloneObj["errors"];
    return cloneObj;
  }
};

export const accountTypeOptions = [
  { value: "individual", label: "Individual" },
  { value: "corporate", label: "Corporate" },
];

export const individualOptions = [
  { value: "Free", label: "Free" },
  { value: "Premium", label: "Premium" },
  { value: "Senior", label: "Senior" },
  { value: "Trial", label: "Trial" },
];

export const corporateOptions = [
  { value: "Basic", label: "Basic" },
  { value: "Multimedia", label: "Multimedia" },
  { value: "Trial", label: "Trial" },
];

function timeDifference(date1, date2) {
  var difference = date1.getTime() - date2.getTime();

  var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
  difference -= daysDifference * 1000 * 60 * 60 * 24;

  var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
  difference -= hoursDifference * 1000 * 60 * 60;

  var minutesDifference = Math.floor(difference / 1000 / 60);
  difference -= minutesDifference * 1000 * 60;

  var secondsDifference = Math.floor(difference / 1000);

  return {
    daysDifference,
    hoursDifference,
    minutesDifference,
    secondsDifference,
  };
}

export const sessionTimer = () => {
  let sessionStart = localStorage.getItem("sessionStart").toString();
  let sessionTime = new Date(sessionStart);
  let time = new Date();

  const { daysDifference } = timeDifference(
    time,
    sessionTime
  );

  if (daysDifference > 1) {
    alert("Your session has expired!");
    onLogout();
  } else {
    const timer = setInterval(() => {
      let sessionTime = new Date(sessionStart);
      let time = new Date();

      const {
        daysDifference,
      } = timeDifference(time, sessionTime);

      if (daysDifference > 1) {
        alert("Your session has expired!");
        onLogout();
      }
      //console.log(minutesDifference);
    }, 10000);
    return () => clearTimeout(timer);
  }
};

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export const toFormData = (bodyObj) => {
  let formData = new FormData();
  Object.keys(bodyObj).map((key) => formData.append(key, bodyObj[key]));
  return formData
}
