import React, { useState, useEffect } from 'react';
import FuneralIcon from '../Assets/images/directive-blue.svg';
import PlusIcon from '../Assets/images/plus-circle.svg';
import { path_url } from '../Utility/constant';
import { ts } from '../Utility/ReduxToaster';
import Edit from '../Assets/images/edit.svg';
import Delete from '../Assets/images/delete.svg';
import {
  getCremations,
  getReadings,
  getSongs,
  getInstructions,
  delCremations,
  delReadings,
  delSongs,
  delInstructions
} from '../Utility/Services/funeralWishes';
import { useNavigate } from 'react-router-dom';

const FuneralWishes = (props) => {
  const navigate = useNavigate();
  const [ cremationsList, setCremationsList ] = useState([]);
  const [ readingList, setReadingList ] = useState([]);
  const [ songsList, setSongsList ] = useState([]);
  const [ instructionList, setInstructionsList ] = useState([]);

  useEffect(() => {
    fetchCremationData();
    fetchReadingData();
    fetchSongsData();
    fetchInstructionData();
  }, []);

  const fetchCremationData = () => {
    getCremations().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setCremationsList(res.data);
      }
    });
  };

  const fetchReadingData = () => {
    getReadings().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setReadingList(res.data);
      }
    });
  };

  const fetchSongsData = () => {
    getSongs().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setSongsList(res.data);
      }
    });
  };

  const fetchInstructionData = () => {
    getInstructions().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setInstructionsList(res.data);
      }
    });
  };

  const addEditCemetery = (val, type) => {
    if (type === 'Edit') {
      navigate(path_url.add_edit_cemetary, {
        state: { cemetery_id: val.id, data: val }
      });
    } else {
      navigate(path_url.add_edit_cemetary);
    }
  };

  const addEditReadings = (val, type) => {
    if (type === 'Edit') {
      navigate(path_url.add_edit_readings, {
        state: { reading_id: val.id, data: val }
      });
    } else {
      navigate(path_url.add_edit_readings);
    }
  };

  const addEditSongs = (val, type) => {
    if (type === 'Edit') {
      navigate(path_url.add_edit_songs, {
        pathname: path_url.add_edit_songs,
        state: { song_id: val.id, data: val }
      });
    } else {
      navigate(path_url.add_edit_songs);
    }
  };

  const addEditInstructions = (val, type) => {
    if (type === 'Edit') {
      navigate(path_url.add_edit_instructions, {
        state: { instruction_id: val.id, data: val }
      });
    } else {
      navigate(path_url.add_edit_instructions);
    }
  };

  const onDeleteCremations = (val) => {
    delCremations(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchCremationData();
        ts('Cremation deleted successfully');
      }
    });
  };

  const onDeleteReadings = (val) => {
    delReadings(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchReadingData();
        ts('Book deleted successfully');
      }
    });
  };

  const onDeleteSongs = (val) => {
    delSongs(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchSongsData();
        ts('Song deleted successfully');
      }
    });
  };

  const onDeleteInstructions = (val) => {
    delInstructions(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchInstructionData();
        ts('Instructions deleted successfully');
      }
    });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section personal-info">
      <h2 className="fw-bold text-black content-title text-center">
          <img className="img-fluid mr-4" src={FuneralIcon} alt="uneral-icon" style={{padding: '7px'}} />
            Your Directives
          <hr className="mb-0" />
        </h2>

        <div className="prompts">
          <strong>Leave Your Last Words of Love and Guidance</strong> 
          <p>Take a moment to leave special directives and heartfelt instructions for your loved ones. </p>
        </div>

        <div className="row mx-0">
          <div className="flex flex-column mb-5 mt-1 w-100">
            <div id="accordion">
              <div className="card mb-4">
                <div
                  className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                  id="heading-1"
                >
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-1"
                      aria-expanded="false"
                      aria-controls="collapse-1"
                    >
                      Plot / Burial / Cremation
                    </a>
                  </h5>
                  <button
                    onClick={() => addEditCemetery('', 'New')}
                    className="share-btn d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
                  >
                    <img src={PlusIcon} className="img-fluid mr-2 h-20" alt='plus-icon' /> Add{' '}
                  </button>
                </div>
                <div id="collapse-1" className="collapse" data-parent="#accordion" aria-labelledby="heading-1">
                  <div className="card-body">
                    <div className="row mx-auto mb-2">
                      {cremationsList &&
                        cremationsList.length > 0 &&
                        cremationsList.map((val, index) => {
                          return (
                            <div className="col-md-12 col-lg-12">
                              <div className='property-card min-h-185'>
                                <div className="d-flex justify-content-between">
                                  <p className="text-blue fz-16 fw-semibold mb-2">{val.cemetery_name}</p>
                                  <div>
                                    <span onClick={() => addEditCemetery(val, 'Edit')}>
                                      <img className="mr-3 cursor-pointer" src={Edit} alt="editicon" />
                                    </span>{' '}
                                    <span onClick={() => onDeleteCremations(val)}>
                                      <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon" />
                                    </span>{' '}
                                  </div>
                                </div>
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">Burial Site: </span>
                                  {val.burial_site}
                                </p>
                                <p className="text-dark-grey fz-15 mb-1">
                                  {' '}
                                  <span className="mr-1 fz-14 fw-bold">Description: </span>
                                  {val.description}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      {cremationsList &&
                      cremationsList.data &&
                      cremationsList.data.length === 0 && (
                        <div>
                          <h6 className="mb-0">No records found</h6>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-4">
                <div
                  className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                  id="heading-2"
                >
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-2"
                      aria-expanded="false"
                      aria-controls="collapse-2"
                    >
                      Passages & Readings
                    </a>
                  </h5>
                  <button
                    onClick={() => addEditReadings('', 'New')}
                    className="share-btn d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
                  >
                    <img src={PlusIcon} className="img-fluid mr-2 h-20" alt='plus-icon' /> Add{' '}
                  </button>
                </div>
                <div id="collapse-2" className="collapse" data-parent="#accordion" aria-labelledby="heading-2">
                  <div className="card-body">
                    <div className="row mx-auto mb-2">
                      {readingList &&
                        readingList.length > 0 &&
                        readingList.map((val, index) => {
                          return (
                            <div className="col-md-12 col-lg-12">
                              <div className='property-card'>
                              <div className="d-flex justify-content-between">
                                <p className="text-blue fz-16  fw-semibold mb-1">{val.book}</p>
                                <div>
                                  <span onClick={() => addEditReadings(val, 'Edit')}>
                                    <img className="mr-3 cursor-pointer" src={Edit} alt="edit-icon" />
                                  </span>{' '}
                                  <span onClick={() => onDeleteReadings(val)}>
                                    <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon" />
                                  </span>{' '}
                                </div>
                              </div>
                              <p className="text-dark-grey fz-15 mb-1">
                                <span className="mr-1 fz-14 fw-bold">Verse: </span>
                                {val.verse}
                              </p>
                              <p className="text-dark-grey fz-15 mb-1">
                                {' '}
                                <span className="mr-1 fz-14 fw-bold">Content: </span>
                                {val.content}
                              </p>
                              <p className="text-dark-grey fz-15 mb-1">
                                {' '}
                                <span className="mr-1 fz-14 fw-bold">Reflection: </span>
                                {val.reflection}
                              </p>
                              </div>
                            </div>
                          );
                        })}
                      {readingList &&
                      readingList.data &&
                      readingList.data.length === 0 && (
                        <div>
                          <h6 className="mb-0">No records found</h6>
                        </div>
                      )}
                    </div>
                  </div>{' '}
                </div>
              </div>
              <div className="card mb-4">
                <div
                  className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                  id="heading-3"
                >
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-3"
                      aria-expanded="false"
                      aria-controls="collapse-3"
                    >
                      Hymns and Songs
                    </a>
                  </h5>
                  <button
                    onClick={() => addEditSongs('', 'New')}
                    className="share-btn d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
                  >
                    <img src={PlusIcon} className="img-fluid mr-2 h-20" alt='plus-icon' /> Add{' '}
                  </button>
                </div>
                <div id="collapse-3" className="collapse" data-parent="#accordion" aria-labelledby="heading-3">
                  <div className="card-body">
                    <div className="row mx-auto mb-2">
                      {songsList &&
                        songsList.length > 0 &&
                        songsList.map((val, index) => {
                          return (
                            <div className="col-md-12 col-lg-12">
                              <div className='property-card'>
                                <div className="d-flex justify-content-between">
                                  <p className="text-blue fz-16 fw-semibold mb-1">{val.title}</p>
                                  <div>
                                    <span onClick={() => addEditSongs(val, 'Edit')}>
                                      <img className="mr-3 cursor-pointer" src={Edit} alt="edit-icon" />
                                    </span>{' '}
                                    <span onClick={() => onDeleteSongs(val)}>
                                      <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon" />
                                    </span>{' '}
                                  </div>
                                </div>
                                <p className="text-dark-grey fz-15 mb-1">
                                  <span className="mr-1 fz-14 fw-bold">Link: </span>
                                  <span className="mr-1 fz-14 fw-bold text-decoration-underline cursor-pointer" onClick={()=>{window.open(`${val.link}`,"_blank")}}>{val.link}</span>
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      {songsList &&
                      songsList.data &&
                      songsList.data.length === 0 && (
                        <div>
                          <h6 className="mb-0">No records found</h6>
                        </div>
                      )}
                    </div>
                  </div>{' '}
                  {' '}
                </div>
              </div>
              <div className="card mb-4">
                <div
                  className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                  id="heading-4"
                >
                  <h5 className="flex-1 mb-0">
                    <a
                      className="collapsed"
                      role="button"
                      data-toggle="collapse"
                      href="#collapse-4"
                      aria-expanded="false"
                      aria-controls="collapse-4"
                    >
                      Special Instructions
                    </a>
                  </h5>
                  <button
                    onClick={() => addEditInstructions('', 'New')}
                    className="share-btn d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
                  >
                    <img src={PlusIcon} className="img-fluid mr-2 h-20" alt='plus-icon' /> Add{' '}
                  </button>
                </div>
                <div id="collapse-4" className="collapse" data-parent="#accordion" aria-labelledby="heading-4">
                  <div className="card-body">
                    <div className="row mx-auto mb-2">
                      {instructionList &&
                        instructionList.length > 0 &&
                        instructionList.map((val, index) => {
                          return (
                            <div className="col-md-12 col-lg-12">
                              <div className='property-card'>
                              <div className="d-flex justify-content-between">
                                <p className="text-blue fz-16 fw-semibold mb-1">{val.title}</p>
                                <div>
                                  <span onClick={() => addEditInstructions(val, 'Edit')}>
                                    <img className="mr-3 cursor-pointer" src={Edit} alt="edit-icon" />
                                  </span>{' '}
                                  <span onClick={() => onDeleteInstructions(val)}>
                                    <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon" />
                                  </span>{' '}
                                </div>
                              </div>
                              <p className="text-dark-grey fz-15 mb-1">
                                <span className="mr-1 fz-14 fw-bold">Description: </span>
                                {val.description}
                              </p>
                              <p className="text-dark-grey fz-15 mb-1">
                                <span className="mr-1 fz-14 fw-bold">Assignee: </span>
                                {val.assignee}
                              </p>
                            </div>
                            </div>
                          );
                        })}
                      {instructionList &&
                      instructionList.data &&
                      instructionList.data.length === 0 && (
                        <div>
                          <h6 className="mb-0">No records found</h6>
                        </div>
                      )}
                    </div>
                  </div>{' '}
                  {' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default FuneralWishes;
