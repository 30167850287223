import React from "react";
import MenuList from "./MenuItems/MenuList";

const SideMenu = (props) => {


  return (
      <React.Fragment>
        <div className="sidemenu-wrapper fw-semibold d-flex justify-content-between flex-column">
          <div className="mb-70">
              <div className="default-menu">
                <MenuList/>
              </div>
          </div>
        </div>
      </React.Fragment>
  );
};

export default SideMenu;
