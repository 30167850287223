import React, { useState, useEffect, useRef } from "react";
import { Input } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import FinancialIcon from "../Assets/images/financials-blue.svg";
import { createFinancial, editFinancial } from "../Utility/Services/financial";

const initForm = {
  bankName: "",
  accountNumber: "",
  loginName: "",
  password: "",
  errors: {
    bankName: null,
    accountNumber: null,
    loginName: null,
    password: null,
  },
};

const AddEditFinancial = (props) => {
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState("");
  const tempForm = useRef();

  let { form } = state;
  let location = useLocation();
  const navigate = useNavigate();
  let { errors, bankName, accountNumber, loginName, password } = form;

  const formFun = () => {
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);

  const formUpdate = () => {
    let index = location.state && location.state.userid;
    let financialData =
      location &&
      location.state &&
      location.state.data.filter((val, i) => i === index);
    form.bankName =
      financialData && financialData[0] && financialData[0].bank_name;
    form.accountNumber =
      financialData && financialData[0] && financialData[0].account_number;
    form.loginName =
      financialData && financialData[0] && financialData[0].login_name;
    form.password =
      financialData && financialData[0] && financialData[0].password;
    setUserId(financialData && financialData[0] && financialData[0].id);
    setState({ ...state, form });
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onAddFinance = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      createFinancial(bankName, accountNumber, loginName, password).then(
        (res) => {
          if (res && res.error) {
            te(res.message);
            setLoader(false);
            return;
          }
          if (res && !res.error) {
            ts("Financial created successfully");
            setLoader(false);
            navigate(path_url.financial);
          }
        }
      );
    } else {
      return false;
    }
  };

  const onEditFinance = (finance_id) => {
    const { form } = state;
    let index = location.state && location.state.userid;
    let financialData =
      location &&
      location.state &&
      location.state.data.filter((val, i) => i === index);
      debugger
    let CheckForm = Object.keys(form).filter((res) => {
      if (res !== "errors" && res !== "password" && !form[res]) return res;
    });
    if (CheckForm.length === 0) {
      setLoader(true);
      editFinancial(
        financialData[0].id,
        bankName,
        accountNumber,
        loginName,
        password
      ).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Financial updated successfully");
          setLoader(false);
          navigate(path_url.financial);
        }
      });
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={FinancialIcon}
            alt="financial-icon"
          />
          {location.state &&
          location.state.data &&
          location.state.data.length > 0
            ? "Update Finance"
            : "Add Finance"}
          <hr className="mb-0" />
        </h2>
        <div className="common-mt add-edit-modal">
          <div className="row mb-3 mx-auto">
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Bank Name
              </label>
              <Input
                name="bankName"
                value={bankName}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Bank Name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter bank name"
                error={errors.bankName}
              />
            </div>
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Account Number
              </label>
              <Input
                name="accountNumber"
                value={accountNumber}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Account Number"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter account number"
                error={errors.accountNumber}
              />
            </div>
          </div>

          <div className="row mb-3 mx-auto">
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Login Name
              </label>
              <Input
                name="loginName"
                value={loginName}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Login Name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter login name"
                error={errors.loginName}
              />
            </div>
            {!userId && (
              <div className="col-lg-6">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">
                  Password
                </label>
                <Input
                  name="password"
                  value={password}
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  title="Password"
                  type="password"
                  isReq={true}
                  validationFunc={onInputValidate}
                  onChangeFunc={(name, value, error) => {
                    onInputChange(name, value, error);
                  }}
                  placeholder="Enter password"
                  error={errors.password}
                />
              </div>
            )}
          </div>

          <div className="col-lg-12 mt-4 account-action">
            <Link to={path_url.financial}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>
            {location &&
              location.state &&
              location.state.data &&
              location.state.data.length > 0 && (
                <button
                  onClick={onEditFinance}
                  type="button"
                  className="btn btn-primary br-10 fz-16 fw-bold"
                >
                  {loader ? "Updating..." : "Update"}
                  {loader && (
                    <i
                      className="ml-2 fa-spin fa-lg fa fa-spinner"
                      aria-hidden="true"
                    />
                  )}
                </button>
              )}
            {location && !location.state && (
              <button
                onClick={onAddFinance}
                type="button"
                className="btn btn-primary br-10 fz-16 fw-bold"
              >
                {loader ? "Loading..." : "Add"}
                {loader && (
                  <i
                    className="ml-2 fa-spin fa-lg fa fa-spinner"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEditFinancial;
