import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { path_url, side_menu_icons } from "../../Utility/constant";
import ToolTip from "../../Common/Input/ToolTip";
import { listNominators } from "../../Utility/Services/deputy";

const NominatorItems = (props) => {
    const [nominators, setNominators] = useState([]);
    const [disable, setDisable] = useState(true);
    const [clicked, setClicked] = useState(false);
    const NominatorIcon = "personalInfo";
    const user_id = localStorage.getItem("userID");

    const fetchNominatorsData = () => {
        listNominators(user_id).then((res) => {
            if (res && res.error) {
                return;
            }

            if (res && res.data && !res.error) {
                setNominators(res.data);
                if(res.data && res.data.length > 0){
                    setDisable(false);
                }
            }
        });
    };

    useEffect(() => {
        fetchNominatorsData();
    }, []);

    const clickHandler = (e) => {
        if(disable){
            e.preventDefault()
        } else {
            setClicked((prevClicked) => !prevClicked);
        }
    };

    // TODO: Update Nominators list to utilize Item Component
    return (
        <React.Fragment>
            <li key="nominator">
                {<NavLink
                    to={disable ? "/" : path_url.nominators}
                    activeClassName="active"
                    key="deputy1"
                    onClick={clickHandler}
                    className="nominators"  >
                    {nominators && nominators.length > 0 ? (
                        <span className="sidemenu-icon d-flex w-100">
                            <img
                                alt=""
                                className="img-fluid mr-2"
                                src={side_menu_icons[NominatorIcon]}
                            />
                            <span>Deputy View</span>
                        </span>
                    ) : (
                    <span className="sidemenu-icon d-flex w-100">
                        <img
                            alt=""
                            className="img-fluid mr-2 opacity"
                            src={side_menu_icons[NominatorIcon]}
                        />
                        <ToolTip
                            text="Deputy View"
                            message="No one made you their deputy yet!"
                            className="ml-2 opacity "
                            style={{
                                pointerEvents: "none",
                            }}
                        />
                    </span>)}
                </NavLink>}
            </li>
            {clicked && (
                <li>
                    <ul className="mb-0 ml-3">
                        {nominators.length > 0 &&
                         nominators.map((nominator) => (
                            <NavLink
                                    to={`${path_url.deputy_view}/${nominator.id}`}
                                    key={`nominator-${nominator.id}`}
                                >
                                    <img
                                        alt=""
                                        src={side_menu_icons[NominatorIcon]}
                                        className="img-fluid mr-2"
                                    />
                                    <span>
                                        {nominator.first_name} {nominator.last_name}
                                    </span>
                            </NavLink>
                        ))}
                    </ul>
                </li>
            )}
        </React.Fragment>
    );
};

export default NominatorItems;
