import { get, post, put, del } from "../httpInterceptor";

export const getDeputy = (id) => {
  return get(`/deputies/${id}`, {}, true, false).then((res) => {
    return res;
  });
};

export const listDeputies = () => {
  return get(`/deputies`, true).then((res) => {
    return res;
  });
};

export const createDeputy = (firstName, lastName, email, role) => {
  return post(
    `/deputies?[deputy][first_name]=${firstName}&[deputy][last_name]=${lastName}&[deputy][email]=${email}&[deputy][role]=${role}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const editDeputy = (deputy_id, firstName, lastName, email, role) => {
  return put(
    `/deputies/${deputy_id}?[deputy][first_name]=${firstName}&[deputy][last_name]=${lastName}&[deputy][email]=${email}&[deputy][role]=${role}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const delDeputy = (id) => {
  return del(`/deputies/${id}`, {}, true, false).then((res) => {
    return res;
  });
};

export const sendInvite = (id) => {
  return get(`/deputies/${id}/send_invite`, {}, true, false).then((res) => {
    return res;
  });
};

export const updatePrivacySettings = (deputy_id, bodyObj) => {
  return put(`/deputies/${deputy_id}`, bodyObj, true, false).then((res) => {
    return res;
  });
};

export const listNominators = (id) => {
  return get(`/users/${id}/nominators`, true).then((res) => {
    return res;
  });
};
