import { get, post, put, del } from '../httpInterceptor';

export const getRecipe = (id) => {
  return get(`/recipes/${id}`, {}, true, false).then((res) => {
    return res;
  });
};

export const listRecipes = () => {
  return get(`/recipes`, true).then((res) => {
    return res;
  });
};

export const createRecipe = (
  userID,
  recipeName,
  servings,
  notes,
  instructions,
  ingredients,
  video_link
) => {
  return post(
    `/recipes?` +
      `[recipe][user_id]=${userID}&` +
      `[recipe][recipe_name]=${recipeName}&` +
      `[recipe][servings]=${servings}&` +
      `[recipe][notes]=${notes}&` +
      `[recipe][instructions]=${instructions}&` +
      `[recipe][ingredients]=${ingredients}&` +
      `[recipe][video_link]=${video_link}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};

export const editRecipe = (
  recipeID,
  recipeName,
  servings,
  notes,
  instructions,
  ingredients,
  video_link
) => {
  return put(
    `/recipes/${recipeID}?` +
      `[recipe][recipe_name]=${recipeName}&` +
      `[recipe][servings]=${servings}&` +
      `[recipe][notes]=${notes}&` +
      `[recipe][instructions]=${instructions}&` +
      `[recipe][ingredients]=${ingredients}&` +
      `[recipe][video_link]=${video_link}`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};


export const delRecipe = (id) => {
  return del(`/recipes/${id}`, {}, true, false).then((res) => {
    return res;
  });
};
