import React, { useState, useEffect, useRef } from "react";
import { Input } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import homeIcon from "../Assets/images/home-property-blue.svg";
import { editVehicles } from "../Utility/Services/assets";

const initForm = {
  type: "",
  registrationNumber: "",
  registeredState: "",
  value: "",
  errors: {
    type: null,
    registrationNumber: null,
    registeredState: null,
    value: null,
  },
};

const EditVehicle = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [loader, setLoader] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const tempForm = useRef();

  let { form } = state;
  let location = useLocation();
  let { errors, type, registrationNumber, registeredState, value } = form;

  const formFun = () => {
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);

  const formUpdate = () => {
    let vehicleData = location.state.data;
    form.type = vehicleData.vehicle_type;
    form.registrationNumber = vehicleData.registration_number;
    form.registeredState = vehicleData.state;
    form.value = vehicleData.estimated_value;
    setState({ ...state, form });
    setVehicleId(location.state.vehicle_id);
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onEditVehicle = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      editVehicles(
        vehicleId,
        type,
        registrationNumber,
        registeredState,
        value
      ).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Vehicle updated successfully");
          setLoader(false);
          navigate(path_url.home_property);
        }
      });
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={homeIcon}
            alt="home-icon"
          />
          Update Vehicles
          <hr className="mb-0" />
        </h2>
        <div className="common-mt add-edit-modal">
          <React.Fragment>
            <div className="row mb-2 mx-auto">
              <div className="col-lg-6">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">Type</label>
                <Input
                  name="type"
                  value={type}
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  title="Type"
                  isReq={true}
                  validationFunc={(name, error) => {
                    onInputValidate(name, error);
                  }}
                  onChangeFunc={(name, value, error) => {
                    onInputChange(name, value, error);
                  }}
                  placeholder="Enter type"
                  error={errors.type}
                />
              </div>
              <div className="col-lg-6">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">
                  Registration Number
                </label>
                <Input
                  name="registrationNumber"
                  value={registrationNumber}
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  title="Registration Number"
                  isReq={true}
                  validationFunc={(name, error) => {
                    onInputValidate(name, error);
                  }}
                  onChangeFunc={(name, value, error) => {
                    onInputChange(name, value, error);
                  }}
                  placeholder="Enter registration number"
                  error={errors.registrationNumber}
                />
              </div>
            </div>
            <div className="row mb-3 mx-auto">
              <div className="col-lg-6">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">
                  State where registered
                </label>
                <Input
                  name="registeredState"
                  value={registeredState}
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  title="Registered State"
                  isReq={true}
                  validationFunc={(name, error) => {
                    onInputValidate(name, error);
                  }}
                  onChangeFunc={(name, value, error) => {
                    onInputChange(name, value, error);
                  }}
                  placeholder="Enter Registered State"
                  error={errors.registeredState}
                />
              </div>
              <div className="col-lg-6">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">
                  Estimated Value
                </label>
                <Input
                  name="value"
                  value={value}
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  title="Estimated Value"
                  onChangeFunc={(name, value, error) => {
                    onInputChange(name, value, error);
                  }}
                  placeholder="Enter estimated value"
                />
              </div>
            </div>
          </React.Fragment>
          <div className="col-lg-12 mt-4 home-action">
            <Link to={path_url.home_property}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>

            <button
              onClick={onEditVehicle}
              type="button"
              className="btn btn-primary br-10 fz-16 fw-bold"
            >
              {loader ? "Loading..." : "Update"}
              {loader && (
                <i
                  className="ml-2 fa-spin fa-lg fa fa-spinner"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditVehicle;
