import React from 'react';
import { Navigate } from 'react-router-dom';
import { path_url } from "../Utility/constant";

const RequireAuth = ({ children }) => {
  const userID = localStorage.getItem("userID");
  return userID ? <>{children}</> : <Navigate to={path_url.login} replace />;
};

export default RequireAuth;
