import React from 'react';
import AppRoutes from './Routes/Route';
import './Assets/scss/main.scss';

const App = () => {
	return (
			<div id="wrapper">
				<AppRoutes />
			</div>
	);
};

export default App;