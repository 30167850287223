import React, { useState, useEffect } from "react";
import { path_url } from "../Utility/constant";
import { Link } from "react-router-dom";
import PersonalIcon from "../Assets/images/person-blue.svg";
import { listNominators } from "../Utility/Services/deputy";

const user_id = localStorage.getItem("userID");

const Nominators = () => {
  const [nominatorsList, setNominatorsList] = useState([]);

  useEffect(() => {
    fetchNominatorsData();
  }, []);

  const fetchNominatorsData = () => {
    listNominators(user_id).then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && res.data && !res.error) {
        setNominatorsList(res.data);
      }
    });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section personal-info">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={PersonalIcon}
            alt="personal-icon"
          />{" "}
          Deputy View <hr className="mb-0" />
        </h2>

        <div className="row mx-0 common-mt">
          <div className="flex flex-column mb-5 mt-1 w-100">
            <div id="">
              { nominatorsList && 
                nominatorsList.length > 0 &&
                nominatorsList.map((nominator, index) => {
                  return (
                    <div className="card mb-4" key={`nominator-${index}`}>
                      <div
                        className="card-header d-flex align-items-center justify-content-between"
                        id={nominator.id}
                      >
                        <h5 className="flex-1 mb-0">
                          <Link
                            to={`${path_url.deputy_view}/${nominator.id}`} >
                              <img
                                alt=""
                                src={PersonalIcon}
                                className="img-fluid mr-2"
                              />
                              <span>
                                {nominator.first_name}{" "}
                                {nominator.last_name}
                              </span>
                          </Link>
                        </h5>
                      </div>
                    </div> )}) 
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Nominators;
