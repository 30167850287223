import React, { useState, useEffect, useRef } from "react";
import { Input, Select, TextArea, File } from "../Common/Input";
import { cloneDeep } from "lodash";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { getFormDetails } from "../Utility/helper";
import LegalIcon from "../Assets/images/legal-blue.svg";
import PdfIcon from "../Assets/images/pdfIcon2.png";
import DocIcon from "../Assets/images/doc.png";
import { listDeputies } from "../Utility/Services/deputy";
import { createLegal, updateLegal } from "../Utility/Services/legals";
import { te, ts } from "../Utility/ReduxToaster";

const initForm = {
  name: "",
  description: "",
  share_with: "",
  reminder_in: "",
  document: [],
  filename: "",
  file_type: "",
  errors: {
    name: null,
    description: null,
    reminder_in: null,
    document: null,
  },
};

const reviewInOptions = [
  { value: "3 months", label: "3 months" },
  { value: "6 months", label: "6 months" },
  { value: "1 year", label: "1 year" },
];

const AddEditLegals = (props) => {
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });
  const [shareWithOptions, setShareWithOptions] = useState([]);
  const [loader, setLoader] = useState(false);
  const [legalId, setLegalId] = useState("");
  const tempForm = useRef();
  const navigate = useNavigate();

  const { form } = state;
  let  location  = useLocation();
  
  const {
    name,
    description,
    share_with,
    reminder_in,
    document,
    errors,
    file_type,
    filename,
  } = form;

  const formFun = () => {
    fetchDeputiesData();
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);

  const formUpdate = () => {
    let legalData =
      location &&
      location.state &&
      location.state.data;

    form.name = legalData && legalData.name;
    form.description = legalData && legalData.description;
    form.share_with =
      legalData &&
      legalData.deputy &&
      legalData.deputy.id;

    form.reminder_in = legalData && legalData.reminder_in;
    form.document =
      legalData && legalData.document
        ? [legalData && legalData.document]
        : [];
    if (
      legalData &&
      legalData.file_type &&
      legalData.filename
    ) {
      form.file_type = legalData && legalData.file_type;
      form.filename = legalData && legalData.filename;
    }

    setLegalId(legalData && legalData.id);
    setState({ ...state, form });
  };

  const fetchDeputiesData = () => {
    listDeputies().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        if (res.data.deputies) {
          setShareWithOptions(
            res.data.deputies.map((options) => {
              return {
                value: options.id,
                label: `${options.first_name} ${options.last_name}`,
              };
            })
          );
        }
      }
    });
  };

  const RemoveImage = (index) => {
    let { document } = state.form;
    document.splice(index, 1);
    setState({ ...state, form: { ...state.form, document: document } });
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    if (name === "document") {
      form[name] = [];
      form[name].push(value);
    } else {
      form[name] = value;
    }
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ ...state, form });
  };

  const onAddLegalDocument = () => {
    const { form } = state;

    let bodyObj;

    if (share_with) {
      bodyObj = {
        "legal[name]": name,
        "legal[description]": description,
        "legal[deputy_id]": share_with,
        "legal[reminder_in]": reminder_in,
        "legal[document]":
          form.document && form.document[0] ? form.document[0] : {},
      };
    } else {
      bodyObj = {
        "legal[name]": name,
        "legal[description]": description,
        "legal[reminder_in]": reminder_in,
        "legal[document]":
          form.document && form.document[0] ? form.document[0] : {},
      };
    }

    let obj = getFormDetails(form, onInputValidate);
    setLoader(true);
    if (obj) {
      createLegal(bodyObj).then((res) => {
        if (res && res.error) {
          return;
        }
        if (res && !res.error) {
          ts("Legal Document added successfully");
          setLoader(false);
          navigate(path_url.legals);
        }
      });
    } else {
      setLoader(false);
      return false;
    }
  };

  const onEditLegalDocument = () => {
    const { form } = state;
    let bodyObj = {};
    if (typeof document[0] === "string") {
      bodyObj = {
        "legal[name]": name,
        "legal[description]": description,
        "legal[reminder_in]": reminder_in,
      };
    } else if (typeof document[0] === "object") {
      bodyObj = {
        "legal[name]": name,
        "legal[description]": description,
        "legal[reminder_in]": reminder_in,
        "legal[document]":
          form.document && form.document[0] ? form.document[0] : {},
      };
    };
    if (share_with) {
        bodyObj["legal[deputy_id]"] = share_with
    };

    let obj = getFormDetails(form, onInputValidate);
    setLoader(true);
    if (obj) {
      updateLegal(bodyObj, legalId).then((res) => {
        if (res && res.error) {
          te(res.error);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Legal Document updated successfully");
          setLoader(false);
          navigate(path_url.legals);
        }
      });
    } else {
      setLoader(false);
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={LegalIcon}
            alt="legal-icon"
          />
          {location.state &&
          location.state.data &&
          location.state.data.id
            ? "Update legal document"
            : "Add legal document"}
          <hr className="mb-0" />
        </h2>
        <div className="common-mt common-mb add-edit-modal upload-wrapper">
          <div className="row mx-auto">
            <div className="col-lg-12">
              {document && document.length < 1 && (
                <div className="w-100 file-upload">
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <i className="fas fa-cloud-upload-alt mr-3 fa-2x" />
                    </div>
                    <div className="pt-3">
                      <span className="text-red-primary fw-semibold">
                        Select File to Upload
                      </span>
                      <p className="text-dark-grey fz-12">
                        Or drop your file here
                      </p>
                    </div>
                  </div>
                  <File
                    id="selectFile"
                    className="selectFile text-black placeholder-black border-dark-grey"
                    name="document"
                    validationFunc={onInputValidate}
                    onChangeFunc={onInputChange}
                    fileType=".pdf, .jpg,.jpeg, .png, application/msword,
application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    ismultiple={false}
                    errors={errors.document}
                  />
                </div>
              )}

              {errors.document && (
                <span className="reqEstric">
                  <i className="fas fa-exclamation-circle" /> {errors.document}
                </span>
              )}

              {document.length > 0 && (
                <React.Fragment>
                  <div className="file-upload d-flex align-items-center justify-content-center">
                    {document &&
                      document.map((res, index) => {
                        let name = "";
                        var imageUrl;
                        if (res && typeof res == "object" && res.type) {
                          var urlCreator = window.URL || window.webkitURL;
                          imageUrl = urlCreator.createObjectURL(res);
                          name = res.name;
                        } else {
                          imageUrl = document;
                          name = filename;
                        }

                        return (
                          <React.Fragment>
                            <div className="imagePreview text-center ml-3 position-relative">
                              <button
                                className="imageRemoveBtn"
                                type="button"
                                onClick={() => {
                                  RemoveImage(index);
                                }}
                              />
                              <div className="imageThumb p-2">
                                <img
                                  src={
                                    (res.type || file_type) ===
                                    "application/pdf"
                                      ? PdfIcon
                                      : (res.type || file_type) ===
                                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                      ? DocIcon
                                      : (res.type || file_type) ===
                                        "application/msword"
                                      ? DocIcon
                                      : imageUrl
                                  }
                                  className="img-fluid"
                                  alt="style-url"
                                />
                              </div>
                              <div className="imageName px-3 py-2 break-text">
                                {name}
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                  </div>
                </React.Fragment>
              )}
            </div>
            <div className="col-lg-12 mt-3">
              <label className="w-100 fw-semibold text-left fz-18 mb-2">Name</label>
              <Input
                name="name"
                value={name}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter name"
                error={errors.name}
              />
            </div>
            <div className="col-lg-12 mt-3">
              <label className="w-100 fw-semibold text-left fz-18 mb-2">
                Description
              </label>
              <TextArea
                name="description"
                value={description}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Description"
                isReq={true}
                validationFunc={(name, error) => {
                  onInputValidate(name, error);
                }}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                rows={4}
                placeholder="Enter description"
                error={errors.description}
              />
            </div>
            <div className="col-lg-6 mt-3">
              <label className="w-100 fw-semibold text-left fz-18 mb-2">
                Share with
              </label>
              <Select
                name="share_with"
                value={share_with}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Share with"
                options={shareWithOptions}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Select share with"
              />
            </div>
            <div className="col-lg-6 mt-3">
              <label className="w-100 fw-semibold text-left fz-18 mb-2">
                Reminder to review in
              </label>
              <Select
                name="reminder_in"
                value={reminder_in}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Review in"
                options={reviewInOptions}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Select"
                error={errors.reminder_in}
                isReq={true}
              />
            </div>

            <div className="col-lg-12 mt-4 legal-action">
              <Link to={path_url.legals}>
                <button className="cancel-btn mr-4">Cancel</button>
              </Link>

              {location &&
                location.state &&
                location.state.data &&
                location.state.data.id && (
                  <button
                    onClick={onEditLegalDocument}
                    type="button"
                    className="cursor-pointer btn btn-primary br-10 fz-16 fw-bold"
                  >
                    {loader ? "Updating..." : "Update"}
                    {loader && (
                      <i
                        className="ml-2 fa-spin fa-lg fa fa-spinner"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                )}

              {location && !location.state && (
                <button
                  onClick={onAddLegalDocument}
                  type="button"
                  className="cursor-pointer btn btn-primary br-10 fz-16 fw-bold"
                >
                  {loader ? "Loading..." : "Add"}
                  {loader && (
                    <i
                      className="ml-2 fa-spin fa-lg fa fa-spinner"
                      aria-hidden="true"
                    />
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEditLegals;
