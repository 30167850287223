import React, { useState, useEffect } from 'react';
import { path_url } from '../Utility/constant';
import FinancialIcon from '../Assets/images/bank-blue.svg';
import Edit from '../Assets/images/edit.svg';
import Delete from '../Assets/images/delete.svg';
import { listFinanicals, delFinancial } from '../Utility/Services/financial';
import { ts } from '../Utility/ReduxToaster';
import { useNavigate } from 'react-router-dom';

const Financials = (props) => {
  const navigate = useNavigate();
  const [ financialsList, setFinancialsList ] = useState([]);
  const [prompts, setPrompts] = useState(false);

  const addEditFinancials = (id, type) => {
    if (type === 'Edit') {
      navigate(path_url.add_edit_financial, {
        state: { userid: id, data: financialsList }
      });
    } else {
      navigate(path_url.add_edit_financial);
    }
  };

  useEffect(() => {
    fetchFinancialsData();
  }, []);

  const fetchFinancialsData = () => {
    listFinanicals().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setFinancialsList(res.data.financials);
        setPrompts(res.data.prompts)
      }
    });
  };

  const onDeleteFinance = (val) => {
    delFinancial(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchFinancialsData();
        ts('Financial deleted successfully');
      }
    });
  };
  return (
      <React.Fragment>
        <div className="w-100 content-section personal-info account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title text-center">
            <img className="img-fluid mr-4" src={FinancialIcon} alt="Financial Accounts" style={{padding: '7px'}} />
            Financial Accounts
          <hr className="mb-0" />
        </h2>
          <button onClick={() => addEditFinancials('', 'New')} className="person-btn">
            Add Finance
          </button>

          {prompts  && (   
          <div className="prompts">
            <strong>{prompts.component} </strong> 
            <p> {prompts.body} </p>
          </div>
        )}

          <div className="row common-mt common-mb ">
            <div className="flex flex-column mb-5 mt-1 w-100">
              <div id="accordion">
                {financialsList && financialsList.length > 0 && (
                    <>
                      {financialsList.map((val, index) => {
                        return (
                            <React.Fragment>
                              <div className="card mb-4">
                                <div
                                    className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                                    id={"heading-" + index}
                                >
                                  <h5 className="flex-1 mb-0">
                                    <a
                                        className="collapsed"
                                        role="button"
                                        data-toggle="collapse"
                                        href={"#collapse-" + index}
                                        aria-expanded="false"
                                        aria-controls={"collapse-" + index}
                                    >
                                      {val.bank_name}
                                    </a>
                                  </h5>
                                  <div className="d-flex justify-content-left">
                                  <span onClick={() => addEditFinancials(index, 'Edit')}>
                                    <img className="mr-3 cursor-pointer" src={Edit} alt="edit-icon" />
                                  </span>{' '}
                                    <span onClick={() => onDeleteFinance(val)}>
                                    <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon" />
                                  </span>{' '}
                                  </div>
                                </div>
                                <div
                                    id={"collapse-" + index}
                                    className="collapse"
                                    data-parent="#accordion"
                                    aria-labelledby={"heading-" + index}
                                >
                                  <div className="card-body">
                                    {(
                                        <div className="property-card">
                                          <div className="d-flex align-items-center justify-content-between mb-4">
                                            <div className="d-flex align-items-center">
                                              <p className="text-dark-grey fz-15 mb-1">
                                                <span className="mr-1 fz-14 fw-bold">
                                                  Account Number:
                                                </span>
                                                { val.account_number }
                                              </p>
                                            </div>
                                          </div>
                                          <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                              <p className="text-dark-grey fz-15 mb-1">
                                                <span className="mr-1 fz-14 fw-bold">
                                                  Login Name:
                                                </span>
                                                { val.login_name }
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                            </React.Fragment>
                        );
                      })}
                    </>
                )}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
  );
};
export default Financials;
