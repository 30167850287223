import React, { useState, useEffect, useRef } from "react";
import { Input, TextArea, File } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import PetIcon from "../Assets/images/pets-blue.svg";
import { createPet, editPet } from "../Utility/Services/mypets";

const initForm = {
  petName: "",
  colors: "",
  breed: "",
  chip_number: "",
  godparent_first_name: "",
  godparent_last_name: "",
  godparent_email: "",
  insurance_info: "",
  special_instructions: "",
  image: [],
  documents: [],
  errors: {
    petName: null,
    colors: null,
    breed: null,
    chip_number: null,
    godparent_first_name: null,
    godparent_last_name: null,
    godparent_email: null,
    insurance_info: null,
    special_instructions: null,
    image: null,
    documents: null,
  },
};

const AddEditPet = (props) => {
  console.log(props);
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [loader, setLoader] = useState(false);

  const tempForm = useRef();

  let { form } = state;
  let location = useLocation();
  let {
    errors,
    petName,
    colors,
    breed,
    chip_number,
    godparent_first_name,
    godparent_last_name,
    godparent_email,
    insurance_info,
    special_instructions,
  } = form;

  const formFun = () => {
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);

  const formUpdate = () => {
    let PetData = location.state && location.state.data;
    if (PetData) {
      form.petName = PetData.name;
      form.colors = PetData.colors;
      form.breed = PetData.breed;
      form.chip_number = PetData.chip_number;
      form.godparent_first_name = PetData.godparent_first_name;
      form.godparent_last_name = PetData.godparent_last_name;
      form.godparent_email = PetData.godparent_email;
      form.insurance_info = PetData.insurance_info;
      form.special_instructions = PetData.special_instructions;
      form.image = PetData.image && PetData.image.files ? PetData.image : [];
      form.documents = PetData.documents ? PetData.documents : [];

      setState({ ...state, form });
    }
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    if (name === "image" || name === "documents") {
      form[name].push(value);
    } else {
      form[name] = value;
    }
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ ...state, form });
  };

  const petPayload = () => {
    const { form } = state;
    let bodyObj = {
      "[pet][name]":petName,
      "[pet][breed]":breed,
      "[pet][colors]":colors,
      "[pet][chip_number]":chip_number,
      "[pet][insurance_info]":insurance_info,
      "[pet][special_instructions]":special_instructions,
      "[pet][godparents][first_name]": godparent_first_name,
      "[pet][godparents][last_name]": godparent_last_name,
      "[pet][godparents][email]": godparent_email,
      "[pet][image]":form.image && form.image[0] ? form.image[0] : ""
    };

    let formData = new FormData();
    Object.keys(bodyObj).map((key) => formData.append(key, bodyObj[key]));

    for (const key of Object.keys(form.documents)) {
      formData.append(`[pet][documents][]`, form.documents[key])
    }

    return formData;
  }

  const onAddPet = () => {

    const bodyObj = petPayload();

    if (bodyObj) {
      createPet(bodyObj).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Image added successfully");
          setLoader(false);
          navigate(path_url.my_pets);
        }
      });
    }
  };

  const onEditPet = () => {

    const bodyObj = petPayload();

    if (bodyObj) {
      editPet(bodyObj, location.state.data.id).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Image added successfully");
          setLoader(false);
          navigate(path_url.my_pets);
        }
      });
    }
  };
  
const  hasObject = location.state && location.state.data && location.state.data.id;

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title text-center">
          <img className="img-fluid mr-3" src={PetIcon} alt="pet-icon" />
            { hasObject ? "Update Pet" : "Share information about your Pet's Needs here"}
          <hr className="mb-0" />
        </h2>
        <div className="q-prompts">
          <strong>What information does your Pets Guardian need to know?</strong>
        </div>
        <div className="add-edit-modal">
          <div className="row mb-3 mx-auto">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                What is your Pet's Name?
              </label>
              <Input name="petName"
                     value={petName}
                     className="form-control input-content text-black placeholder-black border-dark-grey"
                     title="Pet Name"
                     isReq={true}
                     validationFunc={onInputValidate}
                     onChangeFunc={(name, value, error) => {
                       onInputChange(name, value, error);
                     }}
                     placeholder="Enter Pet name"
                     error={errors.petName} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">Breed</label>
              <Input name="breed"
                     value={breed}
                     className="form-control input-content text-black placeholder-black border-dark-grey"
                     title="Breed"
                     isReq={false}
                     validationFunc={onInputValidate}
                     onChangeFunc={(name, value, error) => {
                       onInputChange(name, value, error);
                     }}
                     placeholder="Enter breed"
                     error={errors.breed} />
            </div>
          </div>

          <div className="row mb-3 mx-auto">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">Color</label>
              <Input name="colors"
                     value={colors}
                     className="form-control input-content text-black placeholder-black border-dark-grey"
                     title="Colors"
                     isReq={false}
                     validationFunc={onInputValidate}
                     onChangeFunc={(name, value, error) => {
                       onInputChange(name, value, error);
                     }}
                     placeholder="Enter colors"
                     error={errors.colors} />
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Chip Number
              </label>
              <Input name="chip_number"
                     value={chip_number}
                     className="form-control input-content text-black placeholder-black border-dark-grey"
                     title="Chip Number"
                     isReq={false}
                     validationFunc={onInputValidate}
                     onChangeFunc={(name, value, error) => {
                       onInputChange(name, value, error);
                     }}
                     placeholder="Enter chip number"
                     error={errors.chip_number} />
            </div>
          </div>

          <div className="row mb-3 mx-auto">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Does your pet have insurance?
              </label>
              <TextArea name="insurance_info"
                        value={insurance_info}
                        className="form-control input-content text-black placeholder-black border-dark-grey"
                        title="Insurance Information"
                        isReq={false}
                        validationFunc={onInputValidate}
                        onChangeFunc={(name, value, error) => {
                          onInputChange(name, value, error);
                        }}
                        placeholder="Enter any insurance information"
                        error={errors.insurance_info} />
            </div>
          </div>

          <div className="row mb-3 mx-auto">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Are there any Special Care Instructions needed for your Pet?
              </label>
              <TextArea name="special_instructions"
                        value={special_instructions}
                        className="form-control input-content text-black placeholder-black border-dark-grey"
                        title="Special Instructions"
                        isReq={false}
                        validationFunc={onInputValidate}
                        onChangeFunc={(name, value, error) => {
                          onInputChange(name, value, error);
                        }}
                        placeholder="Enter special instructions"
                        error={errors.special_instructions} />
            </div>
          </div>
          <div className="row mb-3 mx-auto">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                 Pictures of your pet?
              </label>
              <div className="w-100 file-upload">
                <File id="selectFile"
                      className="selectFile"
                      name="image"
                      errors={errors.image}
                      validationFunc={onInputValidate}
                      onChangeFunc={onInputChange}
                      fileType=".jpg,.jpeg, .png"
                      ismultiple={false}
                      isFile={false} />
              </div>
            </div>
          </div>
          <div className="row mb-3 mx-auto">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Are there Documents that Your Guardian will need? (ex medical)
              </label>
              <div className="w-100 file-upload">
                <File id="selectFile"
                      className="selectFile"
                      name="documents"
                      validationFunc={onInputValidate}
                      onChangeFunc={onInputChange}
                      fileType=".pdf, .jpg,.jpeg, .png, application/msword,
application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      ismultiple={true}
                      errors={errors.documents}
                      isFile={false}
                />
              </div>
            </div>
          </div>

          <div className="row mb-3 mx-auto">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Who will you appoint as your Pet's Guardian?
              </label>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left">
                Guardian's First Name
              </label>
              <Input name="godparent_first_name"
                     value={godparent_first_name}
                     className="form-control input-content text-black placeholder-black border-dark-grey"
                     title="First Name"
                     isReq={false}
                     validationFunc={onInputValidate}
                     onChangeFunc={(name, value, error) => {
                       onInputChange(name, value, error);
                     }}
                     placeholder="Enter First Name"
                     error={errors.godparent_first_name} />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left">
                Guardian's Last Name
              </label>
              <Input name="godparent_last_name"
                     value={godparent_last_name}
                     className="form-control input-content text-black placeholder-black border-dark-grey"
                     title="Last Name"
                     isReq={false}
                     validationFunc={onInputValidate}
                     onChangeFunc={(name, value, error) => {
                       onInputChange(name, value, error);
                     }}
                     placeholder="Enter Last Name"
                     error={errors.godparent_last_name} />
            </div>

            <div className="col-sm-12 col-md-12 col-lg-6 fz-18">
              <label className="w-100 fw-semibold mb-2 text-left">
                Email
              </label>
              <Input name="godparent_email"
                     value={godparent_email}
                     className="form-control input-content text-black placeholder-black border-dark-grey"
                     title="Godparent Email"
                     isReq={false}
                     validationFunc={onInputValidate}
                     onChangeFunc={(name, value, error) => {
                       onInputChange(name, value, error);
                     }}
                     placeholder="Enter Email"
                     error={errors.godparent_email} />
            </div>
          </div>

          <div className="row mb-3 mx-auto">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <Link to={path_url.my_pets}>
                <button className="cancel-btn mr-4">Cancel</button>
              </Link>
              {location &&
                location.state &&
                location.state.data &&
                location.state.data.id && (
                  <button onClick={onEditPet}
                          type="button" 
                          className="btn btn-primary br-10 fz-16 fw-bold add-btn" >
                    {loader ? "Updating..." : "Update"}
                    {loader && (
                      <i className="ml-2 fa-spin fa-lg fa fa-spinner"
                         aria-hidden="true" />
                    )}
                  </button>
                )}
              {location && !location.state && (
                <button onClick={onAddPet}
                        type="button"
                        className="btn btn-primary br-10 fz-16 fw-bold add-btn" >
                  {loader ? "Loading..." : "Add"}
                  {loader && (
                    <i className="ml-2 fa-spin fa-lg fa fa-spinner"
                       aria-hidden="true" />
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEditPet;
