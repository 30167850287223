import React, { useState, useEffect } from 'react';
import { File } from '../Common/Input';
import { cloneDeep } from 'lodash';
import { getFormDetails } from '../Utility/helper';
import { path_url } from '../Utility/constant';
import { te, ts } from '../Utility/ReduxToaster';
import Edit from '../Assets/images/edit.svg';
import Delete from '../Assets/images/delete.svg';
import { listImages, delImage, createImage, editImage } from '../Utility/Services/image';
import { useNavigate } from 'react-router-dom';
import ImagesIcon from "../Assets/images/images-blue.svg";

const initForm = {
  file: [],
  errors: {
    file: null
  }
};

const Images = (props) => {
  const navigate = useNavigate();
  const [ state, setState ] = useState({
    form: cloneDeep(initForm)
  });
  const [ loader, setLoader ] = useState(false);
  const [ isEdit, setIsEdit ] = useState(false);
  const [ imageId, setImageId ] = useState('');
  const [ imageList, setImageList ] = useState([]);

  const [ prompts, setPrompt ] = useState(false); 

  let { form } = state;
  let { errors, file } = form;

  useEffect(() => {
    fetchImageData();
  }, []);

  const fetchImageData = () => {
    listImages().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setPrompt(res.data.prompts);
        setImageList(res.data.images);
      }
    });
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {

    const { form } = state;
    if (name === 'file') {
      form[name] = [];
      form[name].push(value);
    } else {
      form[name] = value;
    }
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ ...state, form });
  };

  const RemoveImage = (index) => {
    let { file } = state.form;
    file.splice(index, 1);
    setState({ ...state, form: { ...state.form, file: file } });
  };

  const onUpload = () => {
    const { form } = state;
    let bodyObj = {
      '[image][file]': form.file && form.file[0] ? form.file[0] : {}
    };
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      createImage(bodyObj).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          fetchImageData();
          ts('Image uploaded successfully');
          setLoader(false);
          setIsEdit(false)
          setState({ ...state, form: { ...state.form, file: [] } });
          navigate(path_url.images);
        }
      });
    }
  };

  const onDeleteImage = (val) => {
    delImage(val && val.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchImageData();
        ts('Image deleted successfully');
      }
    });
  };

  const formUpdate = (val) => {
    form.file = val ? [ val ] : [];
    setIsEdit(true);
    setImageId(val.id);
    setState({ ...state, form });
  };

  const onEditUpload = () => {
    const { form } = state;
    if (form && form.file && form.file[0] && form.file[0].size) {
      let bodyObj = {
        '[image][file]': form.file && form.file[0] ? form.file[0] : {}
      };
      let obj = getFormDetails(form, onInputValidate);
      if (obj) {
        setLoader(true);
        editImage(imageId, bodyObj).then((res) => {
          if (res && res.error) {
            te(res.message);
            setLoader(false);
            return;
          }
          if (res && !res.error) {
            fetchImageData();
            ts('Image updated successfully');
            setLoader(false);
            setIsEdit(false)
            setState({ ...state, form: { ...state.form, file: [] } });
            navigate(path_url.images);
          }
        });
      }
    } else {
      fetchImageData();
      ts('Image updated successfully');
      setLoader(false);
      setIsEdit(false)
      setState({ ...state, form: { ...state.form, file: [] } });
      navigate(path_url.images);
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section upload-wrapper personal-info">

        <h3 className="fw-bold text-black content-title text-center">
        <img className="ew-image" src={ImagesIcon} alt="time line" />
          {prompts && prompts.title}
          <hr className="mb-0" />
        </h3>

        {prompts && (   
          <div className="prompts">
            <strong> {prompts.name} </strong> <p> {prompts.body} </p>
          </div>
        )}

        <div className="add-edit-modal">
          <div className="mb-5 row">
            <div className="col-md-12">
              <label className="w-100 fw-semibold text-left mb-2">Select file to upload</label>
            </div>
            <div className="col-md-12">
              {file.length < 1 && (
                <div className="w-100 file-upload">
                  <div className="d-flex justify-content-center align-items-center">
                    <div>
                      <i className="fas fa-cloud-upload-alt mr-3 fa-2x" />
                    </div>
                    <div className="pt-3">
                      <span className="text-red-primary fw-semibold">Select File to Upload</span>
                      <p className="text-dark-grey fz-12">Or drop your file here</p>
                    </div>
                  </div>
                  <File
                    id="selectFile"
                    className="selectFile text-black placeholder-black border-dark-grey"
                    name="file"
                    errors={errors.file}
                    validationFunc={onInputValidate}
                    onChangeFunc={onInputChange}
                    fileType=".jpg,.jpeg, .png"
                    ismultiple={false}
                    isFile={true}
                  />
                </div>
              )}
              {errors.file && (
                <span className="reqEstric">
                  <i className="fas fa-exclamation-circle" /> {errors.file}
                </span>
              )}
              {file &&
              file.length > 0 && (
                <React.Fragment>
                  <div className="file-upload d-flex align-items-center justify-content-center">
                    {file &&
                      file.map((res, index) => {
                        let name = '';
                        var imageUrl;
                        if (res && typeof res == 'object' && res.type) {
                          var urlCreator = window.URL || window.webkitURL;
                          imageUrl = urlCreator.createObjectURL(res);
                          name = res.name;
                        } else {
                          imageUrl = res.file;
                          name = res.filename;
                        }
                        return (
                          <React.Fragment>
                            <div className="imagePreview text-center ml-3 position-relative">
                              My Images
                              <button
                                className="imageRemoveBtn"
                                type="button"
                                onClick={() => {
                                  RemoveImage(index);
                                }}
                              />
                              <div className="imageThumb p-2">
                                <img src={imageUrl} className="img-fluid" alt="style-url" />
                              </div>
                              <div className="imageName px-3 py-2 break-text">{name}</div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    <div>
                      {isEdit ? (
                        <button
                          onClick={onEditUpload}
                          type="button"
                          className="share-btn ml-5 d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
                        >
                          {loader ? 'Updating...' : 'Update'}
                          {loader && <i className="ml-2 fa-spin fa-lg fa fa-spinner" aria-hidden="true" />}
                        </button>
                      ) : (
                        <button
                          onClick={onUpload}
                          type="button"
                          className="share-btn ml-5 d-flex align-items-center justify-content-center cursor-pointer fz-14 fw-bold text-blue"
                        >
                          {loader ? 'Uploading...' : 'Upload'}
                          {loader && <i className="ml-2 fa-spin fa-lg fa fa-spinner" aria-hidden="true" />}                          
                        </button>
                      )}
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-12">
              <label className="w-100 fw-semibold fz-18 text-left mb-2">Uploaded Images</label>
            </div>
          </div>
          <div className="row">
            {imageList &&
              imageList.length > 0 &&
              imageList.map((val) => {
                return (
                  <div className="col-md-3 mb-3">
                    <div className="imagePreviewList text-center position-relative">
                      <div className="imageThumb py-2">
                        <span onClick={() => formUpdate(val)} className="position-absolute imageEditBtn h-18">
                          <img className="mr-3 cursor-pointer" src={Edit} alt="edit-icon" />
                        </span>{' '}
                        <span onClick={() => onDeleteImage(val)} className="position-absolute imageDeleteBtn h-18">
                          <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon" />
                        </span>{' '}
                        <img src={val.file} className="img-fluid" alt="style-file" />
                      </div>
                      <div className="imageName px-3 py-2 break-text">{val.filename}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Images;
