import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import ReduxToastr, { toastr } from 'react-redux-toastr';

import React from 'react';

const toastrOptions = {
  timeOut: 4000,
  newestOnTop: false,
  position: 'bottom-right',
  className: 'toastr-style'
};

export const ts = (message, title = 'Success') => {
  toastr.success(title, message);
};
export const te = (message, title = 'Error') => {
  toastr.error(title, message);
};
export const ti = (message, title = 'Info') => {
  toastr.info(title, message);
};
export const tw = (message, title = 'Warning') => {
  toastr.warning(title, message);
};

const Toastr = () => {
  return <ReduxToastr {...toastrOptions} closeOnToastrClick />;
};

export default Toastr;
