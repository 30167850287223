import React, { useState } from "react";
import AllowedItems from "./../MenuItems/AllowedItems";
import NominatorItems from "./../MenuItems/NominatorItems";
import DefaultItems from "./../MenuItems/DefaultItems";
//import AdminItems from "./../MenuItems/AdminItems";
import LogoutItem from "./LogoutItem";

const MenuList = ({showLogout}) => {
    const [loader, setLoader] = useState(false);
    const [allowedFeatureNames, setAllowedFeatureNames] = useState([]);

    return (
        <React.Fragment>
            {loader && (
                <i
                    className="fa-spin fa-lg fa fa-spinner loader"
                    aria-hidden="true"
                />
            )}
            <ul className="sidemenu-list">
                {/*<AdminItems />*/}
                <AllowedItems
                    setLoader={setLoader}
                    setAllowedFeatureNames={setAllowedFeatureNames} />
                <NominatorItems />
                <DefaultItems allowedFeatureNames={allowedFeatureNames} />
                { showLogout && <LogoutItem/> }
            </ul>
        </React.Fragment>
    );
};

MenuList.defaultProps = {
    showLogout: false
};
export default MenuList;
