import React, { useState, useEffect, useRef } from "react";
import { Input } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails, syncLogin } from "../Utility/helper";
import { Link } from "react-router-dom";
import { path_url } from "../Utility/constant";
import Lock from "../Assets/images/lock.svg";
import Email from "../Assets/images/email.svg";
import { te, ts } from "../Utility/ReduxToaster";
import leftBanner from "../Assets/images/login-bg-new.svg";
//import Logo from "../Assets/images/EnduringWishesLogoV2_white.png";
import Logo from "../Assets/images/EnduringWishesLogoV2.png";
import { userLogin } from "../Utility/Services/authenticate";

const initForm = {
  email: "",
  password: "",
  errors: {
    email: null,
    password: null,
  },
};

const Login = (props) => {
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });
  const [loggedIn, setLoggedIn] = useState(false);
  const [loader, setLoader] = useState(false);
  const tempUrl = useRef();

  let { form } = state;
  let { errors, email, password } = form;

  const urlFun = () => {
    let URL = window.location.search;

    if (URL.includes("email")) {
      const deputyEmail = URL.split("=")[1];
      setState({
        ...state,
        form: {
          ...state.form,
          errors: errors,
          email: deputyEmail,
        },
      });
    }
  };

  tempUrl.current = urlFun;

  useEffect(() => {
    tempUrl.current();
  }, []);

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onCheck = () => {
    if (loggedIn) {
      setLoggedIn(false);
    } else {
      setLoggedIn(true);
    }
  };

  const onSubmit = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      userLogin(email, password).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("User Logged in Successfully");
          syncLogin(res.data);
          setLoader(false);
          let response = res.data.data;
          if (
            response.attributes.account &&
            response.attributes.account.subdomain
          ) {
            window.location.href =
              `${window.location.protocol}//${response.attributes.account.subdomain}.${window.location.host}${path_url.personalInformation}/` +
              response.id +
              "?t=" +
              res.data.meta.token;
          } else if (
            response.attributes.account &&
            !response.attributes.account.subdomain &&
            response.attributes.account_admin
          ) {
            window.location.href = `${window.location.protocol}//${window.location.host}${path_url.corporateDashboard}`;
          } else {
            window.location.href =
              `${window.location.protocol}//${window.location.host}${path_url.personalInformation}/` +
              response.id;
          }
        }
      });
    } else {
      return false;
    }
  };

  const forgotHandler = () => {
    if (window.location.hostname.includes("localhost")) {
      const url = `http://localhost:3000${path_url.forgot_password}`;
      window.open(url, '_blank', 'noreferrer')
    } else if (window.location.hostname.includes("staging")) {
      const url = `${window.location.protocol}//${window.location.host}${path_url.forgot_password}`;
      window.open(url, '_blank', 'noreferrer');
    } else {
      const url = `${window.location.protocol}//${window.location.host}${path_url.forgot_password}`;
      window.open(url, '_blank', 'noreferrer');
    }
  };

  return (
    <React.Fragment>
      <div className=""
           style={{
                    backgroundColor: "#E3E3E3"
                  }}>
        <div className="row">
          <section className="col-lg-6 col-md-6 col-sm-12 login-right-image"
                   style={{
                            margin: "auto",
                            backgroundColor: "#FFFFFF"
                          }}>
            <div className="container-fluid px-0">
              <div className="login-form-part">
                  <div className="d-flex align-items-center h-100 w-100">
                    <div className="w-100 card login-form-main border-0 bg-transparent">
                      <div className="card-body px-0 text-center mb-auto">
                        <h3 className="fw-bold mb-0 h-responsive img-content-text">
                          <span className="text-light-blue">Make Your</span>
                        </h3>
                        <h3 className="fw-bold mb-3 mt-0 h-responsive img-content-text">
                          <img
                            src={Logo}
                            className="img-fluid img-logo"
                            alt="logo"
                            style={{height: "68px"}}
                          />
                        </h3>
                        
                        <p className="fw-bold mb-3 h-responsive img-content">
                          We're glad to see you again!
                        </p>
                        <h3 className="fw-bold mb-3 h-responsive">
                          Sign-in
                        </h3>
                        <div className="card-text ">
                          <div
                            data-testid="email"
                            className="form-group position-relative mb-4" >
                            {" "}
                            <span className="position-absolute custom-icon">
                              <img src={Email} alt="" className="img-fluid" />
                            </span>
                            <Input
                              name="email"
                              value={email}
                              className="form-control form-control-sm text-black placeholder-black border-black"
                              title="Email"
                              isReq={true}
                              reqType="email"
                              validationFunc={onInputValidate}
                              onChangeFunc={(name, value, error) => {
                                onInputChange(name, value, error);
                              }}
                              placeholder="Enter your email"
                              error={errors.email}
                            />
                          </div>
                          <div
                            data-testid="password"
                            className="form-group position-relative" >
                            <span className="position-absolute custom-icon">
                              <img src={Lock} alt="" className="img-fluid" />
                            </span>
                            <Input
                              name="password"
                              className="form-control form-control-sm text-black placeholder-black border-black"
                              value={password}
                              validationFunc={onInputValidate}
                              onChangeFunc={(name, value, error) => {
                                onInputChange(name, value, error);
                              }}
                              isReq={true}
                              title="Password"
                              placeholder="Enter password"
                              error={errors.password}
                              type="password"
                            />
                          </div>
                          <div className="form-group position-relative d-flex justify-content-between align-items-center">
                            <div>
                              <label className="custom-checkbox"
                                     style={{ "marginLeft": "0" }}>
                                <input
                                  className="mr-3"
                                  type="checkbox"
                                  name="loggedIn"
                                  value={loggedIn}
                                  checked={loggedIn}
                                  onChange={onCheck}
                                />
                                <span className="checkmark border-dark-grey ml-0" />
                                <span className="fz-15 text-black">
                                  Keep me logged in
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="form-group position-relative d-flex justify-content-between align-items-center">
                            <div>
                              <a
                                onClick={forgotHandler}
                                className="fz-15 text-light-blue cursor-pointer fw-bold"
                                href="/#" >
                                <span className="forgot-passwprd">
                                  Forgot Password?{" "}
                                </span>{" "}
                              </a>
                            </div>
                          </div>
                          <button
                            data-testid="submitBtn"
                            onClick={onSubmit}
                            className="btn btn-primary fz-20 px-5 fw-bold " >
                            {loader ? "Loading..." : "Login"}
                            {loader && (
                              <i
                                className="ml-2 fa-spin fa-lg fa fa-spinner"
                                aria-hidden="true"
                              />
                            )}
                          </button>
                          <div className="register fz-14 mt-5 mb-5 pb-5">
                            {" "}
                            <span className="text-dark-grey">
                              Don't have an account? &nbsp;
                            </span>
                            <Link
                              to={path_url.signUp}
                              className="fz-15 fw-bold text-light-blue cursor-pointer text-decoration-underline"
                            >
                              Register here
                            </Link>{" "}
                            {/*<br />
                            <span className="text-dark-grey">
                              Need a company account? &nbsp;
                            </span>
                            <Link
                              to={path_url.corporateSignUp}
                              className="fz-15 fw-bold text-light-blue cursor-pointer text-decoration-underline"
                            >
                              Click here
                            </Link>{" "}*/}
                          </div>
                        </div>                        
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Login;
