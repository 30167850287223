import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../../Common/Header";
import SideMenu from "../../Common/SideMenu";
import Footer from "../../Common/Footer";

const MainLayout = (props) => {
  return (
    <React.Fragment>
      <Header {...props} />
      <div className="d-flex">
        <SideMenu {...props} />
        <Outlet/>
      </div>
      <Footer color={"bg-grey"} textColor={"text-black"} />
    </React.Fragment>
  );
};
export default MainLayout;
