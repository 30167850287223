import React, { useState, useEffect, useRef } from "react";
import Edit from "../Assets/images/edit.svg";
import Delete from "../Assets/images/delete.svg";
import { path_url } from "../Utility/constant";
import { ts } from "../Utility/ReduxToaster";
import { userInvite } from "../Utility/Services/user";
import ToolTip from "../Common/Input/ToolTip";
import { getAccount, getCorporateUsers } from "../Utility/Services/account";
import { useNavigate } from "react-router-dom";

const UserManagement = (props) => {
  const navigate = useNavigate();
  // const [ loader, setLoader ] = useState(false);
  const [userList, setUserList] = useState([]);
  const user_id = localStorage.getItem("userID");
  const tempFetch = useRef();

  const fetchFun = () => {
    fetchAccount();
    fetchUserList();
  };

  tempFetch.current = fetchFun;

  useEffect(() => {
    tempFetch.current();
  }, []);

  const fetchUserList = () => {
    getCorporateUsers(user_id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        setUserList(res.data.data);
      }
    });
  };

  const fetchAccount = () => {
    getAccount(user_id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        let account_id =
          res.data.data.attributes &&
          res.data.data.attributes.account &&
          res.data.data.attributes.account.id;
        localStorage.setItem("accountID", account_id);
      }
    });
  };

  const addEditUser = (id, type) => {
    if (type === "Edit") {
      navigate(path_url.users, {
        state: { userid: id, data: userList },
      });
    } else {
      navigate(path_url.users);
    }
  };

  const sendInvite = (id) => {
    // setLoader(true);
    userInvite(id).then((res) => {
      if (res && res.error) {
        // setLoader(false);
        return;
      }
      if (res && !res.error) {
        ts("Invitation sent successfully");
        // setLoader(false);
      }
    });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title" key="title">
          <i className="fas fa-users title-img text-blue mr-3" />
          User Management <hr className="mb-0" />
        </h2>
        <button
          onClick={() => addEditUser("", "New")}
          className="upload-btn"
          key="new-user"
        >
          Add New User
        </button>

        <div className="row common-mb" key="users-div">
          <div className="col-md-12" key="users-table-div">
            <div
              className="custom-table-main shadow table-responsive"
              key="users-table-responsive"
            >
              <table className="table table-striped" key="users-table">
                <thead>
                  <tr>
                    <th width="20%">First Name</th>
                    <th width="20%">Last Name</th>
                    <th width="25%">Email Address</th>
                    <th width="15%">Role</th>
                    <th width="20%">Actions</th>
                  </tr>
                </thead>
                {userList && userList.length > 0 && (
                  <tbody>
                    {userList.map((val, index) => {
                      return (
                        <React.Fragment>
                          {!val.attributes.account_admin && (
                            <tr key={index}>
                              <td className="d-flex align-items-center">
                                {val.attributes.first_name}
                              </td>
                              <td>{val.attributes.last_name}</td>
                              <td>{val.attributes.email}</td>
                              <td>
                                {val.attributes.account_admin
                                  ? "Admin"
                                  : "Member"}
                              </td>
                              <td>
                                <div className="d-flex justify-content-left">
                                  <span
                                    onClick={() => addEditUser(index, "Edit")}
                                  >
                                    <img
                                      alt=""
                                      src={Edit}
                                      className="mr-3 cursor-pointer"
                                    />
                                  </span>
                                  <span>
                                    <ToolTip
                                      className="mr-3 cursor-pointer"
                                      imgSrc={Delete}
                                    />
                                    {/* <img alt="" src={Delete} className="mr-3 cursor-pointer" /> */}
                                  </span>{" "}
                                  <span>
                                    <button
                                      onClick={() => sendInvite(val.id)}
                                      className="invite-btn cursor-pointer"
                                    >
                                      <i
                                        className="fa fa-paper-plane mr-2"
                                        aria-hidden="true"
                                      />
                                      Send Invite
                                    </button>
                                  </span>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                )}
              </table>
              {userList && userList.length === 0 && (
                <h4 className="pt-3 fz-16 pb-4 text-center">
                  <i className="fas fa-exclamation-triangle mr-2" />
                  No Data Found
                </h4>
              )}
              {userList && userList.length === 1 && (
                <React.Fragment>
                  {userList.map((val, index) => {
                    return (
                      <React.Fragment>
                        {val.attributes.account_admin && (
                          <h4 className="pt-3 fz-16 pb-4 text-center">
                            <i className="fas fa-exclamation-triangle mr-2" />
                            No Data Found
                          </h4>
                        )}
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserManagement;
