import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import LegalIcon from "../Assets/images/doc-blue.svg";
import Edit from "../Assets/images/edit.svg";
import { delLegal, listLegals } from "../Utility/Services/legals";
import { listDeputies } from "../Utility/Services/deputy";
import Delete from "../Assets/images/delete.svg";
import { ts } from "../Utility/ReduxToaster";
import FileViewerModal from "../Common/Input/FileViewerModal";

const Legals = (props) => {
  const navigate = useNavigate();
  const [documentList, setDocumentList] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [prompts, setPrompts] = useState(false);

  const showFileModal = (file) => {
    setFileType(file.file_type);
    setFileUrl(file.document);
    setIsOpen(true)
  }

  const addEditLegals = (document, type) => {
    if (type === "Edit") {
      navigate(path_url.add_edit_legals, {
        //state: { userid: id, data: documentList },
        state: { document_id: document.id, data: document },
      });
    } else {
      navigate(path_url.add_edit_legals);
    }
  };

  useEffect(() => {
    fetchLegalsData();
    fetchDeputiesData();
  }, []);

  const fetchDeputiesData = () => {
    listDeputies().then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        // setDeputies(res.data);
      }
    });
  };

  const fetchLegalsData = () => {
    listLegals().then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && !res.error) {
        setDocumentList(res.data.documents);
        setPrompts(res.data.prompts);
      }
    });
  };

  const onDeleteLegal = (document) => {
    delLegal(document && document.id).then((res) => {
      if (res && res.error) {
        return;
      }
      if (res && !res.error) {
        fetchLegalsData();
        ts("Legal deleted successfully");
      }
    });
  };

  return (
    <React.Fragment>
      <FileViewerModal open={modalIsOpen} setIsOpen={setIsOpen} fileType={fileType} fileUrl={fileUrl} />
      <div className="w-100 content-section personal-info account-wrapper user-wrapper">

        <h2 className="fw-bold text-black content-title text-center">
          <img className="ew-image" src={LegalIcon} alt="=legal-icon"/>
          {prompts && prompts.title}
          <hr className="mb-0" />
        </h2>

        <button onClick={() => addEditLegals("", "New")} className="person-btn">
          Add Documents
        </button>

        {prompts && (   
          <div className="prompts">
            <strong> {prompts.name} </strong> <p> {prompts.body} </p>
          </div>
        )}

        <div className="row mx-0 ">
          <div className="flex flex-column mb-5 mt-1 w-100">
            <div id="accordion">
              {documentList && documentList.length > 0 && (
                  <>
                    {documentList.map((document, index) => {
                      return (
                          <React.Fragment key={index}>
                            <div className="card mb-4">
                              <div
                                  className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                                  id={"heading-" + index}
                              >
                                <h5 className="flex-1 mb-0">
                                  <a
                                      className="collapsed"
                                      role="button"
                                      data-toggle="collapse"
                                      href={"#collapse-" + index}
                                      aria-expanded="false"
                                      aria-controls={"collapse-" + index}
                                  >
                                    {document.name}
                                  </a>
                                </h5>
                                <div className="d-flex justify-content-left">
                                  <span onClick={() => addEditLegals(document, "Edit")}>
                                    <img className="mr-3 cursor-pointer" src={Edit} alt="edit-icon"/>
                                  </span>{" "}
                                  <span onClick={() => onDeleteLegal(document)}>
                                    <img className="mr-3 cursor-pointer" src={Delete} alt="delete-icon"/>
                                  </span>{" "}
                                </div>
                              </div>
                              <div
                                  id={"collapse-" + index}
                                  className="collapse"
                                  data-parent="#accordion"
                                  aria-labelledby={"heading-" + index}
                              >
                                <div className="card-body">
                                  {(
                                      <div className="property-card">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-center">

                                            <p className="text-dark-grey fz-15 mb-1">
                                              <span className="mr-1 fz-14 fw-bold">
                                              Document:
                                              </span>
                                              <span
                                                  className="mr-1 fz-14 fw-bold text-decoration-underline cursor-pointer"
                                                  onClick={() => {
                                                    showFileModal(document)
                                                  }}
                                              >
                                                {document.filename}
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-center">
                                            <p className="text-dark-grey fz-15 mb-1">
                                              <span className="mr-1 fz-14 fw-bold">
                                              Description:
                                              </span>
                                              { document.description }
                                            </p>
                                          </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-center">
                                            <p className="text-dark-grey fz-15 mb-1">
                                              <span className="mr-1 fz-14 fw-bold">
                                              Share With:
                                              </span>
                                              {document.deputy?.first_name
                                                  ? `${document.deputy.first_name} ${document.deputy.last_name ?? ''}`
                                                  : "-"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                          <div className="d-flex align-items-center">
                                            <p className="text-dark-grey fz-15 mb-1">
                                              <span className="mr-1 fz-14 fw-bold">
                                              Review In:
                                              </span>
                                              { document.reminder_in }
                                            </p>
                                          </div>
                                        </div>

                                      </div>
                                  )}
                                </div>
                              </div>
                            </div>

                          </React.Fragment>
                      );
                    })}
                  </>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Legals;
