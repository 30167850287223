import React, { useState, useEffect } from "react";
import PrivacyIcon from "../Assets/images/private-blue.svg";
import {
  updatePrivacySettings,
  listDeputies,
} from "../Utility/Services/deputy";

let PrivacySettings = (props) => {
  const [deputies, setDeputies] = useState([]);
  // const [loader, setLoader] = useState(false);

  useEffect(() => {
    fetchDeputiesData();
  }, []);

  const fetchDeputiesData = () => {
    listDeputies().then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && res.data && !res.error) {
        setDeputies(res.data.deputies);
        // const freeFeatures = res.data.free_plan_allowed_features;
        // setFreeFeatures(freeFeatures);
      }
    });
  };

  const checkboxHandler = (value, id, feature) => {
    const {
      allowed_features: allowedFeatures,
      first_name,
      last_name,
      email,
      role,
    } = value;

    if (allowedFeatures.includes(feature)) {
      allowedFeatures.splice(allowedFeatures.indexOf(feature), 1);
    } else {
      allowedFeatures.push(feature);
    }

    const bodyObj = {
      first_name,
      last_name,
      email,
      role,
      allowed_features: allowedFeatures,
    };

    // setLoader(true);

    updatePrivacySettings(id, bodyObj).then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && !res.error) {
        fetchDeputiesData();
        // setLoader(false);
      }
    });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section user-wrapper">
      <h2 className="fw-bold text-black content-title text-center">
            <img className="img-fluid mr-4" src={PrivacyIcon} alt="Privacy" style={{padding: '7px'}} />
           Set the permissions for your Deputies 
          <hr className="mb-0" />
        </h2>

        <div className="prompts">
         <strong>Personalized Deputy Permissions</strong> 
         <p>After appointing deputies, you have the flexibility to set customized permissions, 
          allowing them to dictate what specific information each deputy can access.</p>
        </div>

        <div className="row mx-0">
          <div className="flex flex-column mb-5 mt-1 w-100">
            <div id="accordion" className="privacy-acc">
              {deputies.length > 0 &&
                deputies.map((val) => {
                  return (
                    <div className="card mb-4">
                      <div
                        className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                        id={`heading-${val.id}`}
                      >
                        <h5 className="flex-1 mb-0">
                          <a
                            className="collapsed"
                            role="button"
                            data-toggle="collapse"
                            href={`#collapse-${val.id}`}
                            aria-expanded="false"
                            aria-controls={`collapse-${val.id}`}
                          >
                            {val.first_name} {val.last_name}
                          </a>
                        </h5>
                      </div>
                      <div
                        id={`collapse-${val.id}`}
                        className="collapse"
                        data-parent="#accordion"
                        aria-labelledby={`heading-${val.id}`}
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Personal Information</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`personal-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(
                                        val,
                                        val.id,
                                        "personalInfo"
                                      )
                                    }
                                    checked={val.allowed_features.includes(
                                      "personalInfo"
                                    )}
                                    // disabled={freeFeatures.includes(feature)}
                                  />
                                  <label for={`personal-${val.id}`} />
                                </div>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Images</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`images-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(val, val.id, "images")
                                    }
                                    checked={val.allowed_features.includes(
                                      "images"
                                    )}
                                  />
                                  <label for={`images-${val.id}`} />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Deputies</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`deputies-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(val, val.id, "deputy")
                                    }
                                    checked={val.allowed_features.includes(
                                      "deputy"
                                    )}
                                  />
                                  <label for={`deputies-${val.id}`} />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Funeral Wishes</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`funeralWishes-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(
                                        val,
                                        val.id,
                                        "funeralWishes"
                                      )
                                    }
                                    checked={val.allowed_features.includes(
                                      "funeralWishes"
                                    )}
                                  />
                                  <label for={`funeralWishes-${val.id}`} />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>My Story</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    onChange={() =>
                                      checkboxHandler(val, val.id, "mystory")
                                    }
                                    checked={val.allowed_features.includes(
                                      "mystory"
                                    )}
                                    id={`story-${val.id}`}
                                  />
                                  <label for={`story-${val.id}`} />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Digital Information</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`digital-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(
                                        val,
                                        val.id,
                                        "digitalInfo"
                                      )
                                    }
                                    checked={val.allowed_features.includes(
                                      "digitalInfo"
                                    )}
                                  />
                                  <label for={`digital-${val.id}`} />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Home and Property</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`homeproperty-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(
                                        val,
                                        val.id,
                                        "homeAndProperty"
                                      )
                                    }
                                    checked={val.allowed_features.includes(
                                      "homeAndProperty"
                                    )}
                                  />
                                  <label for={`homeproperty-${val.id}`} />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Legals</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`legals-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(val, val.id, "legals")
                                    }
                                    checked={val.allowed_features.includes(
                                      "legals"
                                    )}
                                  />
                                  <label for={`legals-${val.id}`} />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Timeline</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`timeline-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(val, val.id, "timeline")
                                    }
                                    checked={val.allowed_features.includes(
                                      "timeline"
                                    )}
                                  />
                                  <label for={`timeline-${val.id}`} />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Family Tree</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`family-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(val, val.id, "familytree")
                                    }
                                    checked={val.allowed_features.includes(
                                      "familytree"
                                    )}
                                  />
                                  <label for={`family-${val.id}`} />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Messages To Loved Ones</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`messagelove-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(val, val.id, "messages")
                                    }
                                    checked={val.allowed_features.includes(
                                      "messages"
                                    )}
                                  />
                                  <label for={`messagelove-${val.id}`} />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Financials</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`financials-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(val, val.id, "financials")
                                    }
                                    checked={val.allowed_features.includes(
                                      "financials"
                                    )}
                                  />
                                  <label for={`financials-${val.id}`} />
                                </div>
                              </div>
                            </div>


                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Recipes</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`recipes-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(val, val.id, "recipes")
                                    }
                                    checked={val.allowed_features.includes(
                                      "recipes"
                                    )}
                                  />
                                  <label for={`recipes-${val.id}`} />
                                </div>
                              </div>
                            </div>




                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Insurances</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`insurances-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(val, val.id, "insurances")
                                    }
                                    checked={val.allowed_features.includes(
                                      "insurances"
                                    )}
                                  />
                                  <label for={`insurances-${val.id}`} />
                                </div>
                              </div>
                            </div>
                            <div className="col-12">
                              <div className="setting-opt">
                                <h5>Pets</h5>
                                <div className="switch-box">
                                  <input
                                    type="checkbox"
                                    id={`pets-${val.id}`}
                                    onChange={() =>
                                      checkboxHandler(val, val.id, "pets")
                                    }
                                    checked={val.allowed_features.includes(
                                      "pets"
                                    )}
                                  />
                                  <label for={`pets-${val.id}`} />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PrivacySettings;
