import React, { useState, useEffect, useRef } from "react";
import { Input, TextArea } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import FuneralIcon from "../Assets/images/funeral-wishes-blue.svg";
import { addReadings, editReadings } from "../Utility/Services/funeralWishes";

const initForm = {
  bookName: "",
  verse: "",
  content: "",
  reflection: "",
  errors: {
    bookName: null,
    verse: null,
    content: null,
    reflection: null,
  },
};

const AddEditReadings = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [loader, setLoader] = useState(false);
  const [readingId, setReadingId] = useState("");
  const tempForm = useRef();

  let { form } = state;
  let location = useLocation();
  let { errors, bookName, verse, content, reflection } = form;

  const formFun = () => {
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);

  const formUpdate = () => {
    let readingData = location.state && location.state.data;
    if (readingData) {
      form.bookName = readingData.book;
      form.verse = readingData.verse;
      form.content = readingData.content;
      form.reflection = readingData.reflection;
      setState({ ...state, form });
      setReadingId(location.state.reading_id);
    }
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onAddReadings = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      addReadings(bookName, verse, content, reflection).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Book added successfully");
          setLoader(false);
          navigate(path_url.funeral_wishes);
        }
      });
    } else {
      return false;
    }
  };

  const onEditReadings = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      editReadings(readingId, bookName, verse, content, reflection).then(
        (res) => {
          if (res && res.error) {
            te(res.message);
            setLoader(false);
            return;
          }
          if (res && !res.error) {
            ts("Book updated successfully");
            setLoader(false);
            navigate(path_url.funeral_wishes);
          }
        }
      );
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={FuneralIcon}
            alt="funeral-icon"
          />
          {location.state && location.state.data && location.state.data.id
            ? "Update Passages & Readings"
            : "Add Passages & Readings"}
          <hr className="mb-0" />
        </h2>
        <div className="add-edit-modal">
          <div className="row mb-3 mx-auto">
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Book Name
              </label>
              <Input
                name="bookName"
                value={bookName}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Book Name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter book name"
                error={errors.bookName}
              />
            </div>
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Chapter - Verse
              </label>
              <Input
                name="verse"
                value={verse}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Verse"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter chapter/verse"
                error={errors.verse}
              />
            </div>
          </div>

          <div className="row mb-3 mx-auto">
            <div className="col-lg-12">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Content
              </label>
              <TextArea
                name="content"
                value={content}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Content"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter content"
                error={errors.content}
              />
            </div>
            <div className="col-lg-12">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Reflection
              </label>
              <TextArea
                name="reflection"
                value={reflection}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Reflection"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter reflection"
                error={errors.reflection}
              />
            </div>
          </div>

          <div className="col-lg-12 mt-4">
            <Link to={path_url.funeral_wishes}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>
            {location &&
              location.state &&
              location.state.data &&
              location.state.data.id && (
                <button
                  onClick={onEditReadings}
                  type="button"
                  className="btn btn-primary br-10 fz-16 fw-bold"
                >
                  {loader ? "Updating..." : "Update"}
                  {loader && (
                    <i
                      className="ml-2 fa-spin fa-lg fa fa-spinner"
                      aria-hidden="true"
                    />
                  )}
                </button>
              )}
            {location && !location.state && (
              <button
                onClick={onAddReadings}
                type="button"
                className="btn btn-primary br-10 fz-16 fw-bold add-btn"
              >
                {loader ? "Loading..." : "Add"}
                {loader && (
                  <i
                    className="ml-2 fa-spin fa-lg fa fa-spinner"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEditReadings;
