import React, { useState, useEffect, useRef } from "react";
import { Input } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import { createInsurance, editInsurance } from "../Utility/Services/insurance";

const initForm = {
  companyName: "",
  policyNumber: "",
  beneficiaries: "",
  value: "",
  errors: {
    companyName: null,
    policyNumber: null,
    beneficiaries: null,
    value: null,
  },
};

const AddEditInsurance = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [loader, setLoader] = useState(false);
  const [insuranceId, setInsuranceId] = useState("");
  const tempForm = useRef();

  let { form } = state;
  let location = useLocation();
  let { errors, companyName, beneficiaries, policyNumber, value } = form;

  const formFun = () => {
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);

  const formUpdate = () => {
    let insuranceData = location.state && location.state.data;
    if (insuranceData) {
      form.companyName = insuranceData.company_name;
      form.policyNumber= insuranceData.policy_number;
      form.beneficiaries= insuranceData.beneficiaries;
      form.value = insuranceData.value;
      setState({ ...state, form });
      setInsuranceId(location.state.insurance_id);
    }
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onAddInsurance = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      createInsurance(companyName, policyNumber, beneficiaries, value).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Insurance created successfully");
          setLoader(false);
          navigate(path_url.insuranceInformation);
        }
      });
    } else {
      return false;
    }
  };

  const onEditInsurance = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      editInsurance(insuranceId, companyName, policyNumber, beneficiaries, value).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Insurance updated successfully");
          setLoader(false);
          navigate(path_url.insuranceInformation);
        }
      });
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <i className="fas fa-medkit title-img text-blue mr-3" />
          {location.state &&
          location.state.data &&
          location.state.data.id > 0
            ? "Update Insurance"
            : "Add Insurance"}
          <hr className="mb-0" />
        </h2>
        <div className="common-mt add-edit-modal">
          <div className="row mb-3 mx-auto">
            <div className="col-lg-12">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
               Which insurance company is your policy with?
              </label>
              <Input
                name="companyName"
                value={companyName}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Comapny Name"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter the Insurance Company here"
                error={errors.companyName}
              />
            </div>
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Policy Number
              </label>
              <Input
                name="policyNumber"
                value={policyNumber}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Policy Number"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter policy number"
                error={errors.policyNumber}
              />
            </div>
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Who is the Beneficiary of this Policy?
              </label>
              <Input
                name="beneficiaries"
                value={beneficiaries}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Beneficiaries"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter beneficiaries name"
                error={errors.beneficiaries}
              />
            </div>
          </div>

          <div className="row mb-3 mx-auto">
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">What is the Value of this Policy?</label>
              <Input
                name="value"
                value={value}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Value"
                isReq={true}
                reqType="number"
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter value"
                error={errors.value}
              />
            </div>
          </div>

          <div className="col-lg-12 mt-4 insurance-action">
            <Link to={path_url.insuranceInformation}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>

            {location &&
              location.state &&
              location.state.data &&
              location.state.data.id > 0 && (
                <button
                  onClick={onEditInsurance}
                  type="button"
                  className="btn btn-primary br-10 fz-16 fw-bold"
                >
                  {loader ? "Updating..." : "Update"}
                  {loader && (
                    <i
                      className="ml-2 fa-spin fa-lg fa fa-spinner"
                      aria-hidden="true"
                    />
                  )}
                </button>
              )}
            {location && !location.state && (
              <button
                onClick={onAddInsurance}
                type="button"
                className="btn btn-primary br-10 fz-16 fw-bold"
              >
                {loader ? "Loading..." : "Add"}
                {loader && (
                  <i
                    className="ml-2 fa-spin fa-lg fa fa-spinner"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEditInsurance;
