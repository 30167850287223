import { post, get, put } from '../httpInterceptor';

export const userInvite = (user_id) => {
	return post(`/users/${user_id}/invite`, {}, true, false).then((res) => {
		return res;
	});
};

export const fetchAllUsers = () => {
	return get(`/users`, true).then((res) => {
		return res;
	});
};

export const editUser = (user_id, firstName, lastName, email) => {
	return put(
		`/users/${user_id}?first_name=${firstName}&last_name=${lastName}&email=${email}`,
		{},
		true,
		false
	).then((res) => {
		return res;
	});
};
