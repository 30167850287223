import axios from "axios";
import { API_URL } from "./config";
import { getToken } from "./helper";

export const get = (url, isPrivate = true, responseType = null, headers) => {
  let apiUrl = API_URL + url;
  const axiosObj = {
    method: "get",
    url: apiUrl,
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  if (headers) {
    axiosObj.headers = headers;
  }

  if (responseType) axiosObj.responseType = responseType;
  return axios(axiosObj)
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));
};

export const post = (
  url,
  bodyObj = {},
  isPrivate = true,
  mediaFile = false
) => {
  const apiUrl = API_URL + url;
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
      // 'Content-Type': 'application/x-www-form-urlencoded'
    },
  };

  if (mediaFile === true) {
    let formData = new FormData();
    Object.keys(bodyObj).map((key) => formData.append(key, bodyObj[key]));
    bodyObj = formData;
  }
  
  return axios
    .post(apiUrl, bodyObj, isPrivate && config)
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));
};

export const put = (url, bodyObj = {}, isPrivate = true, mediaFile = false) => {
  const apiUrl = API_URL + url;

  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  if (mediaFile === true) {
    let formData = new FormData();
    Object.keys(bodyObj).map((key) => formData.append(key, bodyObj[key]));
    bodyObj = formData;
  }
  return axios
    .put(apiUrl, bodyObj, isPrivate && config)
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));
};

export const del = (url, isPrivate = true, responseType = null, headers) => {
  let apiUrl = API_URL + url;

  const axiosObj = {
    method: "delete",
    url: apiUrl,
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  if (headers) {
    axiosObj.headers = headers;
  }

  if (responseType) axiosObj.responseType = responseType;
  return axios(axiosObj)
    .then((response) => handleResponse(response))
    .catch((error) => handleError(error));
};

const handleResponse = (response) => {
  return {
    error: false,
    data: response.data,
  };
};

const handleError = (error) => {
  const { response } = error;
  let errorMsg = "Sorry, something went wrong. Please try again.";
  if (response && response.status === 404) {
    errorMsg = response.statusText;
  }
  console.log("API ERROR ::: ", JSON.stringify(response));

  return {
    error: true,
    message:
      response &&
      response.data &&
      response.data.errors &&
      response.data.errors[0]
        ? response.data.errors[0].title
        : errorMsg,
    status: response ? response.status : null,
  };
};
