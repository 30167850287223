import React, { useState } from "react";
import { Input, TextArea } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import homeIcon from "../Assets/images/home-property-blue.svg";
import { addAssets } from "../Utility/Services/assets";

const initForm = [
  {
    description: "",
    location: "",
    value: "",
    errors: {
      description: null,
    },
  },
];

const AddAssets = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm) ? cloneDeep(initForm) : [],
  });

  let [assetsData, setAssetsData] = useState(1);

  const [loader, setLoader] = useState(false);

  let { form } = state;

  const onInputChange = (name, value, error = undefined, i) => {
    const { form } = state;
    form[i][name] = value;
    if (error !== undefined) {
      form[i].errors[name] = error;
    }
    setState({ form });
  };

  const onInputValidate = (name, error, i) => {
    let { form } = state;
    if (error) {
      form[i].errors[name] = error;
      setState({ form });
    } else {
      form[i].errors[name] = null;
      setState({ form });
    }
  };

  const onCounterAdd = (val) => {
    let { form } = state;
    if (val > 0) {
      setAssetsData(val);
      form && form.push({ ...cloneDeep(initForm[0]) });
      setState({ ...state });
    }
  };

  const onDeleteAssets = (data, val, index) => {
    if (data > 0) {
      setAssetsData(data);
      form.splice(index, 1);
      setState({ form });
    } else {
      val.description = "";
      val.location = "";
      val.value = "";
      setState({ form });
    }
  };

  const onAddAssets = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      let formObj = [];
      form &&
        form.map((val, i) =>
          formObj.push({
            description: val.description,
            location: val.location,
            estimated_value: val.value,
          })
        );
      setLoader(true);
      addAssets({ asset: formObj }).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Assets added successfully");
          setLoader(false);
          navigate(path_url.home_property);
        }
      });
    } else {
      return false;
    }
  };

  let assetsFormArray = [];
  for (let i = 0; i < assetsData; i++) {
    assetsFormArray.push(
      <React.Fragment>
        <div className="d-flex align-items-center mb-2">
          <div>
            <span className="fw-bold fz-18 text-blue ml-3">Assets {i + 1}</span>
          </div>
          {assetsData > 1 && (
            <div
              onClick={() => {
                onDeleteAssets(assetsData - 1, form[i], i);
              }}
              className="dynamic-icon cursor-pointer ml-5"
            >
              <i className="fa fa-trash fa-lg mr-2" aria-hidden="true" /> Delete
            </div>
          )}
        </div>
        <div className="row mb-2 mx-auto">
          <div className="col-lg-12">
            <label className="w-100 fw-semibold mb-2 text-left fz-18">
              Description
            </label>
            <TextArea
              name="description"
              value={form[i] && form[i].description}
              className="form-control input-content text-black placeholder-black border-dark-grey"
              title="Description"
              isReq={true}
              validationFunc={(name, error) => {
                onInputValidate(name, error, i);
              }}
              onChangeFunc={(name, value, error) => {
                onInputChange(name, value, error, i);
              }}
              rows={4}
              placeholder="Enter description"
              error={form[i] && form[i].errors.description}
            />
          </div>
        </div>
        <div className="row mb-2 mx-auto">
          <div className="col-lg-6">
            <label className="w-100 fw-semibold mb-2 text-left fz-18">Location</label>
            <Input
              name="location"
              value={form[i] && form[i].location}
              className="form-control input-content text-black placeholder-black border-dark-grey"
              title="Location"
              onChangeFunc={(name, value, error) => {
                onInputChange(name, value, error, i);
              }}
              placeholder="Enter location"
            />
          </div>
          <div className="col-lg-6">
            <label className="w-100 fw-semibold mb-2 text-left fz-18">
              Estimated Value
            </label>
            <Input
              name="value"
              value={form[i] && form[i].value}
              className="form-control input-content text-black placeholder-black border-dark-grey"
              title="Estimated Value"
              onChangeFunc={(name, value, error) => {
                onInputChange(name, value, error, i);
              }}
              placeholder="Enter estimated value"
            />
          </div>
        </div>
      </React.Fragment>
    );
  }

  let assetsStatus = false;
  let CheckForm = form.map((res, i) => {
    return Object.keys(form[i]).filter((res) => {
      if (
        res !== "errors" &&
        res !== "location" &&
        res !== "value" &&
        !form[i][res]
      ) {
        return res;
      }
    });
  });
  CheckForm.map((res, i) => (res.length === 0 ? (assetsStatus = true) : null));
  assetsStatus = CheckForm.every((res) => res.length === 0);

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={homeIcon}
            alt="home-icon"
          />
          Add Other Assets
          <hr className="mb-0" />
        </h2>
        <div className="common-mt add-edit-modal">
          <div className="row mx-auto d-flex justify-content-end">
            <div
              onClick={() => {
                onCounterAdd(assetsData + 1);
              }}
              className="dynamic-icon cursor-pointer ml-3"
            >
              <i className="fa fa-plus-circle fa-lg mr-2" aria-hidden="true" />
              Add Assets
            </div>
          </div>

          {assetsFormArray ? assetsFormArray : ""}
          <div className="col-lg-12 mt-4 home-action">
            <Link to={path_url.home_property}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>

            <button
              onClick={onAddAssets}
              disabled={!assetsStatus}
              type="button"
              className="btn btn-primary br-10 fz-16 fw-bold"
            >
              {loader ? "Loading..." : "Add"}
              {loader && (
                <i
                  className="ml-2 fa-spin fa-lg fa fa-spinner"
                  aria-hidden="true"
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddAssets;
