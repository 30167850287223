import { get } from "../httpInterceptor";

export const getDeputyViewStories = (id) => {
  return get(`/users/${id}/stories`, {}, true, false).then((res) => {
    return res;
  });
};

export const getDeputyViewCremations = (id) => {
  return get(`/users/${id}/plot_burial_cremations`, {}, true, false).then(
    (res) => {
      return res;
    }
  );
};

export const getDeputyViewReadingData = (id) => {
  return get(`/users/${id}/readings`, {}, true, false).then((res) => {
    return res;
  });
};

export const getDeputyViewSongsData = (id) => {
  return get(`/users/${id}/music_and_songs`, {}, true, false).then((res) => {
    return res;
  });
};

export const getDeputyViewInstructionData = (id) => {
  return get(`/users/${id}/special_instructions`, {}, true, false).then(
    (res) => {
      return res;
    }
  );
};

export const getDeputyViewLegalsData = (id) => {
  return get(`/users/${id}/legals`, {}, true, false).then((res) => {
    return res;
  });
};
export const getDeputyViewInfo = (id) => {
  return get(`/users/${id}`, {}, true, false).then((res) => {
    return res;
  });
};
export const getDeputyViewAllowedFeatures = (userId, id) => {
  return get(
    `/users/${userId}/nominators/${id}/privacy_settings`,
    {},
    true,
    false
  ).then((res) => {
    return res;
  });
};
export const getDeputyViewHomeAndProp = (id) => {
  return get(`/users/${id}/home_and_property`, {}, true, false).then((res) => {
    return res;
  });
};
export const getDeputyViewMessages = (id) => {
  return get(`/users/${id}/messages`, {}, true, false).then((res) => {
    return res;
  });
};
export const getDeputyViewInsurances = (id) => {
  return get(`/users/${id}/insurances`, {}, true, false).then((res) => {
    return res;
  });
};
export const getDeputyData = (id) => {
  return get(`/users/${id}/my_deputies`, {}, true, false).then((res) => {
    return res;
  });
};

export const getDeputyViewFinancials = (id) => {
  return get(`/users/${id}/financials`, {}, true, false).then((res) => {
    return res;
  });
};
export const getDeputyViewRecipes = (id) => {
  return get(`/users/${id}/recipes`, {}, true, false).then((res) => {
    return res;
  });
};

export const getDeputyViewTimeline = (id) => {
  return get(`/users/${id}/timeline`, {}, true, false).then((res) => {
    return res;
  });
};
export const getDeputyViewImages = (id) => {
  return get(`/users/${id}/fetch_images`, {}, true, false).then((res) => {
    return res;
  });
};
export const getDeputyViewPets = (id) => {
  return get(`/users/${id}/mypets`, {}, true, false).then((res) => {
    return res;
  });
};

