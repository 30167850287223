import React, { useState, useEffect, useRef } from "react";
import { Input, TextArea } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import LovedOnceIcon from "../Assets/images/loved-ones-blue.svg";
import { createMessage, editMessage } from "../Utility/Services/message";

const initForm = {
  to: "",
  email: "",
  phoneNumber: "",
  body: "",
  errors: {
    to: null,
    email: null,
    phoneNumber: null,
    body: null,
  },
};

const AddEditMessages = (props) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [loader, setLoader] = useState(false);
  const [messageId, setMessageId] = useState("");
  const tempForm = useRef();

  let { form } = state;
  let location = useLocation();
  let { errors, to, body, email, phoneNumber } = form;

  const formFun = () => {
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);

  const formUpdate = () => {
    let messageData = location.state && location.state.data;

    if (messageData) {
      form.to = messageData.to;
      form.body = messageData.body;
      form.email = messageData.email;
      form.phoneNumber = messageData.phoneNumber;
      setState({ ...state, form });
      setMessageId(location.state.message_id);
    }
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onAddMessages = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      createMessage(to, body, email, phoneNumber).then((res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Message created successfully");
          setLoader(false);
          navigate(path_url.message_to_loved_once);
        }
      });
    }
  };

  const onEditMessages = () => {
    const { form } = state;
    let obj = getFormDetails(form, onInputValidate);
    if (obj) {
      setLoader(true);
      editMessage(messageId, to, body, email, phoneNumber).then(
        (res) => {
          if (res && res.error) {
            te(res.message);
            setLoader(false);
            return;
          }
          if (res && !res.error) {
            ts("Message updated successfully");
            setLoader(false);
            navigate(path_url.message_to_loved_once);
          }
        }
      );
    } else {
      return false;
    }
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">
        <h2 className="fw-bold text-black content-title">
          <img
            className="img-fluid mr-3 title-img"
            src={LovedOnceIcon}
            alt="loved-icon"
          />
          {location.state &&
          location.state.data &&
          location.state.data.id
            ? "Update Message"
            : "Add Message"}
          <hr className="mb-0" />
        </h2>
        <div className="common-mt add-edit-modal">
          <div className="row mb-3 mx-auto">
            <div className="col-lg-12">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Send To
              </label>
              <Input
                name="to"
                value={to}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="To"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter sender name"
                error={errors.to}
              />
            </div>
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">Email</label>
              <Input
                name="email"
                value={email}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Email"
                isReq={true}
                reqType="email"
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter email"
                error={errors.email}
              />
            </div>
            <div className="col-lg-6">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Phone Number
              </label>
              <Input
                name="phoneNumber"
                value={phoneNumber}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Phone Number"
                isReq={true}
                reqType="number"
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter phone number"
                type="Number"
                error={errors.phoneNumber}
              />
            </div>
            <div className="col-lg-12">
              <label className="w-100 fw-semibold mb-2 text-left fz-18">
                Message
              </label>
              <TextArea
                name="body"
                value={body}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Message"
                isReq={true}
                rows={5}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                }}
                placeholder="Enter message"
                error={errors.body}
              />
            </div>
          </div>

          <div className="col-lg-12 mt-3 message-action">
            <Link to={path_url.message_to_loved_once}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>

            {location &&
              location.state &&
              location.state.data &&
              location.state.data.id && (
                <button
                  onClick={onEditMessages}
                  type="button"
                  className="btn btn-primary br-10 fz-16 fw-bold"
                >
                  {loader ? "Updating..." : "Update"}
                  {loader && (
                    <i
                      className="ml-2 fa-spin fa-lg fa fa-spinner"
                      aria-hidden="true"
                    />
                  )}
                </button>
              )}
            {location && !location.state && (
              <button
                onClick={onAddMessages}
                type="button"
                className="btn btn-primary br-10 fz-16 fw-bold"
              >
                {loader ? "Loading..." : "Add"}
                {loader && (
                  <i
                    className="ml-2 fa-spin fa-lg fa fa-spinner"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddEditMessages;