import React, {useEffect, useRef, useState} from "react";
import {getFeatures} from "../../Utility/Services/account";
import Item from "./Item";

const AllowedItems = (props) => {
    const { setLoader, setAllowedFeatureNames  } = props;
    const [allowedFeatures, setAllowedFeatures] = useState([]);
    const user_id = localStorage.getItem("userID");
    const isValidPayment = localStorage.getItem("is_valid_payment")
    const featuresFunc = () => {
        getFeatures().then((res) => {
            if (!res || res.error) {
                setLoader(false);
                return;
            }

            const responseData = res.data;
            const resAllowedFeatures = responseData[user_id]?.allowed_features;

            if (resAllowedFeatures?.length > 0 && isValidPayment === 'true') {
                setAllowedFeatures(resAllowedFeatures);
                setAllowedFeatureNames(resAllowedFeatures.map(item => item.name));
            }

            setLoader(false);
        });
    };

    const tempFeatures = useRef(featuresFunc);

    useEffect(() => {
        tempFeatures.current();
    }, []);

    return (
        <React.Fragment>
            {allowedFeatures.map((feature) => (
                <Item key={`allowed-${feature.name}`} 
                      item={feature} 
                      disable={feature.disabled}
                />
            ))}
        </React.Fragment>
    );
};


export default AllowedItems;
