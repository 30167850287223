import React, { useState, useEffect, useRef } from "react";
import { cloneDeep } from "lodash";
import Edit from "../Assets/images/edit.svg";
import Delete from "../Assets/images/delete.svg";
import StoryIcon from "../Assets/images/my-story-blue.svg";
import { TextArea, Input } from "../Common/Input";
import {
  addMyStory,
  deleteMyStory,
  getMyStories,
  updateMyStory,
} from "../Utility/Services/mystories";
import { te, ts } from "../Utility/ReduxToaster";
import { getFormDetails } from "../Utility/helper";

const initForm = {
  description: [],
  story: "",
  storytitle: "",
  errors: {
    story: null,
    storytitle: null,
  },
};

const MyStory = (props) => {
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });
  let { form } = state;
  let { description, errors } = form;

  const [loader, setLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);
  const [story, setStory] = useState("");
  const [storytitle, setStoryTitle] = useState("");
  const [isEdit, setIsedit] = useState(false);
  const [id, setId] = useState();
  const tempFetch = useRef();
  const [prompt, setPrompt] = useState(false); 

  const fetchFun = () => {
    fetchStories();
  };

  tempFetch.current = fetchFun;

  useEffect(() => {
    tempFetch.current();
  }, []);

  const fetchStories = () => {
    getMyStories().then((res) => {
      if (res && res.error) {
        return;
      }

      if (res && !res.error) {
        const stories = res.data.my_stories;
        setPrompt(res.data.prompts)
        if (stories) {
          setState({
            ...state,
            form: {
              ...state.form,
              description: stories.sort(
                (a, b) => parseFloat(b.id) - parseFloat(a.id)
              ),
              errors: errors,
            },
          });
        }
      }
    });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

  const onInputValidate = (name, error) => {
    let { errors } = state.form;

    if (errors) {
      errors[name] = error;
    }

    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const addHandler = () => {
    setIsedit(false);
    let bodyObj = {
      my_story: {
        input: story,
        title: storytitle,
      },
    };
    const obj = getFormDetails(
      {
        story,
        storytitle,
        errors: {
          story: null,
          storytitle: null,
        },
      },
      onInputValidate
    );

    if (obj) {
      setLoader(true);
      addMyStory(bodyObj).then((res) => {
        if (res && res.error) {
          te(res.message);
          return false;
        }

        if (res && !res.error) {
          ts("Story added successfully");
          fetchStories();
          setLoader(false);
          setStory("");
          setStoryTitle("");
        }
      });
    } else {
      return false;
    }
  };

  const editHandler = (desId) => {
    const editStory = description.find((des) => des.id === desId);
    setStory(editStory.input);
    setStoryTitle(editStory.title);
    setIsedit(true);
    setId(desId);
  };

  const updateHandler = (id) => {
    let bodyObj = {
      my_story: {
        input: story,
        title: storytitle,
      },
    };

    const obj = getFormDetails(
      {
        story,
        storytitle,
        errors: {
          story: null,
          storytitle: null,
        },
      },
      onInputValidate
    );

    if (obj) {
      setUpdateLoader(true);
      updateMyStory(id, bodyObj).then((res) => {
        if (res && res.error) {
          te(res.message);
          return;
        }

        if (res && !res.error) {
          fetchStories();
          ts("Story updated successfully");
          setStory("");
          setStoryTitle("");
          setUpdateLoader(false);
          setIsedit(false);
        }
      });
    } else {
      return;
    }
  };

  const deleteHandler = (id) => {
    let { description } = state.form;
    description.splice(id, 1);
    setState({ ...state, form: { ...state.form, description: description } });

    deleteMyStory(id).then((res) => {
      if (res && res.error) {
        te(res.message);
        return;
      }

      if (res && !res.error) {
        const message = res.data && res.data.message;

        if (message) {
          fetchStories();
          ts(message);
        }
      }
    });
  };

  return (
    <React.Fragment>
      <div className="w-100 content-section user-wrapper personl-info">
        <h2 className="fw-bold text-black text-center content-title">
        <img className="ew-image" src={StoryIcon} alt="time line" />
          What Stories Define Your Legacy?
          <hr className="mb-0" />
        </h2>

        {prompt  && (   
          <div className="prompts">
            <strong>{prompt.title} </strong> 
            <p> {prompt.body} </p>
          </div>
        )}
        <div className="add-edit-modal">
          <div className="row mb-2 mx-auto">
            <div className="col-lg-12 mb-2">
              <label className="w-100 fw-semibold fz-18 mb-2 text-left">
                Title
              </label>
              <Input
                name="storytitle"
                value={!isEdit ? storytitle : ""}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Title"
                isReq={true}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                  setStoryTitle(value);
                }}
                reqType="alphaNumeric"
                placeholder="Enter Title"
                error={errors.storytitle}
                disabled={!isEdit ? false : true}
              />
            </div>
            <div className="col-lg-12 ">
              <label className="w-100 fw-semibold fz-18 mb-2 text-left">
                Bio / Summary
              </label>
              <TextArea
                name="story"
                value={!isEdit ? story : ""}
                className="form-control input-content text-black placeholder-black border-dark-grey"
                title="Description"
                isReq={true}
                rows={4}
                validationFunc={onInputValidate}
                onChangeFunc={(name, value, error) => {
                  onInputChange(name, value, error);
                  setStory(value);
                }}
                placeholder="Enter text"
                error={errors && errors.story}
                disabled={!isEdit ? false : true}  />
            </div>
            <div className="col-lg-2 mt-4">
              <button onClick={addHandler}
                      type="button"
                      className="btn btn-primary br-10 fz-16 fw-bold w-100" >
                {loader ? "Adding..." : "Add"}
                {loader && (
                  <i className="ml-2 fa-spin fa-lg fa fa-spinner"
                     aria-hidden="true" />
                )}
              </button>
            </div>
          </div>

          <div className="row mx-0">
            <div className="flex flex-column mb-5 mt-1 w-100">
              <div className="card mb-4">
                <div className="card-header custom-py-15 d-flex align-items-center justify-content-between"
                     id="" >
                  <h5 className="flex-1 mb-0">
                    Your Stories
                  </h5>
                </div>
                <div className="card-body">
                  <div className="row mx-auto mb-2">
                    <div className="col-md-12 col-sm-12 col-xs-12 mb-3">
                        {description && description.length > 0 && (
                          <React.Fragment>
                            { description.map((val, index) => {
                              return (
                                <div className='property-card min-h-185 mb-3'>
                                  <div className="row" key={index}>
                                    {isEdit && val.id === id ? (
                                      <div className="col-md-12 col-sm-12 col-xs-12 mb-3">
                                        <p className="text-dark-grey fz-18 mb-1">
                                          <span className="mr-1 fz-18 fw-bold">Title: </span>
                                          <Input  name="storytitle"
                                                  value={storytitle}
                                                  className="form-control input-content text-black placeholder-black border-dark-grey"
                                                  title="Title"
                                                  isReq={true}
                                                  validationFunc={onInputValidate}
                                                  onChangeFunc={(name, value, error) => {
                                                    onInputChange(name, value, error);
                                                    setStoryTitle(value);
                                                  }}
                                                  reqType="alphaNumeric"
                                                  placeholder="Enter Title"
                                                  error={errors && errors.storytitle} />
                                            </p>
                                        </div>
                                      ) : (
                                        <div className="col-md-8 col-sm-8 col-xs-12 mb-3">
                                          <p className="text-dark-grey fz-18 mb-1">
                                            <span className="mr-1 fz-18 fw-bold">Title: </span>
                                            {val && val.title}
                                          </p>
                                        </div>
                                      )}
                                    {isEdit && val.id === id ? ("") : (
                                      <div className="col-md-4 col-sm-4 col-xs-12 mb-3">
                                        <div className="d-flex justify-content-left pull-right">
                                          <span onClick={() => editHandler(val.id)} >
                                                  <img src={Edit}
                                                       className="mr-3 cursor-pointer"
                                                       alt="edit-icon" />
                                          </span>
                                          <span onClick={() => deleteHandler(val.id)} >
                                                  <img src={Delete}
                                                       className="mr-3 cursor-pointer"
                                                       alt="delete-icon" />
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {isEdit && val.id === id ? (
                                        <div className="col-md-12 col-sm-12 col-xs-12 mb-3">
                                          <div className="d-flex justify-content-between">
                                            <p className="text-dark-grey fz-18 mb-1">
                                              <span className="mr-1 fz-18 fw-bold">Story: </span>
                                              <TextArea
                                                    name="story"
                                                    value={story}
                                                    className="form-control input-content text-black placeholder-black border-dark-grey"
                                                    title="Description"
                                                    isReq={true}
                                                    rows={5}
                                                    validationFunc={onInputValidate}
                                                    onChangeFunc={(name, value) =>
                                                      setStory(value)
                                                    }
                                                    place
                                                    placeholder="Enter text"
                                                    error={errors && errors.story}  />
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                      <div className="col-md-12 col-sm-12 col-xs-12 mb-3">
                                          <div className="d-flex justify-content-between">
                                            <p className="text-dark-grey fz-18 mb-1" style={{ whiteSpace: 'pre-line' }}>
                                              <span className="mr-1 fz-18 fw-bold">Story: </span>
                                              {val && val.input}
                                            </p>
                                          </div>
                                      </div>
                                    )}
                                    {isEdit && val.id === id ? (
                                      <div className="col-md-12 col-sm-12 col-xs-12">
                                            <span className="row mt-4">
                                                <button
                                                  onClick={() => updateHandler(val.id)}
                                                  type="button"
                                                  className="btn btn-primary br-10 fw-bold w-75 h-25 col-6" >
                                                  {updateLoader ? (
                                                    <i className="ml-2 fa-spin fa-lg fa fa-spinner"
                                                      aria-hidden="true" />
                                                  ) : (
                                                    "Update"
                                                  )}
                                                </button>
                                                <button
                                                  className="btn btn-primary br-10 fw-bold w-75 h-25 col-6"
                                                  onClick={() => {
                                                    setIsedit(false);
                                                    setStory("");
                                                    setStoryTitle("");
                                                  }} >
                                                  Cancel
                                                </button>
                                            </span>
                                      </div>
                                    ) : ("") }
                                  </div>
                                </div>
                              );
                            })}
                          </React.Fragment>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default MyStory;
