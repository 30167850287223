import { get, post, put, del } from '../httpInterceptor';

export const getImage = (id) => {
  return get(`/images/${id}`, {}, true, false).then((res) => {
    return res;
  });
};

export const listImages = () => {
  return get(`/images`, true).then((res) => {
    return res;
  });
};

export const createImage = (file) => {
  return post(`/images`, file, true, true).then((res) => {
    return res;
  });
};

export const editImage = (id, file) => {
  return put(`/images/${id}`, file, true, true).then((res) => {
    return res;
  });
};

export const delImage = (id) => {
  return del(`/images/${id}`, {}, true, false).then((res) => {
    return res;
  });
};
