import React, { useState, useEffect, useRef } from "react";
import { Input, TextArea } from "../Common/Input";
import { cloneDeep } from "lodash";
import { getFormDetails } from "../Utility/helper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { path_url } from "../Utility/constant";
import { te, ts } from "../Utility/ReduxToaster";
import RecipeIcon from "../Assets/images/recipe-blue.svg"; // Update this import
import { createRecipe, editRecipe } from "../Utility/Services/recipe"; // Update service imports

const initForm = {
  title: "",
  recipeName: "",
  description: "",
  instructions: "",
  ingredients: [],
  video_link: "",
  servings: "",
  notes: "",
  // user_id: "",
  // prep_time: "",
  // cook_time: "",
  // difficulty: "",
  // cuisine_type: "",
  // meal_type: "",
  // dietary_restrictions: [],
  // rating: "",
  // source_url: "",
  // source: "",
  // tags: [],
  // image: "",
  errors: {
    recipeName: null,
  },
};

const AddEditRecipe = (props) => {
  const [state, setState] = useState({
    form: cloneDeep(initForm),
  });

  const [loader, setLoader] = useState(false);
  const [userId, setUserId] = useState("");
  const tempForm = useRef();
  const [recipeId, setRecipeId] = useState("");

  let { form } = state;
  let location = useLocation();
  const navigate = useNavigate();

  let { errors, title, recipeName, description, instructions, user_id, ingredients,
    prep_time, cook_time, servings, difficulty, cuisine_type, meal_type, dietary_restrictions,
    rating, source_url, reviews, video_link, source, tags,  image, notes
  } = form;

  const formFun = () => {
    formUpdate();
  };

  tempForm.current = formFun;

  useEffect(() => {
    tempForm.current();
  }, []);


const formUpdate = () => {
  const index = location.state?.userid;
  if (location.state && location.state.data && location.state.data[index]) {
    const recipeData = location.state.data[index];
    const updatedForm = {
      ...form,
      recipeName: recipeData.recipe_name,
      notes: recipeData.notes,
      instructions: recipeData.instructions,
      servings: recipeData.servings,
      video_link: recipeData.video_link

      // ... (rest of the properties)
    };
    setUserId(recipeData.id);
    setState({ ...state, form: updatedForm }); 
    setRecipeId(location.state.recipe_id);
  }
};

  const onInputValidate = (name, error) => {
    let { errors } = state.form;
    errors[name] = error;
    setState({ ...state, form: { ...state.form, errors: errors } });
  };

  const onInputChange = (name, value, error = undefined) => {
    const { form } = state;
    form[name] = value;
    if (error !== undefined) {
      let { errors } = form;
      errors[name] = error;
    }
    setState({ form });
  };

const onAddRecipe = () => {
  const { form } = state;
  let obj = getFormDetails(form, onInputValidate);
  console.log(obj)
  if (obj) {
    setLoader(true);
    createRecipe(
      user_id,
      recipeName,
      servings,
      notes,
      instructions,
      ingredients,
      video_link
      ).then(
      (res) => {
        if (res && res.error) {
          te(res.message);
          setLoader(false);
          return;
        }
        if (res && !res.error) {
          ts("Recipe created successfully");
          setLoader(false);
          navigate(path_url.recipes);
        }
      }
    );
  } else {
    return false;
  }
};

const onEditRecipe = (recipe_id) => {
 const { form } = state;
 const { recipeData } = state;

 recipe_id = location.state['recipe_id'];
  
 let obj = getFormDetails(form, onInputValidate);

  console.log('state', state)
  console.log('location', location)
  console.log('form', form)
  console.log('obj', obj)
 
 if (obj) {
    setLoader(true);
    editRecipe(
      location.state['recipe_id'],
      recipeName,
      servings,
      notes,
      instructions,
      ingredients,
      video_link
    ).then((res) => {
      if (res && res.error) {
        te(res.message);
        setLoader(false);
        return;
      }
      if (res && !res.error) {
        ts("Recipe updated successfully");
        setLoader(false);
        navigate(path_url.recipes);
      }
    });
  } else {
    return false;
  }
};
const hasRecipeData = location.state?.data?.length > 0;


  // Rest of the functions for input validation, input change, and API calls...
  return (
    <React.Fragment>
      <div className="w-100 content-section account-wrapper user-wrapper">

        <div className="text-center">
          <h3 className="fw-bold text-black content-title">
            <img className="img-fluid mr-4" src={RecipeIcon} alt="recipe-icon" />
                { hasRecipeData ? "Update Recipe" : "Add Recipe" }
            <hr className="mb-0" />
          </h3>
        </div>

        <div className="common-mt add-edit-modal">

          <div className="row">
            <div className="col-lg-12">
              <div className="form-group">
                <label className="w-100 fw-semibold mb-2 text-center fz-18">Recipe Name</label>
                <Input
                  name="recipeName" value={recipeName} title="RecipeName"
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  isReq={true}
                  validationFunc={onInputValidate}
                  onChangeFunc={(name, value, error) => { onInputChange(name, value, error); }}
                  placeholder="Enter recipe name"
                  error={errors.recipeName}
                />
              </div>
            </div>
          </div>

          <div className="row">

            <div className="col-lg-3" >

              <div className="form-group">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">Ingredients</label>
                <Input name="ingredients" value={ingredients} title="Ingredients"
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  onChangeFunc={(name, value, error) => { onInputChange(name, value, error); }}
                  placeholder="Enter ingredients"
                />
              </div>
              
              <div className="form-group">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">Servings</label>
                <Input
                  name="servings" 
                  value={servings}
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  title="Servings"
                  onChangeFunc={(name, value, error) => { onInputChange(name, value, error); }}
                  placeholder="Enter servings"
                />
              </div>

              <div className="form-group">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">Video Link</label>
                <Input
                  name="video_link" value={video_link} title="Video Link"
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  onChangeFunc={(name, value, error) => { onInputChange(name, value, error); }}
                  placeholder="Enter video link"
                />
              </div>

            </div>

            <div className="col-lg-9">

              <div className="form-group">
                  <label className="w-100 fw-semibold mb-2 text-left fz-18">Notes</label>
                  <TextArea 
                    name="notes" value={notes} title="Notes"
                    className="form-control input-content text-black placeholder-black border-dark-grey"
                    style={{ height: "190%" }} // Span the full height of the form
                    onChangeFunc={(name, value, error) => { onInputChange(name, value, error); }}
                    placeholder="Any Special Notes?"
                  />
              </div>

              <div className="form-group">
                <label className="w-100 fw-semibold mb-2 text-left fz-18">Instructions</label>
                <TextArea
                  name="instructions" value={instructions} title="Instructions"
                  className="form-control input-content text-black placeholder-black border-dark-grey"
                  style={{ height: "190%" }} // Span the full height of the form
                  onChangeFunc={(name, value, error) => { onInputChange(name, value, error); }}
                  placeholder="Enter recipe instructions"
                />
              </div>

            </div>

          </div>
  
          {/* Continue with the rest of the fields */}
          
          <div className="col-lg-12 mt-4 account-action">
            <Link to={path_url.recipes}>
              <button className="cancel-btn mr-4">Cancel</button>
            </Link>
            {location &&
              location.state &&
              location.state.data &&
              location.state.data.length > 0 && (
                <button
                  onClick={onEditRecipe}
                  type="button"
                  className="btn btn-primary br-10 fz-16 fw-bold"
                >
                  {loader ? "Updating..." : "Update"}
                  {loader && (
                    <i
                      className="ml-2 fa-spin fa-lg fa fa-spinner"
                      aria-hidden="true"
                    />
                  )}
                </button>
              )}
            {location && !location.state && (
              <button
                onClick={onAddRecipe}
                type="button"
                className="btn btn-primary br-10 fz-16 fw-bold"
              >
                {loader ? "Loading..." : "Add"}
                {loader && (
                  <i
                    className="ml-2 fa-spin fa-lg fa fa-spinner"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
  


};

export default AddEditRecipe;
