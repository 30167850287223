import React from "react";
import ToolTip from "../../Common/Input/ToolTip";
import {path_url, side_menu_icons} from "../../Utility/constant";
import {NavLink} from "react-router-dom";

const Item = (props) => {
    const { item, disable } = props;
    const user_id = localStorage.getItem("userID");
    const toUrl =
        item.name === "personalInfo"
            ? `${path_url[item.url]}/${user_id}`
            : path_url[item.url];

    const handleClick = (e) => {
        if(disable){
            e.preventDefault()
        }
    }

    return (
            <li key={item.name}>
                <NavLink
                    to={disable ? "/" : toUrl}
                    activeClassName={disable ? "" : "active"}
                    key={item.name}
                    onClick={handleClick}
                >
                  <span className="sidemenu-icon d-flex w-100">
                    <img
                        alt={item.name}
                        className={`${item.className} ${ disable ? "opacity" : ""}`}
                        src={side_menu_icons[item.name]}
                    />
                    {disable &&
                        <ToolTip
                            text={item.value}
                            message="Please upgrade your Subscription to use this Option."
                            className="ml-2 opacity"
                            style={{ pointerEvents: "none" }}
                        />
                    }
                    {!disable &&
                        <span>
                            {item.value}
                        </span>
                    }

                  </span>
                </NavLink>
            </li>
    );
};

export default Item;
